"use client";

/**
 * Third-party library.
 */
import { FunctionComponent, PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react";

/**
 * Project components.
 */
import { trpc } from "@/components/client/trpc";
import { SystemPreferenceKey } from "@/components/common/system-preference";
import { BusinessStatus, CallRecording, SessionInitiationProtocol } from "@/components/common/system-preference/types";

/**
 * Type definition of the context value.
 */
type SystemPreferenceContextValue = {
  /**
   * Business status.
   * - open: Accepts incoming client calls.
   * - closed: Rejects incoming client calls.
   */
  businessStatus: BusinessStatus | null;
  /**
   * The Business Status is still being retrieved by the service.
   */
  businessStatusLoading: boolean;
  /**
   * Session Initiation Protocol (SIP).
   * Only affect outbound calls.
   *
   * - enabled: Uses the United States phone number for outbound calls.
   * - disabled: Uses the Singapore phone number for outbound calls.
   */
  sessionInitationProtocol: SessionInitiationProtocol | null;
  /**
   * The Session Initation Protocol is still being retrieved by the service.
   */
  sessionInitationProtocolLoading: boolean;
  /**
   * Call recording.
   * - enabled: Call recording is enabled.
   * - disabled: Call recording is disabled.
   */
  callRecording: CallRecording | null;
  /**
   * The Call Recordings is still being retrieved by the service.
   */
  callRecordingLoading: boolean;
  /**
   * Updates the Business Status system preference.
   */
  updateBusinessStatus: (args: {
    /**
     * The new value of the Business Status.
     */
    businessStatus: BusinessStatus;
  }) => void;
  /**
   * Updates the Session Initiation Protocol system preference.
   */
  updateSessionInitiationProtocol: (args: {
    /**
     * The new value of the Session Initiation Protocol.
     */
    sessionInitationProtocol: SessionInitiationProtocol;
  }) => Promise<void>;
  /**
   * Updates the Call Recording system preference.
   */
  updateCallRecording: (args: {
    /**
     * The new value of the Call Recording.
     */
    callRecordings: CallRecording;
  }) => Promise<void>;
};

/**
 * Initial value of the context.
 */
const SystemPreferenceContext = createContext<SystemPreferenceContextValue>({
  businessStatus: null,
  businessStatusLoading: true,
  sessionInitationProtocol: null,
  sessionInitationProtocolLoading: true,
  callRecording: null,
  callRecordingLoading: true,
  updateBusinessStatus: () => {},
  updateSessionInitiationProtocol: () => Promise.resolve(),
  updateCallRecording: () => Promise.resolve()
});

/**
 * Use this hook to access the context values.
 */
export const useSystemPreferenceContext = () => useContext(SystemPreferenceContext);

/**
 * Provider component. Wrap around your components provider to allow components
 * access to the context.
 */
export const SystemPreferenceContextProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [businessStatus, setBusinessStatus] = useState<SystemPreferenceContextValue["businessStatus"]>(null);
  const [sessionInitationProtocol, setSessionInitationProtocol] = useState<SystemPreferenceContextValue["sessionInitationProtocol"]>(null);
  const [callRecording, setCallRecording] = useState<SystemPreferenceContextValue["callRecording"]>(null);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // Business Status
  // ===========================================================================

  const {
    data: businessStatusSystemPreference,
    isFetching: businessStatusSystemPreferenceLoading
  } = trpc.systemPreferenceRouter.getSystemPreference.useQuery({
    filter: {
      key: SystemPreferenceKey.BUSINESS_STATUS
    }
  });
  const {
    mutate: updateBusinessStatus,
    isPending: businessStatusUpdating
  } = trpc.systemPreferenceRouter.updateSystemPreference.useMutation({
    onSuccess: data => {
      setBusinessStatus(data.value as BusinessStatus);
    }
  });

  // ===========================================================================
  // Session Initiation Protocol
  // ===========================================================================

  const {
    data: sessionInitiationProtocolSystemPreference,
    isFetching: sessionInitiationProtocolSystemPreferenceLoading
  } = trpc.systemPreferenceRouter.getSystemPreference.useQuery({
    filter: {
      key: SystemPreferenceKey.SESSION_INITIATION_PROTOCOL
    }
  });
  const {
    mutate: updateSessionInitiationProtocol,
    isPending: updatingSessionInitiationProtocolStatus
  } = trpc.systemPreferenceRouter.updateSystemPreference.useMutation({
    onSuccess: data => {
      setSessionInitationProtocol(data.value as SessionInitiationProtocol);
    }
  });

  // ===========================================================================
  // Call Recordings
  // ===========================================================================

  const {
    data: callRecordingSystemPreference,
    isFetching: callRecordingPreferenceLoading
  } = trpc.systemPreferenceRouter.getSystemPreference.useQuery({
    filter: {
      key: SystemPreferenceKey.CALL_RECORDING
    }
  });
  const {
    mutate: updateCallRecording,
    isPending: updateCallRecordingLoading
  } = trpc.systemPreferenceRouter.updateSystemPreference.useMutation({
    onSuccess: data => {
      setCallRecording(data.value as CallRecording);
    }
  });

  // ===========================================================================
  // Subscription
  // ===========================================================================

  /**
   * Update the corresponding state below when the system preference is updated:
   * - Business Status
   * - Call Recording
   * - Session Initiation Protocol
   */
  const {
    reset: resetOnSystemPreferenceUpdateSubscription
  } = trpc.systemPreferenceRouter.onSystemPreferenceUpdate.useSubscription(undefined, {
    onData: data => {
      switch (data.key) {
        case SystemPreferenceKey.BUSINESS_STATUS:
          setBusinessStatus(data.value as BusinessStatus);
          break;
        case SystemPreferenceKey.CALL_RECORDING:
          setCallRecording(data.value as CallRecording);
          break;
        case SystemPreferenceKey.SESSION_INITIATION_PROTOCOL:
          setSessionInitationProtocol(data.value as SessionInitiationProtocol);
          break;
        default:
          throw new Error(`Unknown system preference key: ${data.key}`);
      }
    }
  });

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const _updateBusinessStatus = useCallback(({
    businessStatus
  }: {
    /**
     * The business status to set.
     */
    businessStatus?: BusinessStatus;
  }) => {
    if (businessStatusUpdating || businessStatusSystemPreferenceLoading) {
      console.warn("Business status is currently being updated.");
      return;
    }
    if (!businessStatus) {
      throw new Error("Business status is required.");
    }
    updateBusinessStatus({
      data: {
        key: SystemPreferenceKey.BUSINESS_STATUS,
        value: businessStatus
      }
    });
  }, [updateBusinessStatus, businessStatusUpdating, businessStatusSystemPreferenceLoading]);
  const _updateSessionInitiationProtocol = useCallback(async ({
    sessionInitationProtocol
  }: {
    sessionInitationProtocol?: SessionInitiationProtocol;
  }) => {
    if (!sessionInitationProtocol) {
      throw new Error("Business status is required.");
    }
    return updateSessionInitiationProtocol({
      data: {
        key: SystemPreferenceKey.SESSION_INITIATION_PROTOCOL,
        value: sessionInitationProtocol
      }
    });
  }, [updateSessionInitiationProtocol]);
  const _updateCallRecordings = useCallback(async ({
    callRecordings
  }: {
    callRecordings?: CallRecording;
  }) => {
    if (!callRecordings) {
      throw new Error("Call recording is required.");
    }
    return updateCallRecording({
      data: {
        key: SystemPreferenceKey.CALL_RECORDING,
        value: callRecordings
      }
    });
  }, [updateCallRecording]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useEffect(() => {
    return () => {
      resetOnSystemPreferenceUpdateSubscription();
    };
  }, []);

  /**
   * Update the business status state when the query is done.
   */
  useEffect(() => {
    setBusinessStatus(businessStatusSystemPreference?.value as BusinessStatus);
  }, [businessStatusSystemPreference]);

  /**
   * Update the call recording state when the query is done.
   */
  useEffect(() => {
    setCallRecording(callRecordingSystemPreference?.value as CallRecording);
  }, [callRecordingSystemPreference]);

  /**
   * Update the session initiation protocol state when the query is done.
   */
  useEffect(() => {
    setSessionInitationProtocol(sessionInitiationProtocolSystemPreference?.value as SessionInitiationProtocol);
  }, [sessionInitiationProtocolSystemPreference]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <SystemPreferenceContext.Provider value={{
    businessStatus,
    businessStatusLoading: businessStatusSystemPreferenceLoading || businessStatusUpdating,
    sessionInitationProtocol,
    sessionInitationProtocolLoading: sessionInitiationProtocolSystemPreferenceLoading || updatingSessionInitiationProtocolStatus,
    callRecording,
    callRecordingLoading: callRecordingPreferenceLoading || updateCallRecordingLoading,
    updateBusinessStatus: _updateBusinessStatus,
    updateSessionInitiationProtocol: _updateSessionInitiationProtocol,
    updateCallRecording: _updateCallRecordings
  }} data-sentry-element="unknown" data-sentry-component="SystemPreferenceContextProvider" data-sentry-source-file="system-preference-context.tsx">
      {children}
    </SystemPreferenceContext.Provider>;
};