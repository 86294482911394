/** https://github.com/pilcrowOnPaper/oslo/blob/main/src/encoding/index.ts */
export interface Encoding {
  encode: (data: Uint8Array) => string;
  decode: (data: string) => Uint8Array;
}

/** https://github.com/pilcrowOnPaper/oslo/blob/main/src/encoding/base64.ts */
export class Base64Encoding implements Encoding {
  public alphabet: string;
  public padding: string;

  private decodeMap = new Map<string, number>();

  constructor(
    alphabet: string,
    options?: {
      padding?: string;
    }
  ) {
    if (alphabet.length !== 64) {
      throw new Error("Invalid alphabet");
    }
    this.alphabet = alphabet;
    this.padding = options?.padding ?? "=";
    if (this.alphabet.includes(this.padding) || this.padding.length !== 1) {
      throw new Error("Invalid padding");
    }
    for (let i = 0; i < alphabet.length; i++) {
      this.decodeMap.set(alphabet[i]!, i);
    }
  }

  public encode(
    data: Uint8Array,
    options?: {
      includePadding?: boolean;
    }
  ): string {
    let result = "";
    let buffer = 0;
    let shift = 0;
    for (let i = 0; i < data.length; i++) {
      buffer = (buffer << 8) | data[i]!;
      shift += 8;
      while (shift >= 6) {
        shift += -6;
        result += this.alphabet[(buffer >> shift) & 0x3f];
      }
    }
    if (shift > 0) {
      result += this.alphabet[(buffer << (6 - shift)) & 0x3f];
    }
    const includePadding = options?.includePadding ?? true;
    if (includePadding) {
      const padCount = (4 - (result.length % 4)) % 4;
      for (let i = 0; i < padCount; i++) {
        result += "=";
      }
    }
    return result;
  }

  public decode(
    data: string,
    options?: {
      strict?: boolean;
    }
  ): Uint8Array {
    const strict = options?.strict ?? true;
    const chunkCount = Math.ceil(data.length / 4);
    const result: number[] = [];
    for (let i = 0; i < chunkCount; i++) {
      let padCount = 0;
      let buffer = 0;
      for (let j = 0; j < 4; j++) {
        const encoded = data[i * 4 + j];
        if (encoded === "=") {
          if (i + 1 !== chunkCount) {
            throw new Error(`Invalid character: ${encoded}`);
          }
          padCount += 1;
          continue;
        }
        if (encoded === undefined) {
          if (strict) {
            throw new Error("Invalid data");
          }
          padCount += 1;
          continue;
        }
        const value = this.decodeMap.get(encoded) ?? null;
        if (value === null) {
          throw new Error(`Invalid character: ${encoded}`);
        }
        buffer += value << (6 * (3 - j));
      }
      result.push((buffer >> 16) & 0xff);
      if (padCount < 2) {
        result.push((buffer >> 8) & 0xff);
      }
      if (padCount < 1) {
        result.push(buffer & 0xff);
      }
    }
    return Uint8Array.from(result);
  }
}

/** https://github.com/pilcrowOnPaper/oslo/blob/main/src/encoding/base64.ts */
export const base64url = new Base64Encoding(
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_"
);

/**
 * Generates a random state for OAuth 2.0 authorization flow.
 *
 * @returns A random string 'state' to be saved as a cookie.
 *
 * A 'state' a random value used to protect against XSRF attacks. During the OAuth2 Flow, there's three things happening:
 * 1. Generate Authentication URL
 *    - A state value is created here and returned as a cookie,
 *    before they're redirected to to make sure this was the user that requested an authentication.
 * 2. User is redirected to the Provider URL
 *    - After filling the credentials, user gets redirected to our server's validate callback.
 * 2. Validate Callback
 *    - We validate the state here to make sure this is the same user.
 */
export function generateState(): string {
  const randomValues = new Uint8Array(32);
  crypto.getRandomValues(randomValues);
  return base64url.encode(randomValues, {
    includePadding: false,
  });
}
