"use client";

/**
 * Third-party libraries.
 */
import { Button } from "antd";
import { PropsWithChildren, useState } from "react";

/**
 * Project components.
 */
import { ApplicationLogo } from "@/components/client/images";
import { Loading } from "@/components/client/loading";
import { useNetworkContext } from "@/components/client/network";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

/**
 * Properties for the network connection guard item.
 */
type NetworkConnectionGuardItemProps = {
  /**
   * Indicates if the item is connected or not.
   * - True: Connected. Display a green checkmark.
   * - False: Not connected. Display a red exclamation mark.
   */
  connected: boolean;
  /**
   * Indicates if the item is still loading.
   * - True: Display a loading spinner.
   * - False: Do not display a loading spinner.
   */
  loading: boolean;
  /**
   * The message to display.
   */
  message: string;
};

/**
 * A network connection item.
 * Displays what service you are connecting to and its current status.
 * - Loading: Display a loading spinner.
 * - Connected: Display a green checkmark.
 * - Not connected: Display a red exclamation mark.
 */
function NetworkConnectionGuardItem(props: NetworkConnectionGuardItemProps) {
  const CONNECTION_CLASS_NAME = "flex w-full items-center gap-2";
  return <div className={CONNECTION_CLASS_NAME} data-sentry-component="NetworkConnectionGuardItem" data-sentry-source-file="network-connection-guard.tsx">
      <div className="flex h-8 w-8 items-center justify-center">
        {props.loading && <Loading size="small" />}
        {props.connected && <CheckCircleFilled className="!text-semantic-green animate-ease-in" />}
        {!props.loading && !props.connected && <ExclamationCircleFilled className="!text-semantic-red animate-ease-in" />}
      </div>
      <span className="text-xl text-tpl-navy">{props.message}</span>
    </div>;
}
const HEADER_CLASS_NAME = "text-3xl text-tpl-navy font-bold";
/**
 * A component that only renders the children when all of the services are
 * connected.
 *
 * Shows a loading screen when the services are still connecting or at least
 * one of the services is not connected.
 *
 * Shows a button to reload the page when at least one service is not connected.
 */
export function NetworkConnectionGuard(props: PropsWithChildren<{}>) {
  // ===========================================================================
  // Hooks
  // ===========================================================================
  const {
    connected,
    loading,
    applicationConnected,
    applicationLoading,
    twilioConnected,
    twilioLoading
  } = useNetworkContext();

  // ===========================================================================
  // States
  // ===========================================================================

  /**
   * Indicates that the application is disconnected from all services.
   */
  const disconnected = !loading && !connected;
  const [reloading, setReloading] = useState<boolean>(false);

  // ===========================================================================
  // Effects
  // ===========================================================================

  // ===========================================================================
  // Render
  // ===========================================================================

  // All services is connected.
  if (connected) {
    return props.children;
  }
  return <div className="flex h-[100vh] w-[100vw] items-center justify-center bg-white" data-sentry-component="NetworkConnectionGuard" data-sentry-source-file="network-connection-guard.tsx">
      <div className="flex h-[75%] w-[75%] flex-col items-center justify-center gap-2">
        <div className="max-w-[512px]">
          <ApplicationLogo width="100%" height="100%" data-sentry-element="ApplicationLogo" data-sentry-source-file="network-connection-guard.tsx" />
        </div>
        {disconnected && <span className={`${HEADER_CLASS_NAME} !text-tpl-red`}>
            Connection failed. Please reload the page.
          </span>}
        {(loading || connected) && <span className={HEADER_CLASS_NAME}>Connecting to the services.</span>}
        <div>
          <NetworkConnectionGuardItem connected={applicationConnected} loading={applicationLoading} message="Application Service" data-sentry-element="NetworkConnectionGuardItem" data-sentry-source-file="network-connection-guard.tsx" />
          <NetworkConnectionGuardItem connected={twilioConnected} loading={twilioLoading} message="Twilio Service" data-sentry-element="NetworkConnectionGuardItem" data-sentry-source-file="network-connection-guard.tsx" />
        </div>
        {disconnected && <Button className="mt-4" loading={reloading} type="primary" onClick={() => {
        setReloading(true);
        window.location.reload();
      }}>
            Reload
          </Button>}
      </div>
    </div>;
}