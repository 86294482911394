/**
 * Third-party libraries.
 */
import { TaskStatus } from "@prisma/client";
import { Button, MenuProps } from "antd";
import { useMemo, useState } from "react";

/**
 * Project components.
 */
import { CommunicationLogUtility } from "@/components/client/communication-log/utilities";
import { IconCommunicationLogPhone } from "@/components/client/icons";
import { CircleOutline } from "@/components/client/images";
import { RichTextEditor } from "@/components/client/text-editor";
import { trpc } from "@/components/client/trpc";
import { type ActivityLog } from "@/components/common/activity-log";
import { ActivityLogType } from "@/components/common/activity-log/enumerations";
import { DateUtility, StringUtility } from "@/components/common/utilities";
import { CheckCircleFilled, MessageOutlined } from "@ant-design/icons";
import { ActivityLogContent } from "./sub-components/activity-log-content";
import { ActivityLogHeader } from "./sub-components/activity-log-header";

/**
 * Renders an icon on the activity log.
 */
function ActivityLogIcon(props: ActivityLog) {
  switch (props.type) {
    case ActivityLogType.CALL:
      return <IconCommunicationLogPhone size="small" status={CommunicationLogUtility.getStatus({
        callDirection: props.direction,
        callStatus: props.status
      })} />;
    case ActivityLogType.NOTE:
      return <MessageOutlined className="!text-semantic-blue" />;
    case ActivityLogType.TASK:
      if (props.status === TaskStatus.IN_PROGRESS) {
        return <div className="flex h-[14px] w-[14px] items-center rounded-md bg-neutral-grey-light hover:cursor-pointer">
            <CircleOutline className="!bg-tpl-navy-light" />
          </div>;
      } else if (props.status === TaskStatus.COMPLETED) {
        return <div className="flex h-[14px] w-[14px] items-center rounded-md bg-neutral-grey-light hover:cursor-pointer">
            <CheckCircleFilled className="!text-semantic-green" />
          </div>;
      }
    default:
      throw new Error(`Invalid ${props.type} Activity Log Type`);
  }
}

/**
 * Properties of the ActivityLog component.
 */
type ActivityLogProps = ActivityLog;

/**
 * Displays a single row of item in the activity log.
 */
export function ActivityLog(log: ActivityLogProps) {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Indicates if the activity log is editable.
   */
  const [editable, setEditable] = useState<boolean>(false);

  /**
   * Temporary state storage for any changes made on the task description.
   * This will be used by the service to update the description of the corresponding
   * task.
   */
  const [updatedTaskValue, setUpdatedTaskValue] = useState<string | undefined>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    mutate: updateTask,
    isPending: isUpdatingTask
  } = trpc.taskRouter.updateTask.useMutation();
  const {
    mutate: deleteTask,
    isPending: isDeletingTask
  } = trpc.taskRouter.deleteTask.useMutation();

  /**
   * Menu items for the activity log.
   * This is only utilized for notes and tasks.
   */
  const menuItems: MenuProps["items"] = useMemo<MenuProps["items"]>(() => {
    if (log.type !== ActivityLogType.NOTE && log.type !== ActivityLogType.TASK) {
      return undefined;
    }
    return [{
      disabled: isDeletingTask || isUpdatingTask,
      key: "edit",
      label: "Edit",
      onClick: () => {
        setEditable(true);
      }
    }, {
      disabled: isDeletingTask || isUpdatingTask,
      key: "delete",
      label: "Delete",
      onClick: () => {
        deleteTask({
          data: {
            id: log.id
          }
        });
      }
    }];
  }, [deleteTask, isDeletingTask, isUpdatingTask, log.id, log.type]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`inline-flex h-full flex-col items-start justify-start gap-1 rounded-md ${editable ? "bg-sky-100" : ""} px-2 ${editable ? "py-4" : "py-0"}`} data-sentry-component="ActivityLog" data-sentry-source-file="activity-log.tsx">
      <ActivityLogHeader editable={editable} icon={<ActivityLogIcon {...log} />} menuItems={menuItems} onChange={({
      content
    }) => {
      setUpdatedTaskValue(content);
    }} onIconClick={() => {
      if (log.type !== ActivityLogType.TASK) {
        return;
      }
      updateTask({
        data: {
          id: log.id,
          status: log.status === TaskStatus.IN_PROGRESS ? TaskStatus.COMPLETED : TaskStatus.IN_PROGRESS
        }
      }, {
        onError: error => {
          console.error(error);
        },
        onSuccess: () => {
          // Reset the related states.
          setUpdatedTaskValue(undefined);
          setEditable(false);
        }
      });
    }} title={log.title} userInitials={!log.associatedUserName ? undefined : StringUtility.getInitials({
      input: log.associatedUserName
    }) || undefined} data-sentry-element="ActivityLogHeader" data-sentry-source-file="activity-log.tsx" />
      <ActivityLogContent data-sentry-element="ActivityLogContent" data-sentry-source-file="activity-log.tsx">
        <div className="flex w-full flex-col gap-1 py-1">
          {(log.type === ActivityLogType.CALL || log.type === ActivityLogType.NOTE) && <div className="shrink grow basis-0 text-sm font-normal text-tpl-navy">
              {log.description && <RichTextEditor content={log.description} editable={editable} lineClamp={3} />}
            </div>}
          {!editable && <div className="inline-flex items-center justify-start text-xs font-normal text-tpl-navy-light">
              {DateUtility.getTime({
            date: log.date
          })}
            </div>}
          {!!editable && <div className="flex gap-2">
              <Button disabled={isUpdatingTask} onClick={() => {
            setUpdatedTaskValue(undefined);
            setEditable(false);
          }}>
                Cancel
              </Button>
              <Button disabled={isUpdatingTask} loading={isUpdatingTask} onClick={() => {
            // Task description cannot be removed once it has been created.
            if (!updatedTaskValue) {
              return;
            }
            updateTask({
              data: {
                id: log.id,
                description: updatedTaskValue
              }
            }, {
              onError: error => {
                console.error(error);
              },
              onSuccess: () => {
                // Reset the related states.
                setUpdatedTaskValue(undefined);
                setEditable(false);
              }
            });
          }} type="primary">
                Save
              </Button>
            </div>}
        </div>
      </ActivityLogContent>
    </div>;
}