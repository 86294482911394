import { Auth0User } from "@/components/common/auth0/types";
import { AuthUtility } from "@/components/common/auth0/utilities";

type AuthenticatedUser = Omit<Auth0User, "id"> &
  Required<Pick<Auth0User, "id">>;

const globalForUserSession = globalThis as unknown as {
  usersSession?: Map<string, AuthenticatedUser>;
};

export const usersSession =
  globalForUserSession.usersSession ?? new Map<string, AuthenticatedUser>();

// if (process.env.NODE_ENV !== "production")
globalForUserSession.usersSession = usersSession;

// /**
//  * Map for storing the currently logged in users.
//  *
//  * This is mainly used for checking the permissions of the currently logged in user.
//  */
// const usersSession = new Map<string, AuthenticatedUser>();

/**
 * Gets the currently logged in user by userId. (not to be mistaken for the auth0 id)
 */
export const getLoggedInUser = (options: {
  /** Id of the user from the DB. */
  userId: string;
}) => {
  const user = usersSession.get(options.userId);

  if (!user) return null;

  const isAuthenticated = AuthUtility.isAuthenticated({ user });

  /** Remove the user if it is not authenticated. */
  if (!isAuthenticated) {
    removeLoggedInUser(options);
    return null;
  }

  return user;
};

/**
 * Gets all currently logged in users.
 */
export const getLoggedInUsers = () => {
  const users = [];

  for (const user of usersSession.values()) {
    const isAuthenticated = AuthUtility.isAuthenticated({ user });

    if (isAuthenticated) {
      users.push(user);
    }
  }

  return users;
};

/**
 * Arguments for setting the logged in user.
 */
type SetLoggedInUserArgs = {
  /** Id of the user from the DB. */
  userId: string;
  /** Auth0 user object with permissions. */
  authUser: AuthenticatedUser;
};

/**
 * Saves the currently logged in user in memory.
 */
export const setLoggedInUser = (options: SetLoggedInUserArgs) => {
  const { userId, authUser } = options;

  usersSession.set(userId, authUser);
};

/**
 * Arguments for removing the logged in user.
 */
type RemoveLoggedInUserArgs = {
  /** Id of the user from the DB. */
  userId: string;
};

/**
 * Removes the logged in user from memory.
 */
export const removeLoggedInUser = (options: RemoveLoggedInUserArgs) => {
  usersSession.delete(options.userId);
};
