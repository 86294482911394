import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Auth0TokenUtility } from "../../auth0";

/**
 * Arguments for getting a parent.
 */
type GetParentsArgs = {
  /**
   * Get the parent with the given mobile number.
   */
  mobile: {
    /**
     * The country code of the mobile number.
     *
     * @example
     * +65
     */
    countryCode: string;
    /**
     * The number of the mobile number.
     *
     * @example
     * 12345678
     */
    number: string;
  };
};

/**
 * Archus Core Service API Parent object.
 */
type Parent = {
  /**
   * Parent ID
   *
   * @example
   * 61f7b1b3b3b3b3b3b3b3b3b3
   */
  _id: string;
  /**
   * Created date and time.
   *
   * @example
   * 2024-12-03T06:35:56.145Z
   */
  createDateTime: string;
  /**
   * Last modified date and time.
   *
   * @example
   * 2024-12-03T06:35:56.145Z
   */
  lastModifiedDateTime: string;
  /**
   * Date the parent record was created.
   */
  createdBy: string;
  lastModifiedBy: string;
  userId: string;
  /**
   * Full name of the parent.
   */
  fullName: string;
  alias: string;
  /**
   * Gender of the parent.
   */
  gender: string;
  /**
   * Salutation of the parent.
   */
  salutation: string;
  /**
   * Mobile number of the parent.
   */
  mobile: {
    /**
     * Country code of the mobile number.
     */
    countryCode: string;
    /**
     * The mobile number.
     */
    number: string;
  };
  /**
   * Home phone of the parent.
   */
  homePhone: {
    /**
     * Country code of the landline.
     */
    countryCode: string;
    /**
     * Landline number.
     */
    number: string;
  };
  /**
   * Email of the parent.
   */
  email: string;
  /**
   * Address of the parent.
   */
  address: {
    line1: string;
    streetAddress: string;
    gateBuilding: string;
    link: string;
    unit: string;
    country: string;
    postalCode: string;
  };
  /**
   * URL of the parent's avatar.
   */
  avatarUrl: string;
  stripeCustomerId: string;
};

/**
 * Get the parents with the given mobile number.
 *
 * @returns The parents with the given mobile number.
 */
export async function getParents(args: GetParentsArgs): Promise<
  {
    /**
     * The Archus Core Service profile ID of the parent.
     */
    _id: string;
    /**
     * The full name of the parent.
     */
    fullName: string;
  }[]
> {
  /**
   * Disable this service if the required environment variables are not set.
   */
  if (
    !process.env.ARCHUS_CORE_SERVICE_URL ||
    !process.env.AUTH0_CORE_SERVICE_API_AUDIENCE ||
    !process.env.AUTH0_CORE_SERVICE_API_CLIENT_ID ||
    !process.env.AUTH0_CORE_SERVICE_API_CLIENT_SECRET ||
    !process.env.AUTH0_ISSUER_BASE_URL
  ) {
    return [];
  }

  try {
    const AUTH0_M2M_TOKEN = await Auth0TokenUtility.getM2MToken();

    const REQUEST_HEADERS: AxiosRequestConfig["headers"] = {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + AUTH0_M2M_TOKEN.access_token,
    };

    const REQUEST_BODY = {
      mobile: {
        countryCode: args.mobile.countryCode.replace("+", ""),
        number: args.mobile.number,
      },
    };

    const response: AxiosResponse<{
      code: string;
      messages: string;
      data: {
        page: number;
        total: Number;
        pageSize: number;
        items: Parent[];
      };
    }> = await axios.post(
      `${process.env.ARCHUS_CORE_SERVICE_URL}/api/v1/parents/list`,
      REQUEST_BODY,
      {
        headers: REQUEST_HEADERS,
      },
    );

    if (response.status !== 200) {
      console.error({
        status: response.status,
        text: response.statusText,
      });
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    const parents = response.data.data.items;

    if (parents.length === 0) {
      return [];
    }

    return parents;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(`Error fetching parents:`, error.response?.data);
    }
    return [];
  }
}
