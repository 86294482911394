/**
 * Third-party libraries.
 */
import { CallDirection, CallStatus } from "@prisma/client";
import { Divider } from "antd";
import { CommunicationLogInboundCallStatusMapper, CommunicationLogOutboundCallStatusMapper } from "@/components/client/communication-log";
import { FieldDisplayOnly } from "@/components/client/field";
import { PhoneIcon } from "@/components/client/icons";
import { formatDate } from "@/components/common/time/utilities/time-utility";
import Icon from "@ant-design/icons";
import { RichTextEditor } from "../text-editor";

/** Props for PastInteractionItemCard */
type PastInteractionCardItemProps = {
  status: CallStatus;
  direction: CallDirection;
  summary?: string | null;
  dateStarted: Date | string;
  agentName: string;
};

/** A single card inside past interactions list. */
export function PastInteractionItemCard({
  status,
  direction,
  summary,
  dateStarted,
  agentName
}: PastInteractionCardItemProps) {
  /** Past interactions card title based on call status. */
  const cardTitle = function () {
    if (direction === CallDirection.INBOUND) {
      const mappedStatus = CommunicationLogInboundCallStatusMapper[status as keyof typeof CommunicationLogInboundCallStatusMapper];
      if (mappedStatus) {
        return `${mappedStatus} - ${direction}`;
      }
      return "Unknown Call";
    } else if (direction === CallDirection.OUTBOUND) {
      const mappedStatus = CommunicationLogOutboundCallStatusMapper[status as keyof typeof CommunicationLogOutboundCallStatusMapper];
      if (mappedStatus) {
        return `${mappedStatus} - ${direction}`;
      }
      return "Unknown Call";
    }
  }();

  /** Card's top-right badge based on call status. */
  const badge = function () {
    switch (status) {
      case CallStatus.COMPLETED:
        return null;
      /**
       * "Resolved" tag is temporary and currently out of scope.
       * https://thepiquelab.atlassian.net/browse/ARCD-4341
       */
      // <Tag color="success" className="!text-xs mx-0 !me-0">
      //   Resolved
      // </Tag>
      default:
        return null;
    }
  }();

  /** Color of the phone icon based on call status. */
  const phoneIconColorClass = function () {
    switch (status) {
      case CallStatus.COMPLETED:
        return "!text-semantic-green";
      case CallStatus.MISSED:
      case CallStatus.CANCELED:
        return "!text-semantic-red";
      default:
        return "";
    }
  }();

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className="bg-neutral-surface-gray flex flex-col gap-y-2 rounded border border-neutral-grey-mid bg-neutral-grey-surface p-3 text-tpl-navy" data-sentry-component="PastInteractionItemCard" data-sentry-source-file="call-past-interaction-card-item.tsx">
      <header className="flex w-full flex-col gap-y-2">
        <div className="flex items-center justify-between gap-x-1">
          <div className="flex items-center gap-x-1">
            <Icon className={phoneIconColorClass} component={PhoneIcon} data-sentry-element="Icon" data-sentry-source-file="call-past-interaction-card-item.tsx" />

            <span className="text-sm text-tpl-navy">{cardTitle}</span>
          </div>

          {badge}
        </div>

        <FieldDisplayOnly label="Date" value={formatDate(dateStarted, {
        format: "DD MMM YYYY, h:mm A"
      })} data-sentry-element="FieldDisplayOnly" data-sentry-source-file="call-past-interaction-card-item.tsx" />

        {agentName && <FieldDisplayOnly label="Agent" value={agentName} />}
      </header>
      {summary && <>
          <Divider className="!my-0" />
          <div className="flex flex-col gap-y-2">
            <span className="text-sm font-semibold text-tpl-navy">
              Call Summary:
            </span>
            <RichTextEditor content={summary} editable={false} lineClamp={3} />
          </div>
        </>}
    </div>;
}