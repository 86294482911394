"use client";

/**
 * Third-party libraries.
 */
// import { FilterOutlined as FilterOutlinedIcon } from "@ant-design/icons";
import { Input, Tabs, TabsProps } from "antd";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useState } from "react";

/**
 * Project components.
 */
import { ActivityLogCard } from "@/components/client/activity";
import { useAuthenticationContext } from "@/components/client/authentication";
import { CallCompleteCard, CallControlLiveCard, CallControlRingingCard, CallInformationCard, CallRoutingCard, CallSummaryCard, useCallRecording, UseCallRoutings } from "@/components/client/call";
import { CallPastInteractionsCard } from "@/components/client/call/call-past-interactions-card";
import { CallRecordingPlayerCard } from "@/components/client/call/call-recording-player-card";
import { CommunicationLog, CommunicationLogStatus, CommunicationLogTab, useCommunicationLogContext } from "@/components/client/communication-log";
import { CommunicationLogRouting } from "@/components/client/communication-log/types";
import { ContactProfile, ContactProfileProps } from "@/components/client/contact";
import { useContacts } from "@/components/client/contact/hooks";
import { DevelopmentOnly } from "@/components/client/development";
import { ApplicationDefaultProtectedPageLayout } from "@/components/client/layout";
import { PermissionRequired } from "@/components/client/permission";
import { Placeholder } from "@/components/client/placeholder";
import { trpc } from "@/components/client/trpc";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { ContactSearchField } from "@/components/server/contact/services/contact-service";
import { PlayWrightTestId } from "@/tests/constants";
import { CallRecordingStatus } from "@prisma/client";

/**
 * Panel that displays the call information and actions that can be performed on
 * the selected communication log.
 *
 * This is only shown when a communication log is selected.
 *
 * The sections are displayed in this order from top to bottom subject to
 * the visibility conditions:
 *
 * - Call Information (Always visible) - Displays information about the call.
 *
 * - Call Control Ringing (Visible when the call is ringing)
 *    - Accept Call
 *    - Reject Call
 *
 * - Call Control Live (Visible when the call is ongoing)
 *    - Mute
 *    - End Call
 *
 * - Call Complete (Visible when the call is wrapping up) - Allowas the user to mark the call as completed.
 *
 * - Call Recording Player (Visible when the call is wrapping up or completed) - Allows the user to play the recording of the call when available.
 *
 * - Call Summary (Visible when the call is ongoing, wrapping up, or completed) - Allowas the user to add a summary to the call.
 *
 * - Call Routing (Visible when the call is not ringing, ongoing, or wrapping up) - Show the routing information of the call.
 */
function ActionPanel() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  // =========================================================================
  // Selected Communication Log
  // =========================================================================
  /**
   * Communication log context.
   */
  const {
    selectedCommunicationLog,
    selectedCommunicationLogLoading
  } = useCommunicationLogContext();

  /**
   * Call recording data.
   * This data changes in respect of the selected communication log.
   */

  const {
    data: callRecording,
    fetching: isCallRecordingFetching,
    setFilter: setCallRecordingFilter
  } = useCallRecording();

  /**
   * Call routings data.
   * This data changes in respect of the selected communication log.
   */
  const {
    data: callRoutings,
    fetching: isCallRoutingsFetching,
    setFilter: setCallRoutingsFilter
  } = UseCallRoutings();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // const [completeCall, { loading: callCompleting }] = useCallCompleteMutation();

  const {
    mutate: completeCall,
    isPending: callCompleting
  } = trpc.callRouter.completeCall.useMutation();
  const callCompleteButtonTooltip = useMemo<string>(() => {
    if (!selectedCommunicationLog?.summary) {
      return "Call summary is required.";
    }
    if (callCompleting) {
      return "Call is being completed.";
    }
    return "";
  }, [callCompleting, selectedCommunicationLog]);

  // const {
  //   data: callRecording,
  //   setFilter: setCallRecordingFilter,
  //   fetching: isCallRecordingFetching,
  // } = useCallRecording();

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Updates the call recording filter to trigger a refetch when the selected
   * communication log changes.
   */
  useEffect(() => {
    setCallRecordingFilter({
      callId: selectedCommunicationLog?.id || ""
    });
  }, [selectedCommunicationLog?.id, setCallRecordingFilter]);

  /**
   * Updates the call routings filter to trigger a refetch when the selected
   * communication log changes.
   */
  useEffect(() => {
    setCallRoutingsFilter({
      callId: selectedCommunicationLog?.id || ""
    });
  }, [selectedCommunicationLog?.id, setCallRoutingsFilter]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`col-span-6 flex h-full flex-col overflow-hidden lg:col-span-4 ${!!selectedCommunicationLog ? "" : "hidden"}`} data-sentry-component="ActionPanel" data-sentry-source-file="page.tsx">
      <CallControlRingingCard data={selectedCommunicationLog} visible={selectedCommunicationLog?.status === CommunicationLogStatus.RINGING} data-sentry-element="CallControlRingingCard" data-sentry-source-file="page.tsx" />
      <CallCompleteCard callback={{
      onCompleteCall: async () => {
        if (!selectedCommunicationLog?.id) {
          return;
        }
        completeCall({
          data: {
            callId: selectedCommunicationLog.id
          }
        });
      }
    }} data={{
      callId: selectedCommunicationLog?.id!,
      clientName: selectedCommunicationLog?.clientName,
      clientPhoneNumber: selectedCommunicationLog?.clientPhoneNumber ?? "",
      dateEnded: selectedCommunicationLog?.dateEnded || new Date()
    }} disabled={!selectedCommunicationLog?.summary || callCompleting} loading={callCompleting} tooltip={callCompleteButtonTooltip} visible={!!selectedCommunicationLog?.id && selectedCommunicationLog?.status === CommunicationLogStatus.WRAPPING_UP} data-sentry-element="CallCompleteCard" data-sentry-source-file="page.tsx" />
      <CallControlLiveCard data={selectedCommunicationLog} visible={selectedCommunicationLog?.status === CommunicationLogStatus.ONGOING} data-sentry-element="CallControlLiveCard" data-sentry-source-file="page.tsx" />
      <CallInformationCard visible={!!selectedCommunicationLog && selectedCommunicationLog?.status !== CommunicationLogStatus.RINGING && selectedCommunicationLog?.status !== CommunicationLogStatus.ONGOING && selectedCommunicationLog?.status !== CommunicationLogStatus.WRAPPING_UP} data-sentry-element="CallInformationCard" data-sentry-source-file="page.tsx" />
      <div className={`flex h-full w-full flex-col items-center gap-4 overflow-y-auto bg-neutral-grey-surface p-4 ${selectedCommunicationLog?.status === CommunicationLogStatus.RINGING ? "hidden" : ""}`}>
        <CallRecordingPlayerCard loading={isCallRecordingFetching} processing={callRecording?.status === CallRecordingStatus.IN_PROGRESS} source={callRecording?.recordingUrl || callRecording?.thirdPartyUrl || undefined} restricted={callRecording?.status === CallRecordingStatus.RESTRICTED} visible={selectedCommunicationLog?.status === CommunicationLogStatus.WRAPPING_UP || selectedCommunicationLog?.status === CommunicationLogStatus.COMPLETED} data-sentry-element="CallRecordingPlayerCard" data-sentry-source-file="page.tsx" />
        <CallSummaryCard data={selectedCommunicationLog} initialMode={selectedCommunicationLog?.status === CommunicationLogStatus.ONGOING ? "edit" : "view"} visible={!!selectedCommunicationLog?.id && (selectedCommunicationLog?.status === CommunicationLogStatus.ONGOING || selectedCommunicationLog?.status === CommunicationLogStatus.WRAPPING_UP || selectedCommunicationLog?.status === CommunicationLogStatus.COMPLETED)} data-sentry-element="CallSummaryCard" data-sentry-source-file="page.tsx" />
        <CallRoutingCard loading={isCallRoutingsFetching} routings={callRoutings.map(callRouting => {
        return {
          agentName: callRouting.user?.fullName || "",
          date: callRouting.date,
          id: callRouting.id,
          status: callRouting.status,
          remark: callRouting.remark
        } satisfies CommunicationLogRouting;
      })} visible={!!selectedCommunicationLog && selectedCommunicationLog?.status !== CommunicationLogStatus.RINGING && selectedCommunicationLog?.status !== CommunicationLogStatus.ONGOING && selectedCommunicationLog?.status !== CommunicationLogStatus.WRAPPING_UP} data-sentry-element="CallRoutingCard" data-sentry-source-file="page.tsx" />
        <DevelopmentOnly data-sentry-element="DevelopmentOnly" data-sentry-source-file="page.tsx">
          <ActivityLogCard callId={selectedCommunicationLog?.id} customerPhoneNumber={selectedCommunicationLog?.clientPhoneNumber} visible={!!selectedCommunicationLog?.clientPhoneNumber} data-sentry-element="ActivityLogCard" data-sentry-source-file="page.tsx" />
        </DevelopmentOnly>
      </div>
    </div>;
}

/**
 * Panel that displays the communication logs.
 *
 * Displays the active, concluded, and missed communication logs.
 * - Active communication logs are displayed on the top of the panel.
 * - Concluded and Missed communication logs are viewed in separate tabs.
 *
 * Communication Logs are selectable.
 * - Selected communication log is highlighted.
 */
function CommunicationLogPanel() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================
  const urlSearchParam = useSearchParams();
  const router = useRouter();
  const tabParam = urlSearchParam.get("tab");
  const {
    user
  } = useAuthenticationContext();

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Communication Logs Active
    // =========================================================================
    communicationLogsActive,
    communicationLogsActiveLoading,
    fetchMoreCommunicationLogsActive,
    // =========================================================================
    // Communication Logs Concluded
    // =========================================================================
    communicationLogsConcluded,
    communicationLogsConcludedLoading,
    communicationLogsConcludedFetchingMore,
    fetchMoreCommunicationLogsConcluded,
    hasMoreCommunicationLogsConcluded,
    setCommunicationLogsFilter: setCommunicationLogsConcludedFilter,
    // =========================================================================
    // Communication Logs Missed
    // =========================================================================
    communicationLogsMissed,
    communicationLogsMissedLoading,
    communicationLogsMissedFetchingMore,
    fetchMoreCommunicationLogsMissed,
    hasMoreCommunicationLogsMissed,
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    setSelectedCommunicationLog
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [selectedTab, setSelectedTab] = useState(tabParam || user?.permissions.includes(Auth0Permission.CALL_SUMMARY_UPDATE_ALL) ? CommunicationLogTab.MISSED : CommunicationLogTab.ALL);

  /**
   * Communication log tabs.
   */
  const communicationLogTabs = useMemo<TabsProps["items"]>(() => {
    const _tabs: TabsProps["items"] = [{
      key: CommunicationLogTab.ALL,
      label: CommunicationLogTab.ALL
    }
    /** Uncomment for "pending" tab */
    // { key: "pending", label: "Pending" },
    ];

    /**
     * Missed call tab is visible only for users with the permission to view all call logs.
     */
    if (user?.permissions.includes(Auth0Permission.CALL_LOGS_VIEW_ALL)) {
      _tabs.push({
        key: CommunicationLogTab.MISSED,
        label: "Missed"
      });
      // Set the default tab based on the user permissions.
      setSelectedTab(CommunicationLogTab.MISSED);
    } else {
      // Set the default tab based on the user permissions.
      setSelectedTab(CommunicationLogTab.ALL);
    }
    return _tabs;
  }, [user?.permissions]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div data-testid={PlayWrightTestId.CommunicationLog.PANEL} className="col-span-6 flex h-full flex-col border-r lg:col-span-4" data-sentry-component="CommunicationLogPanel" data-sentry-source-file="page.tsx">
      <CommunicationLog classNames="!h-auto" data={communicationLogsActive} data-testid={PlayWrightTestId.CommunicationLog.SECTION_ACTIVE} hideListStatusIndicator loading={communicationLogsActiveLoading} onLogClick={({
      communicationLog
    }) => {
      setSelectedCommunicationLog(communicationLog);
    }} onMaxScroll={fetchMoreCommunicationLogsActive} data-sentry-element="CommunicationLog" data-sentry-source-file="page.tsx" />
      {/* <Divider className="!m-0" /> */}
      <div className="inline-flex h-16 w-full flex-col items-start justify-center gap-2 border-b border-t bg-white p-4">
        <div className="inline-flex items-center justify-start gap-2 self-stretch">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
            <Input.Search allowClear className="rounded-lg border bg-neutral-grey-light" enterButton
          // loading
          placeholder="Search" onSearch={value => {
            setCommunicationLogsConcludedFilter({
              from: value,
              to: value
            });
          }} variant="filled" data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
          </div>
          {/* <Button icon={<FilterOutlined />} type="text" /> */}
        </div>
      </div>
      {/* Tabs */}
      <div className="bg-white px-4">
        <Tabs defaultActiveKey="all" activeKey={selectedTab} items={communicationLogTabs} onChange={key => {
        router.push(`/?tab=${key}`);
        setSelectedTab(key as CommunicationLogTab);
      }} data-sentry-element="Tabs" data-sentry-source-file="page.tsx" />
      </div>

      {selectedTab === CommunicationLogTab.ALL ? <CommunicationLog classNames="flex-grow !h-auto !min-h-[100px]" data={communicationLogsConcluded} data-testid={PlayWrightTestId.CommunicationLog.SECTION_CONCLUDED} hasMoreData={hasMoreCommunicationLogsConcluded} loading={communicationLogsConcludedLoading} loadingMoreData={communicationLogsConcludedFetchingMore} onLogClick={({
      communicationLog
    }) => {
      setSelectedCommunicationLog(communicationLog);
    }} onMaxScroll={fetchMoreCommunicationLogsConcluded} /> : <PermissionRequired requiredPermissions={[Auth0Permission.CALL_LOGS_VIEW_ALL]}>
          <CommunicationLog classNames="flex-grow !h-auto !min-h-[100px]" data={communicationLogsMissed} data-testid={PlayWrightTestId.CommunicationLog.SECTION_MISSED} hasMoreData={hasMoreCommunicationLogsMissed} loading={communicationLogsMissedLoading} loadingMoreData={communicationLogsMissedFetchingMore} onLogClick={({
        communicationLog
      }) => {
        setSelectedCommunicationLog(communicationLog);
      }} onMaxScroll={fetchMoreCommunicationLogsMissed} />
        </PermissionRequired>}
    </div>;
}

/**
 * Panel that displays the contact information.
 *
 * This is only shown when a communication log is selected.
 *
 * Displays the following sections:
 * - Contact Profile - Displays the contact's profile information.
 * - Call Past Interactions - Displays the past interactions with the contact.
 */
function InformationPanel() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    selectedCommunicationLog
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: contacts,
    loading: contactsLoading,
    setFilter: setContactsFilter
  } = useContacts();

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  const contactProfileData = useMemo<ContactProfileProps["data"] | undefined>(() => {
    if (!selectedCommunicationLog) {
      return;
    }

    // const contact =
    //   selectedCommunicationLog.direction === CommunicationDirection.INBOUND
    //     ? selectedCommunicationLog.fromContact
    //     : selectedCommunicationLog.toContact;

    const contact = contacts[0];
    return {
      ...contact,
      id: contact?.id || selectedCommunicationLog.clientPhoneNumber,
      displayName: contact?.displayName,
      phoneNumber: selectedCommunicationLog.clientPhoneNumber,
      phoneNumbers: contact?.phoneNumbers || [],
      photoUrl: contact?.photoUrl || null,
      email: contact?.email || null,
      issues: contact?.issues || []
    };
  }, [contacts, selectedCommunicationLog]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  /**
   * Set the contact filter to the selected communication log's phone number.
   */
  useEffect(() => {
    setContactsFilter({
      searchText: selectedCommunicationLog?.clientPhoneNumber || "",
      includeFields: [ContactSearchField.PHONE_NUMBER]
    });
  }, [selectedCommunicationLog?.clientPhoneNumber, setContactsFilter]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`hidden h-full flex-col gap-4 overflow-x-hidden border-l bg-neutral-grey-surface p-4 lg:col-span-4 ${
  // Hide when nothing is selected.
  !!selectedCommunicationLog ? "lg:flex" : "hidden"}`} data-sentry-component="InformationPanel" data-sentry-source-file="page.tsx">
      <ContactProfile data={contactProfileData} loading={contactsLoading} visible={!!contactProfileData} data-sentry-element="ContactProfile" data-sentry-source-file="page.tsx" />
      <CallPastInteractionsCard
    // Always visible.
    visible={!!selectedCommunicationLog} clientPhoneNumber={selectedCommunicationLog?.clientPhoneNumber} data-sentry-element="CallPastInteractionsCard" data-sentry-source-file="page.tsx" />
    </div>;
}

/**
 * Panel that is displayed when no communication log is selected.
 *
 * Display a placeholder image and a text saying to select a communication log.
 */
function NoSelectedLogPanel() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    selectedCommunicationLog
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`col-span-6 inline-flex h-full w-full flex-col items-center justify-center bg-neutral-grey-surface lg:col-span-8 ${!selectedCommunicationLog ? "" : "hidden"}`} data-sentry-component="NoSelectedLogPanel" data-sentry-source-file="page.tsx">
      <Placeholder message="Select a Log" data-sentry-element="Placeholder" data-sentry-source-file="page.tsx" />
    </div>;
}

/**
 * Home page.
 * This is requires authentication to be accessed.
 */
function HomePage() {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <ApplicationDefaultProtectedPageLayout data-sentry-element="ApplicationDefaultProtectedPageLayout" data-sentry-component="HomePage" data-sentry-source-file="page.tsx">
      <div className="flex h-full">
        <div className="grid h-full w-full grid-cols-12 grid-rows-1">
          <CommunicationLogPanel data-sentry-element="CommunicationLogPanel" data-sentry-source-file="page.tsx" />
          <NoSelectedLogPanel data-sentry-element="NoSelectedLogPanel" data-sentry-source-file="page.tsx" />
          <ActionPanel data-sentry-element="ActionPanel" data-sentry-source-file="page.tsx" />
          <InformationPanel data-sentry-element="InformationPanel" data-sentry-source-file="page.tsx" />
        </div>
      </div>
    </ApplicationDefaultProtectedPageLayout>;
}
export default HomePage;