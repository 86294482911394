"use client";

import { useEffect, useState } from "react";

import { useAuthenticationContext } from "@/components/client/authentication";
import {
  trpc,
  TrpcRouterInputs,
  UseTrpcQueryCallbacks,
} from "@/components/client/trpc";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";
import { BusinessHour } from "@prisma/client";
import { BusinessHourUtility } from "../utilities";

/**
 * The arguments for the use business hours hook.
 */
type UseBusinessHoursArgs = UseTrpcQueryCallbacks<
  UseBusinessHoursReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The filter for fetching business hours.
 */
export type UseBusinessHoursFilter = Extract<
  TrpcRouterInputs["businessHourRouter"]["getBusinessHours"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use business hours hook.
 */
type UseBusinessHoursReturn = {
  /**
   * The paginated data for business hours.
   */
  data: BusinessHour[];
  /**
   * Indicates if the business hours are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Indicates if the business hours are loading for the first time.
   */
  loading: boolean;
  /**
   * Set the filter for fetching business hours.
   */
  setFilter: React.Dispatch<
    React.SetStateAction<UseBusinessHoursFilter | undefined>
  >;
};

/**
 * The use business hours hook type.
 */
type UseBusinessHours = (args?: UseBusinessHoursArgs) => UseBusinessHoursReturn;

/**
 * Hook to fetch, filter, or refetch business hours.
 */
export const useBusinessHours: UseBusinessHours = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for business hours.
   */
  const [businessHours, setBusinessHours] = useState<
    UseBusinessHoursReturn["data"]
  >([]);

  /**
   * The filter for fetching business hours.
   */
  const [businessHoursFilter, setBusinessHoursFilter] =
    useState<UseBusinessHoursFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: businessHoursData,
    isLoading: isBusinessHoursLoading,
    isFetching: isBusinessHoursFetching,
    refetch: refetchBusinessHours,
  } = trpc.businessHourRouter.getBusinessHours.useQuery();

  /**
   * Subscribe to business hours events.
   * - Add new business hours to the list if it is not existing.
   * - Update existing business hours when a business hours event is published.
   */
  const { status: onCallEventStatus } =
    trpc.businessHourRouter.onBusinessHoursUpdate.useSubscription(
      { userId: user?.id },
      {
        onData: (eventBusinessHours) => {
          /**
           * Nothing to do if there is no call in the published event.
           */
          if (!eventBusinessHours) return;

          /**
           * Business hours has not been fetched yet.
           */
          if (!businessHours) return;

          /**
           * This will hold the updated list of business hours.
           */
          const updatedBusinessHours = [...businessHours];

          for (const eventBusinessHour of eventBusinessHours) {
            /**
             * Index of the existing business hour matching the existing business
             * hours.
             */
            const existingBusinessHourIndex = businessHours.findIndex(
              (businessHours) => businessHours.id === eventBusinessHour.id,
            );

            /**
             * Indicates whether the business hour from the event exists in the
             * existing business hours.
             */
            const isBusinessHourExisting = existingBusinessHourIndex > -1;

            // Update the existing business hours since it already exists in the list.
            if (isBusinessHourExisting) {
              updatedBusinessHours[existingBusinessHourIndex] =
                eventBusinessHour;
            }
            // Add the new business hours on top of the list since it doesn't exist.
            else {
              updatedBusinessHours.unshift(eventBusinessHour);
            }

            setBusinessHours(updatedBusinessHours);
          }
        },
      },
    );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the business hours when the data changes.
   */
  useEffect(() => {
    setBusinessHours(businessHoursData || []);
  }, [businessHoursData]);

  /**
   * Refetch the business hours if the filter changed.
   * Reset the business hours pagination offset to 0.
   *
   * This would update the concluded communication logs showing on the screen.
   */
  useEffect(() => {
    refetchBusinessHours();
  }, [businessHoursFilter, refetchBusinessHours]);

  /**
   * Trigger the on status change callback when the on call event status changes.
   */
  useEffect(() => {
    args?.onEventListenerStatusChange?.({ status: onCallEventStatus });
  }, [args, onCallEventStatus]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    /**
     * Sorted business hours from Monday to Sunday.
     */
    data: BusinessHourUtility.sort({ businessHours }),
    fetching: isBusinessHoursFetching,
    loading: isBusinessHoursLoading,
    setFilter: setBusinessHoursFilter,
  };
};
