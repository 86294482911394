/**
 * External dependencies.
 */
import { Call, User } from "@prisma/client";

/**
 * Internal dependencies.
 */
import { ActivityLogType } from "@/components/common/activity-log/enumerations";
import {
  ActivityLogCall,
  ActivityLogTask,
} from "@/components/common/activity-log/types";
import { TaskWithUser } from "@/components/server/task/types";

/**
 * Arguments for converting a task record to an activity log task record.
 */
type FromTaskArgs = {
  /**
   * The task to convert to an activity log task.
   */
  task: TaskWithUser;
};

/**
 * Converts a task record to an activity log task record.
 */
export function fromTask({ task }: FromTaskArgs): ActivityLogTask {
  return {
    date: task.createdDate,
    id: task.id,
    status: task.status,
    title: task.description.replace("<p>", "").replace("</p>", ""),
    type: ActivityLogType.TASK,
    associatedUserName: task.user?.fullName,
  };
}

/**
 * Arguments for converting a call record to an activity log call record.
 */
type FromCallArgs = {
  /**
   * The call to convert to an activity log call.
   */
  call: Call & {
    /**
     * The user who created the call.
     */
    user?: Pick<User, "id" | "fullName">;
  };
};

/**
 * Converts a call record to an activity log call record.
 */
export function fromCall({ call }: FromCallArgs): ActivityLogCall {
  return {
    date: call.date,
    description: call.summary || undefined,
    direction: call.direction,
    id: call.id,
    status: call.status,
    title: `${call.status} - ${call.direction}`,
    type: ActivityLogType.CALL,
    associatedUserName: call.user?.fullName,
  };
}
