"use client";

/**
 * Third-party libraries.
 */
import BulletList from "@tiptap/extension-bullet-list";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import { BubbleMenu, Content, Editor, EditorContent, FloatingMenu, useEditor, UseEditorOptions } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { HTMLAttributes, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

/**
 * Project components.
 */
import { getHotkeyHandler, HotkeyItem } from "@/components/client/hooks/use-hotkeys";
import { PlayWrightTestId } from "@/tests/constants";
import { UnorderedListOutlined } from "@ant-design/icons";
import { EditorState } from "@tiptap/pm/state";
import { EditorProps } from "@tiptap/pm/view";
import { ShiftEnterHandlerExtension } from "./custom-extensions";
import "./rich-text-editor.css";
const RICH_TEXT_EDITOR_CLASS = "rich-text-editor";

/** Type for the RichTextEditor component. */
export type RichTextEditorProps = {
  /**
   * The class name for the editor.
   */
  className?: HTMLAttributes<HTMLDivElement>["className"];
  /**
   * The INITIAL content of the editor.
   *
   * IMPORTANT:
   * 1. Avoid setting this `onUpdate`. Why? Because you lose cursor focus
   * when you try to re-set this everytime.
   * 2. Only set this to a new value for completely new content (when cursor position doesn't matter.)
   * 3. If you need to watch this, use `onUpdate` and set to a separate state instead.
   * 4. You can always get the final value with `editor.getHTML()`.
   */
  content?: Content;
  /** A function that should return if the editor is editable. @defaultValue () => `true` */
  editable?: boolean;
  /**
   * The maximum number of lines to display before truncating the text.
   */
  lineClamp?: number;
  /**
   * MOD means CMD on Mac and CTRL on Windows.
   * This function gets called when the hotkey "Enter" is pressed.
   */
  onHotkey_ENTER?: (event: KeyboardEvent) => void;
  /**
   * MOD means CMD on Mac and CTRL on Windows.
   * This function gets called when the hotkey "MOD + S" is pressed.
   */
  onHotkey_MOD_S?: (event: KeyboardEvent) => void;
  /**
   * This function gets called when the hotkey "Escape" is pressed.
   */
  onHotkey_ESC?: (event: KeyboardEvent) => void;
  /**
   * Callback event triggered when the editor loses focus.
   */
  onBlur?: () => void;
  /**
   * A way for us to get the tiptap editor instance from the parent component.
   *
   * @example
   * const editorRef = useRef<Editor | null>(null);
   *
   * // 1. Reference the editor instance from the parent component
   * <RichTextEditor
   *   onCreate={({ editor }) => {
   *     editorRef.current = editor;
   *   }}
   * />
   *
   * // 2. Now you can perform any tiptap commands. (e.g. focus from a button in parent).
   * <Button onClick={() => {
   *   editorRef.current?.focus('all');
   * }}>Focus The Editor!</Button>
   */
  onCreate?: UseEditorOptions["onCreate"];
  /**
   * Gets called when the editor updates.
   *
   * IMPORTANT: Don't try to set `content` from here like when you use <input onChange={...} /> in regular React.
   * Reason is because you lose cursor focus when you try to re-set this everytime.
   *
   * @example
   * onUpdate={({ editor }) => {
   *   // The editor is empty.
   *   if (!editor.getText()?.length) {
   *     setEditorContent(undefined);
   *   }
   *   // The editor has contents.
   *   else {
   *     setEditorContent(editor.getHTML());
   *   }
   * }}
   */
  onUpdate?: UseEditorOptions["onUpdate"];
  /**  The placeholder text for the editor. */
  placeholder?: string;
  /**
   * Flag to determine whether to show the bubble menu.
   * @default false
   */
  showBubbleMenu?: boolean;
  /**
   * Flag to determine whether to show the floating menu.
   * @default false
   */
  showFloatingMenu?: boolean;
};

/**
 * A rich text editor component for all WYSIWYG (What You See Is What You Get) editor
 * usecases in the CCC frontend.
 *
 * Keyboard shortcuts also work out of the box here. See more: https://tiptap.dev/docs/editor/core-concepts/keyboard-shortcuts#page-title
 *
 */
export function RichTextEditor(props: RichTextEditorProps) {
  // ===========================================================================
  // States
  // ===========================================================================
  /**
   * Expanded state of the past interactions card ITEM SUMMARY.
   */
  const [expanded, setExpanded] = useState<boolean>();

  /** The tiptap editor. */
  const editorRef = useRef<Editor | null>(null);
  const [lineShouldClamp, setLineShouldClamp] = useState(false);
  const [placeholder, setPlaceholder] = useState(props.placeholder);

  // ===========================================================================
  // Variables
  // ===========================================================================
  /**
   * We personally prefer the hook approach.
   * But if you want to break the RichTextEditor into multiple components, you can use the provider approach.
   * https://tiptap.dev/docs/editor/getting-started/install/react#consume-the-editor-context-in-child-components
   *
   * Using the provider approach will allow you to access the `editor` instance using a `useCurrentEditor` hook.
   * For hook approach, just pass this around, no problem.
   */
  const editor = useEditor({
    extensions: [StarterKit.configure({
      // hardBreak: false, // Hard break adds SHIFT + ENTER to make <br />, which we don't want.
    }), BulletList, Image.configure({
      /** Enabling to true allows you to paste and drag images from the clipboard. */
      allowBase64: true
    }), Placeholder.configure({
      showOnlyWhenEditable: false,
      placeholder: props.placeholder,
      /** before:content-[attr(data-placeholder)] is needed so it displays in HTML. */
      emptyEditorClass: "cursor-text before:content-[attr(data-placeholder)] before:absolute before:top-0 before:left-0 before:opacity-50 before-pointer-events-none"
    }), ShiftEnterHandlerExtension],
    content: props.content,
    editorProps: {
      attributes: {
        /**
         * We're using tailwind-typography to style all the Rich Text.
         * You can customize it in `tailwind.config.ts > theme > extend > typography`.
         *
         * Adds line clamping to the content.
         */
        class: `prose prose-sm focus:outline-none break-word ${!expanded && props.lineClamp ? `line-clamp-${props.lineClamp}` : ""}`,
        /**
         * Test ID for PlayWright.
         */
        "data-testid": PlayWrightTestId.RichtTextEditor.RICH_TEXT_EDITOR
      },
      editable: ((_: unknown, _state: EditorState) => props.editable) as EditorProps["editable"]
    },
    onCreate: args => {
      editorRef.current = args.editor;
      props.onCreate?.(args);
    },
    onUpdate: props.onUpdate,
    onBlur: props.onBlur
  }
  // Ideally don't use the dependency array as it will cause the editor to re-render.
  // [props.placeholder, props.content],
  );

  /** Registers the handlers for the rich text editor. */
  const hotKeys = useMemo(() => {
    const handlers: HotkeyItem[] = [];
    if (props.onHotkey_ENTER) {
      handlers.push(["Enter", props.onHotkey_ENTER]);
    }
    if (props.onHotkey_MOD_S) {
      handlers.push(["mod+s", props.onHotkey_MOD_S]);
    }
    if (props.onHotkey_ESC) {
      handlers.push(["escape", props.onHotkey_ESC]);
    }
    return getHotkeyHandler(handlers);
  }, [props.onHotkey_ENTER, props.onHotkey_MOD_S, props.onHotkey_ESC]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Set the initial content of the editor.
   */
  useEffect(() => {
    if (editor) {
      editor.commands.setContent(props.content ?? null);
    }
  }, [editor, props.content]);

  /**
   * Make the editor editable or not.
   */
  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.setOptions({
      editable: props.editable
    });
  }, [editor, props.editable]);

  // /**
  //  * Update the place holder of the editor.
  //  */
  // useEffect(() => {
  //   if (!editor) {
  //     return;
  //   }

  //   setPlaceholder(props.placeholder);

  //   editor.extensionManager.extensions
  //     .filter((ext) => {
  //       console.debug("DEBUG", "extensionName", ext.name);
  //       return ext.name === "placeholder";
  //     })
  //     .forEach((ext) => {
  //       ext.options.placeholder = props.placeholder;
  //     });
  // }, [editor, props.placeholder]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!editorRef || !editorRef.current || !props.lineClamp || !props.content) {
      if (lineShouldClamp !== false) {
        if (expanded === undefined) {
          setExpanded(undefined);
        }
        setLineShouldClamp(false);
      }
      return;
    }
    /**
     * To resolve an eslint warning when trying to access the ref in the cleanup.
     * Solution is to make declare a local variable and use that in the cleanup instead.
     * This is because accessing `editorRef.current` directly is erratic.
     */
    let _clampedElementRef = editorRef.current;
    const targetNode = _clampedElementRef.view.dom;
    function countLinesInElement() {
      // Get the height of the div
      const divHeight = targetNode.scrollHeight;
      // const divHeight = targetNode.getBoundingClientRect().height;1

      // Get the line height from the computed styles
      const lineHeight = parseInt(window.getComputedStyle(targetNode).lineHeight, 10);

      // Calculate the number of lines by dividing the div height by the line height
      return Math.round(divHeight / lineHeight);
    }
    const _lineShouldClamp = countLinesInElement() > props.lineClamp;
    if (lineShouldClamp !== _lineShouldClamp) {
      if (expanded === undefined) {
        setExpanded(!_lineShouldClamp);
      }
      setLineShouldClamp(_lineShouldClamp);
    }
  });

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`relative flex w-full flex-col ${props.className || ""}`} data-sentry-component="RichTextEditor" data-sentry-source-file="rich-text-editor.tsx">
      {/* Appears on a new line. Know more: https://tiptap.dev/docs/editor/getting-started/style-editor/custom-menus#floating-menu */}
      <FloatingMenu editor={editor}
    /** Show on <br /> and empty <p> tags. */ shouldShow={_props => {
      if (props.showFloatingMenu && _props.editor.isEditable) {
        // These 3 lines are how to get the current node in TipTap.
        // Learn more: https://github.com/ueberdosis/tiptap/discussions/3787#discussioncomment-10390236
        const state = _props.editor.view.state;
        const from = state.selection.from;
        const currentPos = state.doc.resolve(from);

        /** Empty paragraph. <p></p> */
        if (_props.editor.isActive("paragraph") && currentPos.node().content.size <= 0) return true;

        /** A linebreak. <br />.
         * We can't use _props.editor.isActive("hardBreak") because making linebreaks
         * also means we're focusing on the next node (e.g. the parent paragraph).
         *
         * Luckily, we can always check the last child of the current paragraph, if it's a "hardBreak".
         */
        if (currentPos.node().lastChild?.type.name === "hardBreak") return true;
      }
      return false;
    }} className="flex gap-x-1 rounded-md bg-gray-200 bg-gradient-to-br from-tpl-red to-tpl-red-dark p-1 text-white shadow-md" tippyOptions={{
      duration: 250,
      animation: "fade"
    }} data-sentry-element="FloatingMenu" data-sentry-source-file="rich-text-editor.tsx">
        <MenuTipButton icon={"H1"} onClick={() => editor?.chain()?.focus()?.toggleHeading({
        level: 1
      })?.run()} active={editor?.isActive("heading", {
        level: 1
      })} data-sentry-element="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx" />
        <MenuTipButton icon={"H2"} onClick={() => editor?.chain()?.focus()?.toggleHeading({
        level: 2
      })?.run()} active={editor?.isActive("heading", {
        level: 2
      })} data-sentry-element="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx" />
        <MenuTipButton icon={"H3"} onClick={() => editor?.chain()?.focus()?.toggleHeading({
        level: 3
      })?.run()} active={editor?.isActive("heading", {
        level: 3
      })} data-sentry-element="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx" />
        <MenuTipButton icon={<UnorderedListOutlined />} onClick={() => editor?.chain()?.focus()?.toggleBulletList()?.run()} active={editor?.isActive("bulletList")} data-sentry-element="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx" />
        <MenuTipButton icon={<UnorderedListOutlined />} onClick={() => editor?.chain()?.focus()?.toggleOrderedList()?.run()} active={editor?.isActive("orderedList")} data-sentry-element="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx" />
      </FloatingMenu>

      {/* Appears after selecting text. Know more: https://tiptap.dev/docs/editor/getting-started/style-editor/custom-menus#bubble-menu */}
      <BubbleMenu editor={editor} className="flex gap-x-1 rounded-md bg-gray-200 bg-gradient-to-br from-tpl-red to-tpl-red-dark p-1 text-white shadow-md" tippyOptions={{
      duration: 250,
      animation: "fade"
    }} shouldShow={_props => {
      return props.showBubbleMenu || false;
    }} data-sentry-element="BubbleMenu" data-sentry-source-file="rich-text-editor.tsx">
        <MenuTipButton icon={"B"} onClick={() => editor?.chain()?.focus()?.toggleBold()?.run()} active={editor?.isActive("bold")} data-sentry-element="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx" />
        <MenuTipButton icon={"• -"} onClick={() => editor?.chain()?.focus()?.toggleBulletList()?.run()} active={editor?.isActive("bulletList")} data-sentry-element="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx" />
        <MenuTipButton icon={<span className="text-xs">{"1. -"}</span>} onClick={() => editor?.chain()?.focus()?.toggleOrderedList()?.run()} active={editor?.isActive("orderedList")} data-sentry-element="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx" />
      </BubbleMenu>

      <EditorContent className={props.editable ? "box-border !rounded border bg-neutral-grey-light px-[11px] py-[3px]" : "p-2.5"} editor={editor} onKeyDown={hotKeys} tabIndex={-1} style={props.editable ? {
      borderColor: "#d9d9d9"
    } : undefined} data-sentry-element="EditorContent" data-sentry-source-file="rich-text-editor.tsx" />
      {lineShouldClamp && !expanded && <span className="flex w-full justify-end bg-transparent text-sm">
          <button className="text-semantic-blue" onClick={() => {
        setExpanded(true);
      }}>
            See More
          </button>
        </span>}
      {lineShouldClamp && expanded && <span className="right-0 flex justify-end bg-transparent text-sm">
          <button className="text-semantic-blue" onClick={() => {
        setExpanded(false);
      }}>
            Collapse
          </button>
        </span>}
    </div>;
}

/** Type for the MenuTipButton component. */
type MenuTipButtonProps = {
  /** The icon to render for the menu button. */
  icon: React.ReactNode;
  /** @defaultValue `false` */
  active?: boolean;
  /** The callback to be called when the button is clicked. */
  onClick: () => void;
};

/** A single menu tip button with proper styles. */
export function MenuTipButton(props: MenuTipButtonProps) {
  return <button className={`flex h-6 w-6 items-center justify-center rounded-md p-1 hover:bg-white/20 ${props.active ? "bg-white/20" : ""}`} onClick={props.onClick} data-sentry-component="MenuTipButton" data-sentry-source-file="rich-text-editor.tsx">
      {props.icon}
    </button>;
}