const DEFAULT_PORT = process.env.PORT ? parseInt(process.env.PORT) : 3000;

export const TrpcConfiguration = {
  http: {
    port: DEFAULT_PORT,
  },
  webSocket: {
    port: process.env.NODE_ENV === "production" ? DEFAULT_PORT : 3001,
  },
};
