"use client";

/**
 * Third-party libraries.
 */
import { CallDirection, CallStatus } from "@prisma/client";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { UseCallsConcludedFilter, useCallsActiveUser, useCallsConcluded } from "@/components/client/call";
import { UseCallsMissedFilter, useCallsMissed } from "@/components/client/call/hooks/use-calls-missed";
import { CommunicationLog, CommunicationLogGroup, CommunicationLogsGroupedByDate } from "@/components/client/communication-log/types";
import { CommunicationLogUtility } from "@/components/client/communication-log/utilities";
import { useNetworkContext } from "@/components/client/network";
import { useNotificationContext } from "@/components/client/notification";
import { trpc } from "@/components/client/trpc";
import { useTwilioContext } from "@/components/client/twilio";
import { CallUtility } from "@/components/server/call/utilities";

/**
 * Argument that contains the call ID.
 */
type CallIdArgs = {
  /**
   * Internal ID of the call.
   */
  callId: string;
};
type CommunicationLogFilter = UseCallsConcludedFilter | UseCallsMissedFilter | undefined;

/**
 * Communication Log context.
 *
 * This provides context for the communication logs such as:
 * - Active communication logs.
 * - Concluded communication logs.
 * - Selected communication log.
 *
 * This also provides functions for interacting with the communication log.
 */
type CommunicationLogContext = {
  // ===========================================================================
  // Call Actions
  // ===========================================================================
  /**
   * Accept an incoming call.
   */
  accept: (args: CallIdArgs) => Promise<void>;
  /**
   * Cancel, end, or reject a call.
   *
   * - Cancel
   *    - Outbound call that is ringing.
   * - Reject
   *    - Inbound call that is ringing.
   * - End
   *    - Ongoing inbound or outbound call.
   */
  hangUp: (args: CallIdArgs & {
    /**
     * The direction of the call direction.
     */
    direction: CallDirection;
    /**
     *  Remark for hanging up the call.
     */
    remark?: string;
    /**
     * The status of the call.
     */
    status: CallStatus;
  }) => Promise<void>;
  /**
   * Set show call reject modal.
   */
  hideCallRejectedModal: () => void;
  /**
   * Check if the call is being accepted.
   */
  isAccepting: (args: CallIdArgs) => boolean;
  /**
   * Check if the call is being canceled, ended, or rejected.
   */
  isHangingUp: (args: CallIdArgs) => boolean;
  /**
   * ID of the call routing that was rejected.
   */
  rejectedCallRoutingId: string | null;
  /**
   * Show call reject modal.
   */
  showCallRejectModal: boolean;
  // ===========================================================================
  // Calls Missed
  // ===========================================================================
  /**
   * Date groups of customer missed calls.
   *
   * These are missed calls of customers which have been grouped by date.
   */
  communicationLogsMissed: CommunicationLogGroup[];
  /**
   * Indicates that the calls missed query is still loading.
   */
  communicationLogsMissedLoading: boolean;
  /**
   * Indicates that the calls missed query is being refetched.
   */
  communicationLogsMissedFetchingMore: boolean;
  /**
   * Fetches more calls missed.
   */
  fetchMoreCommunicationLogsMissed: () => Promise<void>;
  /**
   * Indicates that there are more calls missed to fetch.
   */
  hasMoreCommunicationLogsMissed: boolean;
  // ===========================================================================
  // Communication Logs Active
  // ===========================================================================
  /**
   * Active communication logs.
   *
   * These are logs which are currently being handled by the agent.
   */
  communicationLogsActive: CommunicationLogsGroupedByDate;
  /**
   * Indicates that the active communication logs are loading.
   */
  communicationLogsActiveLoading: boolean;
  /**
   * Indicates that the active communication logs are being refetched.
   */
  communicationLogsActiveFetchingMore: boolean;
  /**
   * Fetches more active communication logs.
   */
  fetchMoreCommunicationLogsActive: () => Promise<void>;
  /**
   * Indicates that the logged in user has active communication log.
   */
  hasActiveCommunicationLog: boolean;
  /**
   * Indicates that there are more active communication logs to fetch.
   */
  hasMoreCommunicationLogsActive: boolean;
  // ===========================================================================
  // Communication Logs Concluded
  // ===========================================================================
  /**
   * Communication logs that have been concluded.
   *
   * These are logs which have already marked as concluded.
   * This includes canceled, completed, failed, missed, and rejected calls.
   */
  communicationLogsConcluded: CommunicationLogsGroupedByDate;
  /**
   * Indicates that the communication logs are loading.
   */
  communicationLogsConcludedLoading: boolean;
  /**
   * Indicates that the communication logs are being refetched.
   */
  communicationLogsConcludedFetchingMore: boolean;
  /**
   * Fetches more communication logs.
   */
  fetchMoreCommunicationLogsConcluded: () => Promise<void>;
  /**
   * Indicates that there are more communication logs to fetch.
   */
  hasMoreCommunicationLogsConcluded: boolean;
  /**
   * Sets the filter for the concluded calls.
   */
  setCommunicationLogsFilter: React.Dispatch<React.SetStateAction<CommunicationLogFilter>>;
  // ===========================================================================
  // Selected Communication Log
  // ===========================================================================
  /**
   * Selected communication log.
   * This is the currently active communication log from the communication log list.
   */
  selectedCommunicationLog: CommunicationLog | null;
  /**
   * Indicates that the selected communication log is loading.
   */
  selectedCommunicationLogLoading: boolean;
  /**
   * Sets the selected communication log.
   * This causes the other panels to display corresponding information in respect
   * of the active communication log record.
   */
  setSelectedCommunicationLog: (communicationLog: CommunicationLog | null) => void;
};

/**
 * Communication Log related context.
 */
const CommunicationLogContext = React.createContext<CommunicationLogContext>({
  // ===========================================================================
  // Call Actions
  // ===========================================================================
  accept: async () => {},
  hangUp: async () => {},
  hideCallRejectedModal: () => {},
  isAccepting: () => false,
  isHangingUp: () => false,
  rejectedCallRoutingId: null,
  showCallRejectModal: false,
  // ===========================================================================
  // Communication Log Active
  // ===========================================================================
  communicationLogsActive: [],
  communicationLogsActiveLoading: true,
  communicationLogsActiveFetchingMore: false,
  fetchMoreCommunicationLogsActive: async () => {},
  hasActiveCommunicationLog: false,
  hasMoreCommunicationLogsActive: true,
  // ===========================================================================
  // Communication Log Concluded
  // ===========================================================================
  communicationLogsConcluded: [],
  communicationLogsConcludedLoading: true,
  communicationLogsConcludedFetchingMore: false,
  fetchMoreCommunicationLogsConcluded: async () => {},
  hasMoreCommunicationLogsConcluded: true,
  setCommunicationLogsFilter: () => {},
  // ===========================================================================
  // Calls Missed
  // ===========================================================================
  communicationLogsMissed: [],
  communicationLogsMissedLoading: true,
  communicationLogsMissedFetchingMore: false,
  fetchMoreCommunicationLogsMissed: async () => {},
  hasMoreCommunicationLogsMissed: true,
  // ===========================================================================
  // Selected Communication Log
  // ===========================================================================
  selectedCommunicationLog: null,
  selectedCommunicationLogLoading: true,
  setSelectedCommunicationLog: () => {}
});

/**
 * Use Communication Log context.
 */
export const useCommunicationLogContext = () => {
  return React.useContext(CommunicationLogContext);
};

/**
 * Communication log context provider.
 */
export const CommunicationLogContextProvider = ({
  children
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  // const { setActiveCalls } = useApplicationContext();

  const {
    user
  } = useAuthenticationContext();
  const {
    notification
  } = useNotificationContext();
  const {
    setCallsUserActiveEventSubscriptionStatus: setCallActiveEventSubscriptionStatus,
    setCallsMissedEventSubscriptionStatus: setCallMissedEventSubscriptionStatus,
    setCallsConcludedEventSubscriptionStatus: setCallConcludedEventSubscriptionStatus
  } = useNetworkContext();
  const {
    getCall: getTwilioCall
  } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // const [callsConcluded, setCallsConcluded] =
  //   useState<TrpcRouterOutputs["callRouter"]["getConcludedCalls"]>();

  /**
   * ID of the call routing that was rejected.
   * - Populate this if you want to show the call reject modal.
   * - Clear this if you want to hide the call reject modal.
   */
  const [rejectedCallRoutingId, setRejectedCallRoutingId] = useState<CommunicationLogContext["rejectedCallRoutingId"]>(null);

  // trpc.callRouter.onCallEvent.useSubscription(undefined, {
  //   onData: (data) => {
  //     setCallsConcluded(data);
  //   },
  // });

  // ===========================================================================
  // Call Actions
  // ===========================================================================

  /**
   * Call IDs that are being accepted.
   */
  const [callIdsAccepting, setCallIdsAccepting] = useState<string[]>([]);

  /**
   * Call IDs that are being canceled or rejected.
   */
  const [callIdsHangingUp, setCallIdsHangingUp] = useState<string[]>([]);

  /**
   * Data for filtering the concluded and missed communication logs.
   */
  const [communicationLogsFilter, setCommunicationLogsFilter] = useState<CommunicationLogFilter>();

  // ===========================================================================
  // Selected Communication Log
  // ===========================================================================

  /**
   * Indicates which communication log is currently active on the screen.
   * This could control which subpanels are shown.
   */
  const [selectedCommunicationLog, setSelectedCommunicationLog] = useState<CommunicationLog | null>(null);

  /**
   * Indicates that the selected communication log is loading.
   */
  const [selectedCommunicationLogLoading, setSelectedCommunicationLogLoading] = useState<CommunicationLogContext["selectedCommunicationLogLoading"]>(false);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // /**
  //  * The current offset for fetching more communication logs.
  //  */
  // const [concludedCallsPaginationOffset, setConcludedCallsPaginationOffset] =
  //   useState<number>(APPLICATION_CONFIGURATION.pagination.offset);

  // const [missedCallsPaginationOffset, setMissedCallsPaginationOffset] =
  //   useState(APPLICATION_CONFIGURATION.pagination.offset);

  // const [_acceptCall] = useCallAcceptMutation();
  const {
    mutate: _acceptCall
  } = trpc.callRouter.acceptCall.useMutation();
  // const [_rejectCall] = useCallRejectMutation();
  const {
    mutate: _rejectCall
  } = trpc.callRouter.rejectCall.useMutation();
  // const [_cancelCall] = useCallCancelMutation();
  const {
    mutate: _cancelCall
  } = trpc.callRouter.cancelCall.useMutation();
  const {
    data: callsActiveUser,
    fetching: callsActiveFetching,
    fetchMore: fetchMoreCallsActiveUser,
    hasMore: hasMoreCallsActiveUser,
    loading: callsActiveLoadingUser
  } = useCallsActiveUser({
    /**
     * Does the following on call events:
     * - Show a notification for incoming calls.
     * - Dismiss the notification if the call is no longer ringing.
     * - Update the selected communication log if it is the same call as the
     * active call event.
     */
    onEvent: async ({
      data: _callsActiveUser,
      eventData: eventCall
    }) => {
      if (!eventCall) return;
      /**
       * Indicates that the user can view the call.
       */
      const isCallUserViewable = CallUtility.isUserViewable({
        call: {
          status: eventCall.status,
          userId: eventCall.userId
        },
        userId: user?.id!
      });

      /**
       * Update the list of active calls in the system.
       */
      const existingCallIndex = _callsActiveUser.findIndex(call => call.id === eventCall.id);

      /**
       * Indicates whether the call exists in the existing calls agent list.
       */
      const isCallExisting = existingCallIndex > -1;

      /**
       * A generated key used for tagging a notification with a unique identifier.
       */
      const notificationTag = `notification-call-${eventCall.id}`;

      // Call is active and is viewable by the user.
      if (isCallUserViewable) {
        if (!isCallExisting) {
          // Show a desktop notification for incoming calls.
          if (eventCall.direction === CallDirection.INBOUND) {
            notification.desktop({
              tag: notificationTag,
              message: `Incoming call from ${eventCall.from}`,
              title: `Incoming Call`
            });
          }
        }

        /**
         * Remove the notification if the call is no longer ringing.
         */
        if (eventCall.status !== CallStatus.QUEUED) {
          // Remove the notification.
          notification.close({
            tag: notificationTag
          });
        }
        if (
        // Call is assigned to the current user.
        eventCall.userId === user?.id && (
        // Auto select a new call in the active call section.
        !isCallExisting ||
        /**
         * Update the selected communication log if it is the same call.
         * This is to ensure the call details are up to date.
         */
        selectedCommunicationLog?.id === eventCall.id)) {
          _setSelectedCommunicationLog(CommunicationLogUtility.fromCall({
            call: eventCall
          }));
        }
      }
      // Call is not viewable by the user.
      else {
        // Remove the notification.
        notification.close({
          tag: notificationTag
        });
      }
    },
    /**
     * Keep track of the subscription status for active user calls.
     */
    onEventListenerStatusChange: ({
      status
    }) => {
      setCallActiveEventSubscriptionStatus(status);
    }
  });
  const {
    data: callsConcluded,
    fetching: callsConcludedFetching,
    fetchMore: fetchMoreCallsConcluded,
    hasMore: hasMoreCallsConcluded,
    loading: callsConcludedLoading,
    setFilter: setCallsConcludedFilter
  } = useCallsConcluded({
    /**
     * Update the selected communication log when there is a concluded call event
     * and it is the same call as the selected communication log.
     */
    onEvent: ({
      data: _callsConcluded,
      eventData: eventCall
    }) => {
      if (!eventCall) return;

      // Update the selected communication log if it is the same call.
      if (selectedCommunicationLog?.id === eventCall.id) {
        _setSelectedCommunicationLog(CommunicationLogUtility.fromCall({
          call: eventCall
        }));
      }
    },
    /**
     * Keep track of the subscription status for concluded calls.
     */
    onEventListenerStatusChange: ({
      status
    }) => {
      setCallConcludedEventSubscriptionStatus(status);
    }
  });
  const {
    data: callsMissed,
    fetching: callsMissedFetching,
    fetchMore: fetchMoreCallsMissed,
    hasMore: hasMoreCallsMissed,
    loading: callsMissedLoading,
    setFilter: setCallsMissedFilter
  } = useCallsMissed({
    /**
     * Keep track of the subscription status for missed calls.
     */
    onEventListenerStatusChange: ({
      status
    }) => {
      setCallMissedEventSubscriptionStatus(status);
    }
  });

  /**
   * Active calls query.
   * Active calls are calls that are in the following statuses:
   * - Queued (Assigned to the current user)
   * - In Progress
   * - Wrapping Up
   */
  // const {
  //   data: callsUserActiveData,
  //   networkStatus: callsActiveNetworkStatus,
  //   updateQuery: updateCallsUserActiveQuery,
  // } = useCallsUserActiveQuery({
  //   variables: {
  //     limit: APPLICATION_CONFIGURATION.pagination.limit,
  //     offset: APPLICATION_CONFIGURATION.pagination.offset,
  //   },
  //   notifyOnNetworkStatusChange: true,
  // });

  // const { data: callsUserActiveData, isFetching: callsActiveNetworkLoading } =
  //   trpc.callRouter.getActiveUserCalls.useQuery(
  //     {
  //       limit: APPLICATION_CONFIGURATION.pagination.limit,
  //       offset: APPLICATION_CONFIGURATION.pagination.offset,
  //     },
  //     {
  //       enabled: !!user?.id,
  //     },
  //   );

  /**
   * Concluded calls query.
   * This fetches the concluded calls in the system.
   *
   * Concluded calls are calls that are in final statuses listed as follows:
   * - Canceled
   * - Completed
   * - Failed
   * - Missed
   * - Rejected
   */
  // const {
  //   data: callsConcludedData,
  //   fetchMore: fetchMoreCallsConcluded,
  //   loading: loadingCallsConcluded,
  //   networkStatus: callsConcludedNetworkStatus,
  //   refetch: refetchCallsConcluded,
  //   updateQuery: updateCallsConcludedQuery,
  // } = useCallsConcludedQuery({
  //   variables: {
  //     limit: APPLICATION_CONFIGURATION.pagination.limit,
  //     offset: APPLICATION_CONFIGURATION.pagination.offset,
  //   },
  //   notifyOnNetworkStatusChange: true,
  // });

  // /** Calls missed query. */
  // const {
  //   data: callsMissedData,
  //   fetchMore: fetchMoreCommunicationLogsMissed,
  //   loading: loadingCommunicationLogsMissed,
  //   networkStatus: communicationLogsMissedNetworkStatus,
  //   refetch: refetchCallsMissedQuery,
  //   updateQuery: updateCallsMissedQuery,
  // } = useCallsMissedQuery({
  //   skip:
  //     !user?.id ||
  //     /**
  //      * Users without this permission will not be able to see missed calls tab.
  //      */
  //     !user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_ALL),
  //   variables: {
  //     limit: APPLICATION_CONFIGURATION.pagination.limit,
  //     offset: APPLICATION_CONFIGURATION.pagination.offset,
  //   },
  //   notifyOnNetworkStatusChange: true,
  // });

  // /**
  //  * Retrieves the details of a single call.
  //  */
  // const [getCall] = useCallLazyQuery();

  const {
    refetch: refetchCall
  } = trpc.callRouter.getCall.useQuery({
    filter: {
      id: selectedCommunicationLog?.id!
    }
  }, {
    enabled: false
  });

  // /**
  //  * Subscribe to call events.
  //  * - Active Calls
  //  *  - Adds the active calls to the list of active calls.
  //  *  - Keeps the active calls list updated.
  //  *  - Connects the agent to the conference call upon answering.
  //  * - Concluded Calls
  //  *  - Adds the concluded calls to the list of concluded calls.
  //  *  - Keeps the active calls list updated.
  //  */
  // useCallEventSubscription({
  //   skip: !user?.id,
  //   variables: {
  //     input: {
  //       userId: user?.id || "",
  //     },
  //   },
  //   /**
  //    * Updates the active and concluded calls when there is a call event.
  //    */
  //   onData({ data }) {
  //     /**
  //      * The call that triggered the event.
  //      */
  //     const eventCall = data.data?.callEvent;

  //     if (!eventCall) return;

  //     /**
  //      * Update the active calls data when there is a call event.
  //      * This is used to track to whom the calls are assigned to.
  //      */
  //     if (
  //       CALL_STATUSES_ONGOING_WITH_PENDING.includes(eventCall.status) &&
  //       !!eventCall.userId
  //     ) {
  //       /**
  //        * Update the list of active calls in the system.
  //        */
  //       setActiveCalls((previousActiveCalls) => {
  //         const existingCallIndex = previousActiveCalls.findIndex(
  //           (call) => call.id === eventCall.id,
  //         );

  //         /**
  //          * Copy of the active calls list.
  //          *
  //          * A copy is necessary because the active calls list is immutable.
  //          */
  //         const updatedActiveCalls = !previousActiveCalls?.length
  //           ? []
  //           : [...previousActiveCalls];

  //         // Update the existing call in the active calls list.
  //         if (existingCallIndex > -1) {
  //           updatedActiveCalls[existingCallIndex] = eventCall;

  //           return updatedActiveCalls;
  //         }

  //         // Add the call to the active calls list.
  //         return [...updatedActiveCalls, eventCall];
  //       });
  //     }
  //     // The call is not in progress. Remove it from the active calls list.
  //     else {
  //       /**
  //        * Update the list of active calls in the system.
  //        * Remove the call from the active calls list.
  //        */
  //       setActiveCalls((previousActiveCalls) => {
  //         if (!previousActiveCalls?.length) {
  //           return [];
  //         }

  //         const existingCallIndex = previousActiveCalls.findIndex(
  //           (call) => call.id === eventCall.id,
  //         );

  //         /**
  //          * Copy of the active calls list.
  //          *
  //          * A copy is necessary because the active calls list is immutable.
  //          */
  //         const updatedActiveCalls = !previousActiveCalls?.length
  //           ? []
  //           : [...previousActiveCalls];

  //         // Call is in the active calls list.
  //         if (existingCallIndex > -1) {
  //           if (updatedActiveCalls.length === 1) {
  //             return [];
  //           }

  //           // Remove the call from the list.
  //           updatedActiveCalls.splice(existingCallIndex, 1);
  //         }

  //         // Return the updated active calls list.
  //         return updatedActiveCalls;
  //       });
  //     }

  //     /**
  //      * Update the active calls data when there is a call event.
  //      *
  //      * - Active Calls
  //      *  - Adds the active calls to the list of active calls.
  //      *  - Keeps the active calls list updated.
  //      *  - Connects the agent to the conference call upon answering.
  //      */
  //     updateCallsUserActiveQuery((previousData) => {
  //       /**
  //        * Nothing to do if there is no call in the published event.
  //        */
  //       if (!eventCall) return previousData;

  //       /**
  //        * Index of the existing call in the calls agent list matching the
  //        * call in the ublished event.
  //        */
  //       const existingCallIndex = previousData.callsUserActive.items?.findIndex(
  //         (call) => call.id === eventCall.id,
  //       );

  //       /**
  //        * Indicates whether the call exists in the existing calls agent list.
  //        */
  //       const isCallExisting = existingCallIndex > -1;

  //       /**
  //        * Indicates that the user can view the call.
  //        */
  //       const isCallUserViewable = CallUtility.isUserViewable({
  //         call: {
  //           status: eventCall.status,
  //           userId: eventCall.userId,
  //         },
  //         user: user!,
  //       });

  //       /**
  //        * This will hold the updated list of agent calls.
  //        */
  //       const updatedCalls = [...previousData.callsUserActive.items];

  //       /**
  //        * A generated key used for tagging a notification with a unique identifier.
  //        */
  //       const notificationTag = `notification-call-${eventCall.id}`;

  //       // Call is viewable by the user.
  //       if (
  //         isCallUserViewable &&
  //         CALL_STATUSES_ACTIVE.includes(eventCall.status)
  //       ) {
  //         // Update the existing call since it already exists in the list.
  //         if (isCallExisting) {
  //           updatedCalls[existingCallIndex] = eventCall;
  //         }
  //         // Add the new call on top of the list since it doesn't exist.
  //         else {
  //           updatedCalls.unshift(eventCall);

  //           // Show a desktop notification for incoming calls.
  //           if (eventCall.direction === CallDirection.Inbound) {
  //             notification.desktop({
  //               tag: notificationTag,
  //               message: `Incoming call from ${eventCall.from}`,
  //               title: `Incoming Call`,
  //             });
  //           }
  //         }

  //         if (
  //           // Auto select a new call in the active call section.
  //           !isCallExisting ||
  //           /**
  //            * Update the selected communication log if it is the same call.
  //            * This is to ensure the call details are up to date.
  //            */
  //           selectedCommunicationLog?.id === eventCall.id
  //         ) {
  //           _setSelectedCommunicationLog(
  //             CommunicationLogUtility.fromCall({
  //               call: eventCall,
  //             }),
  //           );

  //           /**
  //            * Remove the notification if the call is no longer ringing.
  //            */
  //           if (eventCall.status !== CallStatus.Queued) {
  //             // Remove the notification.
  //             notification.close({
  //               tag: notificationTag,
  //             });
  //           }
  //         }

  //         /**
  //          * Call was accepted/created by the user, connect the user to the
  //          * conference.
  //          */
  //         if (
  //           // Call is inbound and is already accepted by the user.
  //           (eventCall.status === CallStatus.InProgress &&
  //             eventCall.direction === CallDirection.Inbound) ||
  //           // Call is outbound and is already answered.
  //           (eventCall.status === CallStatus.InProgress &&
  //             eventCall.direction === CallDirection.Outbound)
  //         ) {
  //           /**
  //            * Arguments for getting the customer phone number from a call.
  //            */
  //           type GetCustomerPhoneNumberArgs = {
  //             /**
  //              * The call record.
  //              */
  //             call: typeof eventCall;
  //           };

  //           /**
  //            * Get the customer phone number from the call.
  //            *
  //            * @returns The customer phone number.
  //            */
  //           const getCustomerPhoneNumber = ({
  //             call,
  //           }: GetCustomerPhoneNumberArgs) => {
  //             switch (call.direction) {
  //               case CallDirection.Inbound:
  //                 return call.from;
  //                 break;
  //               case CallDirection.Outbound:
  //                 return call.to;
  //                 break;
  //               default:
  //                 throw new Error("Invalid call direction.");
  //             }
  //           };

  //           /**
  //            * https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceconnectconnectoptions
  //            */
  //           connectToConference({
  //             callId: eventCall.id,
  //             conferenceFriendlyName: TwilioConferenceUtility.getFriendlyName({
  //               customerPhoneNumber: getCustomerPhoneNumber({
  //                 call: eventCall,
  //               }),
  //             }),
  //           });
  //         }
  //       }
  //       // Call is not viewable by the user.
  //       else if (isCallExisting) {
  //         // Remove the call from the list.
  //         updatedCalls.splice(existingCallIndex, 1);

  //         // Close the notification if it is removed.
  //         notification.close({
  //           tag: notificationTag,
  //         });
  //       }

  //       // Sort updatedCallsAgent from newest to oldest date.
  //       updatedCalls.sort((a, b) => {
  //         const dateA = new Date(a.date);
  //         const dateB = new Date(b.date);
  //         return dateB.getTime() - dateA.getTime();
  //       });

  //       return Object.assign({} as CallsUserActiveQuery, previousData, {
  //         callsUserActive: {
  //           ...previousData.callsUserActive,
  //           items: updatedCalls,
  //         },
  //       });
  //     });

  //     /**
  //      * Update the concluded calls data when there is a call event.
  //      *
  //      * - Concluded Calls
  //      *  - Adds the concluded calls to the list of concluded calls.
  //      *  - Keeps the active calls list updated.
  //      */
  //     updateCallsConcludedQuery((previousData) => {
  //       /**
  //        * Nothing to do if there is no call in the published event.
  //        */
  //       if (!eventCall) return previousData;

  //       /**
  //        * Index of the existing call in the calls agent list matching the
  //        * call in the published event.
  //        */
  //       const existingCallIndex = previousData.callsConcluded.items?.findIndex(
  //         (call) => call.id === eventCall.id,
  //       );

  //       /**
  //        * Indicates whether the call exists in the existing calls agent list.
  //        */
  //       const isCallExisting = existingCallIndex > -1;

  //       /**
  //        * Indicates that the user can view the call.
  //        */
  //       const isCallUserViewable = CallUtility.isUserViewable({
  //         call: {
  //           status: eventCall.status,
  //           userId: eventCall.user?.id,
  //         },
  //         user: user!,
  //       });

  //       /**
  //        * This will hold the updated list of agent calls.
  //        */
  //       const updatedCallsAgent = [...previousData.callsConcluded.items];

  //       // Call is viewable by the user.
  //       if (
  //         isCallUserViewable &&
  //         CALL_STATUSES_CONCLUDED.includes(eventCall.status)
  //       ) {
  //         // Update the existing call since it already exists in the list.
  //         if (isCallExisting) {
  //           updatedCallsAgent[existingCallIndex] = eventCall;
  //         }
  //         // Add the new call on top of the list since it doesn't exist.
  //         else {
  //           updatedCallsAgent.unshift(eventCall);
  //         }

  //         // Also update the selected communication log if it is the same call.
  //         if (selectedCommunicationLog?.id === eventCall.id) {
  //           _setSelectedCommunicationLog(
  //             CommunicationLogUtility.fromCall({
  //               call: eventCall,
  //             }),
  //           );
  //         }
  //       }
  //       // Call is not viewable by the user.
  //       else if (isCallExisting) {
  //         // Remove the call from the list.
  //         updatedCallsAgent.splice(existingCallIndex, 1);
  //       }

  //       // Sort updatedCallsAgent from newest to oldest date.
  //       updatedCallsAgent.sort((a, b) => {
  //         const dateA = new Date(a.date);
  //         const dateB = new Date(b.date);
  //         return dateB.getTime() - dateA.getTime();
  //       });

  //       return Object.assign({} as CallsConcludedQuery, previousData, {
  //         callsConcluded: {
  //           ...previousData.callsConcluded,
  //           items: updatedCallsAgent,
  //         },
  //       });
  //     });

  //     /**
  //      * This is for updating the calls missed list after a new missed call occurs.
  //      */
  //     updateCallsMissedQuery((previousData) => {
  //       if (!eventCall) return previousData;

  //       const existingCallIndex = previousData.callsMissed.items.findIndex(
  //         (call) => call.id === eventCall.id,
  //       );

  //       const isCallExisting = existingCallIndex > -1;

  //       if (!isCallExisting) {
  //         return previousData;
  //       }

  //       const updatedMissedCalls = [...previousData.callsMissed.items];

  //       if (isCallExisting) {
  //         updatedMissedCalls[existingCallIndex] = {
  //           ...updatedMissedCalls[existingCallIndex],
  //           userId: eventCall.userId,
  //           user: eventCall.user,
  //         };
  //       }

  //       // Sort updatedMissedCalls from newest to oldest date.
  //       updatedMissedCalls.sort((a, b) => {
  //         const dateA = new Date(a.date);
  //         const dateB = new Date(b.date);
  //         return dateB.getTime() - dateA.getTime();
  //       });

  //       return Object.assign({} as CallsMissedQuery, previousData, {
  //         callsMissed: {
  //           ...previousData.callsMissed,
  //           items: updatedMissedCalls,
  //         },
  //       });
  //     });
  //   },
  //   onError(error) {
  //     console.error("Error subscribing to call event", error);
  //   },
  // });

  // /** Listens to the `callMissedGroupAddedEvent` event. */
  // useCallMissedGroupAddedEventSubscription({
  //   skip:
  //     !user?.id ||
  //     /**
  //      * Users without this permission will not be able to see missed calls tab.
  //      */
  //     !user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_ALL),
  //   variables: {
  //     input: {
  //       userId: user?.id || "",
  //     },
  //   },
  //   onData: (data) => {
  //     const missedCall = data.data.data?.callMissedGroupAddedEvent;

  //     /** Do nothing if there is no payload. */
  //     if (!missedCall) return;

  //     /** This is for updating the calls missed list after a new missed call occurs. */
  //     updateCallsMissedQuery((previousData) => {
  //       // Remove existing missed call from the list matching the customer phone number.
  //       const filteredCalls = previousData.callsMissed.items.filter(
  //         (item) => item.from !== missedCall.from,
  //       );

  //       // Add the new missed call to the list.
  //       filteredCalls.unshift(missedCall);

  //       return {
  //         callsMissed: {
  //           ...previousData.callsMissed,
  //           items: filteredCalls,
  //         },
  //       };
  //     });
  //   },
  //   onError(error) {
  //     console.error("Error subscribing to call missed added event", error);
  //   },
  // });

  // useCallMissedGroupDeletedEventSubscription({
  //   skip:
  //     !user?.id ||
  //     /**
  //      * Users without this permission will not be able to see missed calls tab.
  //      */
  //     !user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_ALL),
  //   onData: ({ data }) => {
  //     /** This removes the call that was returned from the missed calls list. */
  //     updateCallsMissedQuery((previousData) => {
  //       if (!data.data?.callMissedGroupDeletedEvent) {
  //         return previousData;
  //       }

  //       // Remove the call from the missed calll list.
  //       const filteredCalls = previousData.callsMissed?.items.filter(
  //         (item) =>
  //           item.id !== data.data?.callMissedGroupDeletedEvent?.lastCallId,
  //       );

  //       return {
  //         callsMissed: { ...previousData.callsMissed, items: filteredCalls },
  //       };
  //     });
  //   },
  //   onError(error) {
  //     console.error("Error subscribing to call missed deleted event", error);
  //   },
  // });

  // /** Listen to call summary events */
  // useCallSummaryEventSubscription({
  //   /** Skip if the user is not authorized */
  //   skip:
  //     !user ||
  //     !PermissionUtility.isAuthorized({
  //       userPermissions: user?.permissions || [],
  //       requiredPermissions: [
  //         Auth0Permission.CALL_LOGS_VIEW_ALL,
  //         Auth0Permission.CALL_LOGS_VIEW_OWN,
  //       ],
  //     }),
  //   onData: (options) => {
  //     const event = options.data.data?.callSummaryEvent;
  //     if (!event) return;

  //     if (
  //       selectedCommunicationLog &&
  //       selectedCommunicationLog?.id === event.id
  //     ) {
  //       setSelectedCommunicationLog({
  //         ...selectedCommunicationLog,
  //         summary: event?.summary || "",
  //       });
  //     }
  //   },
  // });

  /**
   * Keep the call summary of the selected communication log updated.
   */
  trpc.callRouter.onCallSummaryEvent.useSubscription({
    userId: user?.id
  }, {
    onData: eventCall => {
      /**
       * Nothing to do if there is no call in the published event.
       */
      if (!eventCall) return;

      /**
       * Concluded calls has not been fetched yet.
       */
      if (!selectedCommunicationLog) return;

      /**
       * Update the summary property of the selected communication log.
       */
      if (selectedCommunicationLog && selectedCommunicationLog?.id === eventCall.id) {
        setSelectedCommunicationLog({
          ...selectedCommunicationLog,
          summary: eventCall?.summary || ""
        });
      }
    }
  });

  // ===========================================================================
  // Communication Log Active
  // ===========================================================================

  // /**
  //  * Indicates that there are more communication logs to fetch.
  //  * The count excludes any ongoing calls.
  //  * The count for determining if there are more communication logs is based only
  //  * on the active calls (call status equal to queued, in progress, or wrapping
  //  * up that is assigned to the user).
  //  */
  // const hasMoreCommunicationLogsActive = useMemo<
  //   CommunicationLogContext["hasMoreCommunicationLogsActive"]
  // >(() => {
  //   // There are no active calls.
  //   if (
  //     !callsUserActiveData?.totalCount ||
  //     !callsUserActiveData?.items.length
  //   ) {
  //     return false;
  //   }

  //   /**
  //    * We are only counting the active calls for determining if there are
  //    * more communication logs.
  //    */
  //   return (
  //     (callsUserActiveData?.totalCount || 0) >
  //     (callsUserActiveData?.items.length || 0)
  //   );
  // }, [callsUserActiveData]);

  // ===========================================================================
  // Communication Log Concluded
  // ===========================================================================

  // /**
  //  * Indicates that there are more communication logs to fetch.
  //  * The count excludes any ongoing calls.
  //  * The count for determining if there are more communication logs is based only
  //  * on the concluded calls (call status not equal to pending, queued, in progress, or wrapping up).
  //  */
  // const hasMoreCommunicationLogsConcluded = useMemo<
  //   CommunicationLogContext["hasMoreCommunicationLogsConcluded"]
  // >(() => {
  //   // There are no concluded calls.
  //   if (!callsConcludedData?.totalCount || !callsConcludedData?.items.length) {
  //     return false;
  //   }

  //   /**
  //    * We are only counting the concluded calls for determining if there are
  //    * more communication logs.
  //    */
  //   return (
  //     (callsConcludedData?.totalCount || 0) >
  //     (callsConcludedData?.items.length || 0)
  //   );
  // }, [callsConcludedData]);

  // /**
  //  * Indicates that there are more missed calls to fetch.
  //  */
  // const hasMoreCallsMissed = useMemo<boolean>(() => {
  //   // There are no more missed calls.
  //   if (!callsMissedData?.totalCount || !callsMissedData?.items.length) {
  //     return false;
  //   }

  //   /**
  //    * Compare the total number of missed calls with the current items count to determine
  //    * if there are more calls missed that needs to be fetched.
  //    */
  //   return (
  //     (callsMissedData?.totalCount || 0) > (callsMissedData?.items.length || 0)
  //   );
  // }, [callsMissedData]);

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================

  /**
   * The communication log records in the system.
   */
  const communicationLogsActive = useMemo<CommunicationLogsGroupedByDate>(() => {
    const groupLabel = "Active";
    const _communicationLogs: CommunicationLogsGroupedByDate = [{
      label: groupLabel,
      logs: []
    }];

    // There are no calls.
    if (!callsActiveUser?.length) {
      return _communicationLogs;
    }

    /**
     * Comminication logs grouped by date.
     * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
     */
    callsActiveUser.forEach(call => {
      _communicationLogs[0].logs.push(CommunicationLogUtility.fromCall({
        call
      }));
    });
    return _communicationLogs;
  }, [callsActiveUser]);

  /**
   * The communication log records in the system.
   */
  const communicationLogsConcluded = useMemo<CommunicationLogsGroupedByDate>(() => {
    // There are no calls.
    if (!callsConcluded?.length) {
      return [{
        label: "Today",
        logs: []
      }];
    }

    /**
     * Comminication logs grouped by date.
     * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
     */
    const _communicationLog: CommunicationLogsGroupedByDate = CommunicationLogUtility.fromCallsGroupedByDate({
      calls: callsConcluded
    });
    return _communicationLog;
  }, [callsConcluded]);

  /**
   * The call missed records in the system.
   */
  const communicationLogsMissed = useMemo(() => {
    // There are no calls.
    if (!callsMissed?.length) {
      return [{
        label: "Today",
        logs: []
      }];
    }

    /**
     * Communication logs grouped by date.
     * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
     */
    const _communicationLog: CommunicationLogsGroupedByDate = CommunicationLogUtility.fromCallsGroupedByDate({
      calls: callsMissed
    });
    return _communicationLog;
  }, [callsMissed]);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // Call Actions
  // ===========================================================================

  /**
   * Accept a call.
   */
  const acceptCall: CommunicationLogContext["accept"] = useCallback(async ({
    callId
  }) => {
    // Add the call sid to the list of currently being accepted calls.
    setCallIdsAccepting([...callIdsAccepting, callId]);
    _acceptCall({
      data: {
        callId
      }
    }, {
      onSuccess: () => {
        // Remove the call sid from the list of currently being accepted calls.
        setCallIdsAccepting(callIdsAccepting.filter(sid => sid !== callId));
      }
    });
  }, [_acceptCall, callIdsAccepting]);

  /**
   * Cancel, end or reject a call.
   */
  const hangUpCall: CommunicationLogContext["hangUp"] = useCallback(async ({
    callId,
    direction,
    status
  }) => {
    // Add the call sid to the list of currently being canceled or rejected calls.
    setCallIdsHangingUp([...callIdsHangingUp, callId]);
    switch (status) {
      // Hang up an ongoing inbound/outbound call.
      case CallStatus.IN_PROGRESS:
        const twilioCall = getTwilioCall({
          callId
        });
        if (!twilioCall) {
          throw new Error("Cannot hang up call. Call not found.");
        }
        twilioCall.disconnect();
        setTimeout(() => {
          setCallIdsHangingUp(callIdsHangingUp.filter(id => id !== callId));
        }, 3000);
        break;
      case CallStatus.QUEUED:
        // Reject a ringing inbound call.
        if (direction === CallDirection.INBOUND) {
          /**
           * Reject the call. This will end the call and mark it as rejected.
           */
          _rejectCall({
            data: {
              callId
            }
          }, {
            onSettled: () => {
              // Remove the call ID from the list of currently being canceled or rejected calls.
              setCallIdsHangingUp(callIdsHangingUp.filter(id => id !== callId));
            },
            onSuccess: data => {
              setRejectedCallRoutingId(data.routings.slice(-1)[0].id);
            }
          });
          break;
        }
        // Cancel a ringing outbound call.
        else if (direction === CallDirection.OUTBOUND) {
          _cancelCall({
            data: {
              callId
            }
          }, {
            onSettled: () => {
              // Remove the call ID from the list of currently being canceled or rejected calls.
              setCallIdsHangingUp(callIdsHangingUp.filter(id => id !== callId));
            },
            onSuccess: () => {
              const twilioCall = getTwilioCall({
                callId
              });

              /**
               * Twilio call is sometimes not found when the call is cancelled
               * before even being connected.
               */
              twilioCall?.disconnect();
            }
          });
        }
        break;
      default:
        break;
    }
  }, [_cancelCall, _rejectCall, callIdsHangingUp, getTwilioCall]);

  /**
   * Check if the call is being accepted.
   */
  const isAccepting: CommunicationLogContext["isAccepting"] = useCallback(({
    callId
  }) => callIdsAccepting.includes(callId), [callIdsAccepting]);

  /**
   * Check if the call is being canceled, ended, or rejected.
   */
  const isHangingUp: CommunicationLogContext["isHangingUp"] = useCallback(({
    callId
  }) => callIdsHangingUp.includes(callId), [callIdsHangingUp]);

  // ===========================================================================
  // Communication Logs Concluded
  // ===========================================================================

  // /**
  //  * @deprecated
  //  *
  //  * Fetch more calls.
  //  */
  // const _fetchMoreCallsConcluded = useCallback(async () => {
  //   // Do nothing if the communication logs are loading.
  //   if (callsConcludedLoading) {
  //     return;
  //   }

  //   Fetch more calls when the communication logs limit changes.
  //   fetchMoreCallsConcluded({
  //     variables: {
  //       filter: communicationLogsFilter,
  //       // Use the current call size as the next offset for fetching more calls.
  //       offset:
  //         concludedCallsPaginationOffset +
  //         APPLICATION_CONFIGURATION.pagination.limit,
  //     },
  //     updateQuery(previousData, { fetchMoreResult }) {
  //       /**
  //        * A list of the calls viewable by the agent.
  //        * Fetch more results are added or updated in this list.
  //        */
  //       const updatedCallsAgent = [...previousData.callsConcluded.items];

  //       /**
  //        * IDs of the existing calls in the list. Initialize this with the
  //        * current existing call IDs.
  //        */
  //       const existingCallIds =
  //         previousData?.callsConcluded?.items.map((call) => call.id) ?? [];

  //       /**
  //        * Update the existing calls with the new calls or add the new call if
  //        * it doesn't exist in the existing calls.
  //        */
  //       fetchMoreResult.callsConcluded.items.forEach((newCall) => {
  //         /**
  //          * Index of the existing call in the calls agent list matching the
  //          * call in the fetch more result.
  //          *
  //          * @example
  //          * // A -1 value when it doesn't have a match
  //          * -1
  //          *
  //          * @example
  //          * // Greater than -1 value when it has a match
  //          * 0
  //          */
  //         const existingCallIndex = existingCallIds.indexOf(newCall.id);

  //         /**
  //          * Indicates whether the call exists in the existing calls agent list.
  //          */
  //         const existingCall = existingCallIndex > -1;

  //         /**
  //          * Indicates that the user can view the call.
  //          */
  //         const isCallUserViewable = CallUtility.isUserViewable({
  //           call: {
  //             status: newCall.status,
  //             userId: newCall.user?.id,
  //           },
  //           user: user!,
  //         });

  //         // Call is viewable by the user.
  //         if (isCallUserViewable) {
  //           // Call is existing in the call list.
  //           if (existingCall) {
  //             // Replace the existing call with the new call.
  //             updatedCallsAgent[existingCallIndex] = newCall;
  //           }
  //           // Call is not existing in the call list.
  //           else {
  //             // Add the call in the existing call list.
  //             existingCallIds.push(newCall.id);

  //             // Add the call in the updated call list.
  //             updatedCallsAgent.push(newCall);
  //           }
  //         }
  //         // Call is not viewable by the user.
  //         else {
  //           // Remove the call from the list.
  //           existingCallIds.splice(existingCallIndex, 1);

  //           // Remove the call from the list.
  //           updatedCallsAgent.splice(existingCallIndex, 1);
  //         }
  //       });

  //       if (
  //         fetchMoreResult.callsConcluded.offset <
  //         fetchMoreResult.callsConcluded.totalCount
  //       ) {
  //         /**
  //          * Keep track of the current offset for the next fetch more operation.
  //          */
  //         setConcludedCallsPaginationOffset(
  //           fetchMoreResult.callsConcluded.offset,
  //         );
  //       }

  //       return Object.assign({}, previousData, {
  //         callsConcluded: {
  //           items: updatedCallsAgent,
  //           limit: fetchMoreResult.callsConcluded.limit,
  //           offset: fetchMoreResult.callsConcluded.offset,
  //           totalCount: fetchMoreResult.callsConcluded.totalCount,
  //         },
  //       });
  //     },
  //   });
  // }, [
  //   communicationLogsFilter,
  //   fetchMoreCallsConcluded,
  //   loadingCallsConcluded,
  //   concludedCallsPaginationOffset,
  //   user,
  // ]);

  // /**
  //  * @deprecated
  //  *
  //  * Fetch more missed calls.
  //  */
  // const _fetchMoreCallsMissed = useCallback(async () => {
  //   // Do nothing if the communication logs are loading.
  //   if (callsMissedLoading) {
  //     return;
  //   }

  //   // Fetch more calls when the communication logs limit changes.
  //   fetchMoreCommunicationLogsMissed({
  //     variables: {
  //       // Use the current call size as the next offset for fetching more calls.
  //       offset:
  //         missedCallsPaginationOffset +
  //         APPLICATION_CONFIGURATION.pagination.limit,
  //     },
  //     updateQuery(previousData, { fetchMoreResult }) {
  //       /**
  //        * A list of the calls viewable by the agent.
  //        * Fetch more results are added or updated in this list.
  //        */
  //       const updatedCallsAgent = [...previousData.callsMissed.items];

  //       /**
  //        * IDs of the existing calls in the list. Initialize this with the
  //        * current existing call IDs.
  //        */
  //       const existingCallIds =
  //         previousData?.callsMissed?.items.map((call) => call.id) ?? [];

  //       /**
  //        * Update the existing calls with the new calls or add the new call if
  //        * it doesn't exist in the existing calls.
  //        */
  //       fetchMoreResult.callsMissed.items.forEach((newCall) => {
  //         /**
  //          * Index of the existing call in the calls agent list matching the
  //          * call in the fetch more result.
  //          *
  //          * @example
  //          * // A -1 value when it doesn't have a match
  //          * -1
  //          *
  //          * @example
  //          * // Greater than -1 value when it has a match
  //          * 0
  //          */
  //         const existingCallIndex = existingCallIds.indexOf(newCall.id);

  //         /**
  //          * Indicates whether the call exists in the existing calls agent list.
  //          */
  //         const existingCall = existingCallIndex > -1;

  //         /**
  //          * Indicates that the user can view the call.
  //          */
  //         const isCallUserViewable = CallUtility.isUserViewable({
  //           call: {
  //             status: newCall.status,
  //             userId: newCall.user?.id,
  //           },
  //           user: user!,
  //         });

  //         // Call is viewable by the user.
  //         if (isCallUserViewable) {
  //           // Call is existing in the call list.
  //           if (existingCall) {
  //             // Replace the existing call with the new call.
  //             updatedCallsAgent[existingCallIndex] = newCall;
  //           }
  //           // Call is not existing in the call list.
  //           else {
  //             // Add the call in the existing call list.
  //             existingCallIds.push(newCall.id);

  //             // Add the call in the updated call list.
  //             updatedCallsAgent.push(newCall);
  //           }
  //         }
  //         // Call is not viewable by the user.
  //         else {
  //           // Remove the call from the list.
  //           existingCallIds.splice(existingCallIndex, 1);

  //           // Remove the call from the list.
  //           updatedCallsAgent.splice(existingCallIndex, 1);
  //         }
  //       });

  //       if (
  //         fetchMoreResult.callsMissed.offset <
  //         fetchMoreResult.callsMissed.totalCount
  //       ) {
  //         /**
  //          * Keep track of the current offset for the next fetch more operation.
  //          */
  //         setMissedCallsPaginationOffset(fetchMoreResult.callsMissed.offset);
  //       }

  //       return Object.assign({}, previousData, {
  //         callsMissed: {
  //           items: updatedCallsAgent,
  //           limit: fetchMoreResult.callsMissed.limit,
  //           offset: fetchMoreResult.callsMissed.offset,
  //           totalCount: fetchMoreResult.callsMissed.totalCount,
  //         },
  //       });
  //     },
  //   });
  // }, [
  //   // fetchMoreCommunicationLogsMissed,
  //   callsMissedLoading,
  //   missedCallsPaginationOffset,
  //   user,
  // ]);

  /**
   * Sets the selected communication log.
   */
  const _setSelectedCommunicationLog: CommunicationLogContext["setSelectedCommunicationLog"] = useCallback(communicationLog => {
    setSelectedCommunicationLogLoading(true);

    // Initially set the communication log.
    setSelectedCommunicationLog(communicationLog);

    // Do nothing if there is no communication log.
    if (!communicationLog) {
      setSelectedCommunicationLogLoading(false);
      return;
    }
  }, []);

  // /**
  //  * Sets the selected communication log.
  //  */
  // const _setSelectedCommunicationLog: CommunicationLogContext["setSelectedCommunicationLog"] =
  //   useCallback((communicationLog) => {
  //     setSelectedCommunicationLogLoading(true);

  //     // Initially set the communication log.
  //     setSelectedCommunicationLog(communicationLog);

  //     // Do nothing if there is no communication log.
  //     if (!communicationLog) {
  //       setSelectedCommunicationLogLoading(false);
  //       return;
  //     }

  //     /**
  //      * Set the call ID to fetch the selected communication log so we will
  //      * trigger fetching the full data of the selected communication log.
  //      */
  //     setGetCallId(communicationLog.id);
  //   }, []);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the following filters when the communication log filter changes:
   * - Concluded calls filter.
   * - Missed calls filter.
   */
  useEffect(() => {
    setCallsConcludedFilter(communicationLogsFilter);
    setCallsMissedFilter(communicationLogsFilter);
  }, [communicationLogsFilter, setCallsConcludedFilter, setCallsMissedFilter]);

  /**
   * Update the selected communication log when the call list changes.
   * - Removes it if it is not included anymore on the updated list. This might
   * happen when the call is reassigned to a different agent.
  //  * - Update the status if the call is still in the list.
   */
  useEffect(() => {
    if (callsActiveFetching || callsConcludedFetching || callsMissedFetching) {
      return;
    }

    /** List of calls that were previously fetched. */
    const fetchedCalls = [...(callsActiveUser ?? []), ...(callsConcluded ?? []), ...(callsMissed ?? [])];
    const matchingCallIndex = fetchedCalls.findIndex(call => call.id === selectedCommunicationLog?.id);

    /**
     * Indicates whether the selected communication log exists in the communication logs.
     *
     * This is used to determine whether the selected communication log should be reset.
     *
     * A selected communication log might not be valid in the communication logs with status
     * queued because it might have been reassigned to a different agent.
     */
    const selectedCallExists = matchingCallIndex > -1;

    /**
     * TODO: There is a tiny window that the selected communication is removed
     * from the active calls and then later on added to the concluded calls.
     * This treats it as if the selected communication log doesn't exist anymore
     * and removes it as the selected communication log.
     *
     * Clear the selected communication log so we can remove any panels
     * that are associated with the communication log if:
     * - There are no communication logs.
     * - The selected communication log doesn't exist anymore in the list. This
     * might happen in the case of a queued call that was reassigned to a different agent.
     */
    if (!selectedCallExists) {
      _setSelectedCommunicationLog(null);
    }
  }, [selectedCommunicationLog?.id, _setSelectedCommunicationLog, callsActiveUser, callsConcluded, callsMissed, callsConcludedFetching, callsMissedFetching, callsActiveFetching]);

  /**
   * Fetch the recording of the selected communication log.
   */
  useEffect(() => {
    if (!selectedCommunicationLogLoading) {
      return;
    }

    // Prevent fetching the recording if there is no selected communication log.
    if (!selectedCommunicationLog?.id) {
      setSelectedCommunicationLogLoading(false);
      return;
    }
    refetchCall().then(result => {
      if (!result.data || !selectedCommunicationLog) {
        return;
      }

      // Update the recording of the selected communication log.
      setSelectedCommunicationLog(previousSelectedCommunicationLog => {
        if (!previousSelectedCommunicationLog) {
          return previousSelectedCommunicationLog;
        }
        return {
          ...selectedCommunicationLog,
          recording: result.data?.recording || undefined
        };
      });
    }).finally(() => {
      setSelectedCommunicationLogLoading(false);
    });
  }, [refetchCall, selectedCommunicationLog?.id, selectedCommunicationLog, selectedCommunicationLogLoading]);

  // /**
  //  * Refetch the concluded calls if the filter changed.
  //  * Reset the concluded calls pagination offset to 0.
  //  *
  //  * This would update the concluded communication logs showing on the screen.
  //  */
  // useEffect(() => {
  //   refetchCallsConcluded();

  //   // Reset the concluded calls pagination offset to the default offset configuration.
  //   setConcludedCallsPaginationOffset(
  //     APPLICATION_CONFIGURATION.pagination.offset,
  //   );
  // }, [communicationLogsFilter, refetchCallsConcluded]);

  // /**
  //  * Refetch the missed calls if the filter changed.
  //  * Reset the missed calls pagination offset to 0.
  //  *
  //  * This would update the missed communication logs showing on the screen.
  //  */
  // useEffect(() => {
  //   refetchCallsMissedQuery();

  //   // Reset the missed calls pagination offset to the default offset configuration.
  //   setMissedCallsPaginationOffset(APPLICATION_CONFIGURATION.pagination.offset);
  // }, [communicationLogsFilter, refetchCallsMissedQuery]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <CommunicationLogContext.Provider value={{
    // =====================================================================
    // Call Actions
    // =====================================================================
    accept: acceptCall,
    hangUp: hangUpCall,
    hideCallRejectedModal: () => {
      setRejectedCallRoutingId(null);
    },
    isAccepting,
    isHangingUp,
    rejectedCallRoutingId,
    showCallRejectModal: !!rejectedCallRoutingId,
    // =====================================================================
    // Communication Logs Filter
    // =====================================================================
    setCommunicationLogsFilter,
    // =====================================================================
    // Communication Logs Active
    // =====================================================================
    communicationLogsActive,
    communicationLogsActiveFetchingMore: callsActiveLoadingUser,
    communicationLogsActiveLoading: callsActiveLoadingUser,
    fetchMoreCommunicationLogsActive: fetchMoreCallsActiveUser,
    hasActiveCommunicationLog: !!communicationLogsActive[0].logs.length,
    hasMoreCommunicationLogsActive: hasMoreCallsActiveUser,
    // hasMoreCommunicationLogsActive,
    // =====================================================================
    // Communication Logs Concluded
    // =====================================================================
    communicationLogsConcluded,
    communicationLogsConcludedFetchingMore: callsConcludedLoading,
    communicationLogsConcludedLoading: callsConcludedLoading,
    fetchMoreCommunicationLogsConcluded: fetchMoreCallsConcluded,
    hasMoreCommunicationLogsConcluded: hasMoreCallsConcluded,
    // =====================================================================
    // Calls Missed Group
    // =====================================================================
    communicationLogsMissed,
    communicationLogsMissedFetchingMore: callsMissedLoading,
    communicationLogsMissedLoading: callsMissedLoading,
    fetchMoreCommunicationLogsMissed: fetchMoreCallsMissed,
    hasMoreCommunicationLogsMissed: hasMoreCallsMissed,
    // =====================================================================
    // Selected Communication Log
    // =====================================================================
    selectedCommunicationLog,
    selectedCommunicationLogLoading,
    setSelectedCommunicationLog: _setSelectedCommunicationLog
  }} data-sentry-element="unknown" data-sentry-component="CommunicationLogContextProvider" data-sentry-source-file="communication-log-context.tsx">
      {children}
    </CommunicationLogContext.Provider>;
};