"use client";

/**
 * External dependencies.
 */
import { useEffect, useState } from "react";

/**
 * Internal dependencies.
 */
import { APPLICATION_CONFIGURATION } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import {
  trpc,
  TrpcRouterInputs,
  UseTrpcQueryCallbacks,
} from "@/components/client/trpc";
import { ActivityLogType } from "@/components/common/activity-log/enumerations";
import type {
  ActivityLog,
  ActivityLogCall,
  ActivityLogTask,
} from "@/components/common/activity-log/types";
import { ActivityLogUtility } from "@/components/common/activity-log/utilities";
import { CALL_STATUSES_CONCLUDED } from "@/components/server/call/services/enumerations";
import { CallUtility } from "@/components/server/call/utilities";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";

/**
 * The arguments for the use activity logs hook.
 */
type UseActivityLogsArgs = UseTrpcQueryCallbacks<
  UseActivityLogsReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The filter for fetching activity logs.
 */
export type UseActivityLogsFilter = Extract<
  TrpcRouterInputs["activityLogRouter"]["getActivityLogs"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use activity logs hook.
 */
type UseActivityLogsReturn = {
  /**
   * The paginated data for activity logs.
   */
  data: ActivityLog[];
  /**
   * Indicates if the activity logs are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Fetch more activity logs if there are more data to fetch.
   */
  fetchMore: () => Promise<void>;
  /**
   * Indicates if there are more activity logs to fetch.
   */
  hasMore: boolean;
  /**
   * Indicates if the activity logs are loading for the first time.
   */
  loading: boolean;
  /**
   * Set the filter for fetching activity logs.
   */
  setFilter: React.Dispatch<
    React.SetStateAction<UseActivityLogsFilter | undefined>
  >;
};

/**
 * The use activity logs hook type.
 */
type UseActivityLogs = (args?: UseActivityLogsArgs) => UseActivityLogsReturn;

/**
 * Hook to fetch, filter, or refetch activity logs.
 */
export const useActivityLogs: UseActivityLogs = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for activity logs.
   */
  const [activityLogs, setActivityLogs] = useState<
    UseActivityLogsReturn["data"]
  >([]);

  /**
   * The filter for fetching activity logs.
   */
  const [activityLogsFilter, setActivityLogsFilter] =
    useState<UseActivityLogsFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: activityLogsData,
    hasNextPage: hasMoreActivityLogs,
    isFetching: activityLogsFetching,
    isLoading: activityLogsLoading,
    refetch: refetchActivityLogs,
    fetchNextPage: fetchNextPageActivityLogs,
  } = trpc.activityLogRouter.getActivityLogs.useInfiniteQuery(
    {
      filter: activityLogsFilter,
      limit: APPLICATION_CONFIGURATION.pagination.limit,
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage[lastPage.length - 1]?.id || undefined,
    },
  );

  /**
   * Subscribe to call events.
   * - Add new concluded call to the list if it is viewable by the user.
   * - Update existing concluded call when a call event is published if it is viewable by the user.
   * - Remove concluded call that is not viewable by the user.
   */
  const { status: onCallEventStatus } =
    trpc.callRouter.onCallEvent.useSubscription(
      { userId: user?.id },
      {
        onData: (eventCall) => {
          /**
           * Nothing to do if there is no call in the published event.
           */
          if (!eventCall) return;

          /**
           * Concluded calls has not been fetched yet.
           */
          if (!activityLogs) return;

          /**
           * Index of the existing call in the calls agent list matching the
           * call in the published event.
           */
          const existingCallIndex = activityLogs.findIndex(
            (activityLog) =>
              activityLog.id === eventCall.id &&
              activityLog.type === ActivityLogType.CALL,
          );

          /**
           * Indicates whether the call exists in the existing calls agent list.
           */
          const isCallExisting = existingCallIndex > -1;

          /**
           * Indicates that the user can view the call.
           */
          const isCallUserViewable = CallUtility.isUserViewable({
            call: {
              status: eventCall.status,
              userId: eventCall.userId,
            },
            userId: user?.id!,
          });

          /**
           * This will hold the updated list of activity logs.
           */
          const updatedActivityLogs = [...activityLogs];

          // Call is viewable by the user.
          if (
            isCallUserViewable &&
            CALL_STATUSES_CONCLUDED.includes(eventCall.status)
          ) {
            // /**
            //  * Call the on event callback if it is provided.
            //  */
            // args?.onEvent?.({
            //   data: activityLogs,
            //   eventData: eventCall,
            // });

            /**
             * The activity log created from the call event.
             */
            const eventActivityLog: ActivityLogCall =
              ActivityLogUtility.fromCall({
                call: { ...eventCall, user: eventCall.user || undefined },
              });

            // Update the existing call since it already exists in the list.
            if (isCallExisting) {
              updatedActivityLogs[existingCallIndex] = eventActivityLog;
            }
            // Add the new call to the list since it does not exist in the list.
            else {
              updatedActivityLogs.unshift(eventActivityLog);
            }
          }

          // Sort the activity logs from newest to oldest date.
          updatedActivityLogs.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
          });

          setActivityLogs(updatedActivityLogs);
        },
      },
    );

  /**
   * Listen to call summary event.
   *
   * Update the summary of the concluded call in the activity log.
   */
  trpc.callRouter.onCallSummaryEvent.useSubscription(
    { userId: user?.id },
    {
      onData: (eventCallSummary) => {
        if (!eventCallSummary) return;

        if (!activityLogs) return;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the published event.
         */
        const existingCallIndex = activityLogs.findIndex(
          (call) => call.id === eventCallSummary.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * This will hold the updated list of activity logs.
         */
        const updatedActivityLogs = [...activityLogs];

        // Update the existing call since it already exists in the list.
        if (isCallExisting) {
          updatedActivityLogs[existingCallIndex] = {
            ...updatedActivityLogs[existingCallIndex],
            /**
             * Update the activity log summary of the call.
             */
            description: eventCallSummary.summary || undefined,
          };
        }

        // Sort the activity logs from newest to oldest date.
        updatedActivityLogs.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        setActivityLogs(updatedActivityLogs);
      },
    },
  );
  /**
   * Listen to call events.
   *
   * Add the user viewable concluded calls to the activity log.
   */
  trpc.taskRouter.onTaskCreate.useSubscription(
    { userId: user?.id },
    {
      onData: (eventTask) => {
        if (!eventTask) return;

        if (!activityLogs) return;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the published event.
         */
        const existingCallIndex = activityLogs.findIndex(
          (activityLog) =>
            activityLog.id === eventTask.id &&
            activityLog.type === ActivityLogType.TASK,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isTaskExisting = existingCallIndex > -1;

        /**
         * This will hold the updated list of activity logs.
         */
        const updatedActivityLogs = [...activityLogs];

        /**
         * The activity log created from the call event.
         */
        const eventActivityLog: ActivityLogTask = ActivityLogUtility.fromTask({
          task: eventTask,
        });

        // Update the existing call since it already exists in the list.
        if (isTaskExisting) {
          updatedActivityLogs[existingCallIndex] = eventActivityLog;
        }
        // Add the new call to the list since it does not exist in the list.
        else {
          updatedActivityLogs.unshift(eventActivityLog);
        }

        // Sort the activity logs from newest to oldest date.
        updatedActivityLogs.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        setActivityLogs(updatedActivityLogs);
      },
    },
  );

  /**
   * Deletes the task when the delete event is received.
   */
  trpc.taskRouter.onTaskDelete.useSubscription(
    { userId: user?.id },
    {
      onData: (eventTask) => {
        /**
         * Nothing to do if there is no task in the published event.
         */
        if (!eventTask) return;

        /**
         * Concluded calls has not been fetched yet.
         */
        if (!activityLogs) return;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the published event.
         */
        const existingCallIndex = activityLogs.findIndex(
          (activityLog) =>
            activityLog.id === eventTask.id &&
            activityLog.type === ActivityLogType.TASK,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isTaskExisting = existingCallIndex > -1;

        if (!isTaskExisting) {
          return;
        }

        /**
         * This will hold the updated list of activity logs.
         */
        const updatedActivityLogs = [...activityLogs];

        updatedActivityLogs.splice(existingCallIndex, 1);

        // Sort the activity logs from newest to oldest date.
        updatedActivityLogs.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        setActivityLogs(updatedActivityLogs);
      },
    },
  );

  /**
   * Update the tasks when the data changes.
   */
  trpc.taskRouter.onTaskUpdate.useSubscription(
    { userId: user?.id },
    {
      onData: (eventTask) => {
        /**
         * Nothing to do if there is no task in the published event.
         */
        if (!eventTask) return;

        /**
         * Concluded calls has not been fetched yet.
         */
        if (!activityLogs) return;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the published event.
         */
        const existingCallIndex = activityLogs.findIndex(
          (activityLog) =>
            activityLog.id === eventTask.id &&
            activityLog.type === ActivityLogType.TASK,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isTaskExisting = existingCallIndex > -1;

        /**
         * This will hold the updated list of activity logs.
         */
        const updatedActivityLogs = [...activityLogs];

        /**
         * The activity log created from the call event.
         */
        const eventActivityLog: ActivityLogTask = ActivityLogUtility.fromTask({
          task: eventTask,
        });

        // Update the existing call since it already exists in the list.
        if (isTaskExisting) {
          updatedActivityLogs[existingCallIndex] = eventActivityLog;
        }
        // Add the new call to the list since it does not exist in the list.
        else {
          updatedActivityLogs.unshift(eventActivityLog);
        }

        // Sort the activity logs from newest to oldest date.
        updatedActivityLogs.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        setActivityLogs(updatedActivityLogs);
      },
    },
  );

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  async function fetchMoreCallsConcluded() {
    // Concluded calls are still being fetched or there are no more activity logs to fetch.
    if (activityLogsFetching || activityLogsLoading || !hasMoreActivityLogs) {
      return;
    }

    fetchNextPageActivityLogs().then((response) => {
      // Only add the new last page to the existing data.
      setActivityLogs((previousActivityLogs) => [
        ...previousActivityLogs,
        ...(response.data?.pages[response.data.pages.length - 1] || []),
      ]);
    });
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the activity logs when the data changes.
   */
  useEffect(() => {
    // Doing a fetch more, handle that manually on the fetch more logic.
    if (activityLogs.length) {
      return;
    }

    /**
     * Only set the activity logs on first fetch to avoid overwriting
     * subscription event added data.
     */
    setActivityLogs(activityLogsData?.pages.flat() || []);
  }, [activityLogs.length, activityLogsData]);

  /**
   * Refetch the activity logs if the filter changed.
   * Reset the activity logs pagination offset to 0.
   *
   * This would update the concluded communication logs showing on the screen.
   */
  useEffect(() => {
    /**
     * Clear the activity logs since the filter changed.
     * This would help track if we are doing a refetch or a fetch more.
     */
    setActivityLogs([]);
    refetchActivityLogs();
  }, [activityLogsFilter, refetchActivityLogs]);

  /**
   * Trigger the on status change callback when the on call event status changes.
   */
  useEffect(() => {
    args?.onEventListenerStatusChange?.({ status: onCallEventStatus });
  }, [args, onCallEventStatus]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: activityLogs,
    fetching: activityLogsFetching,
    fetchMore: fetchMoreCallsConcluded,
    hasMore: hasMoreActivityLogs,
    loading: activityLogsLoading,
    setFilter: setActivityLogsFilter,
  };
};
