"use client";

import { CallDirection, CallStatus } from "@prisma/client";
import { useEffect, useState } from "react";

import { APPLICATION_CONFIGURATION } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import { trpc, UseTrpcQueryCallbacks } from "@/components/client/trpc";
import { useTwilioContext } from "@/components/client/twilio";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { CallWithOptionalRelations } from "@/components/common/call/types";
import { TwilioConferenceUtility } from "@/components/common/twilio";
import { CALL_STATUSES_ACTIVE } from "@/components/server/call/services/enumerations";
import { CallUtility } from "@/components/server/call/utilities";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";

/**
 * The arguments for the use calls active user hook.
 */
type UseCallsActiveUserArgs = UseTrpcQueryCallbacks<
  UseCallsActiveUserReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The return type for the use calls active hook.
 */
type UseCallsActiveUserReturn = {
  /**
   * The paginated data for active calls.
   */
  data: CallWithOptionalRelations[];
  /**
   * Indicates if the active calls are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Fetch more active calls if there are more data to fetch.
   */
  fetchMore: () => Promise<void>;
  /**
   * Indicates if there are more active calls to fetch.
   */
  hasMore: boolean;
  /**
   * Indicates if the active calls are loading.
   */
  loading: boolean;
};

/**
 * The use calls active hook type.
 */
type UseCallsActiveUser = (
  args?: UseCallsActiveUserArgs,
) => UseCallsActiveUserReturn;

/**
 * Hook to fetch, filter, or refetch active calls viewable by the user.
 */
export const useCallsActiveUser: UseCallsActiveUser = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  const { connectToConference } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for active calls.
   */
  const [callsActiveUser, setCallsActiveUser] = useState<
    UseCallsActiveUserReturn["data"]
  >([]);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: callsActiveUserData,
    hasNextPage: hasMoreCallsActiveUser,
    isLoading: callsActiveUserLoading,
    isFetching: callsActiveUserFetching,
    fetchNextPage: fetchNextPageCallsActiveUser,
  } = trpc.callRouter.getActiveUserCalls.useInfiniteQuery(
    {
      /**
       * As of now make sure that we are displaying all active calls of a user.
       * This is to make sure that all ongoing calls that have an active call
       * are displayed.
       */
      limit: APPLICATION_CONFIGURATION.pagination.limit,
    },
    {
      enabled:
        !!user?.id &&
        // Only fetch when the user has either view all or view own call logs permission.
        (user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_ALL) ||
          user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_OWN)),
      getNextPageParam: (lastPage) =>
        lastPage[lastPage.length - 1]?.id || undefined,
    },
  );

  /**
   * Subscribe to call events.
   * - Active Calls
   *  - Adds the active calls to the list of active calls.
   *  - Keeps the active calls list updated.
   *  - Connects the user to the conference call upon answering.
   */
  const { status: onCallEventStatus } =
    trpc.callRouter.onCallEvent.useSubscription(
      { userId: user?.id },
      {
        onData: (eventCall) => {
          /**
           * Nothing to do if there is no call in the published event.
           */
          if (!eventCall) return;

          /**
           * Active calls has not been fetched yet.
           */
          if (!callsActiveUser) return;

          /**
           * Update the list of active calls in the system.
           */
          const existingCallIndex = callsActiveUser.findIndex(
            (call) => call.id === eventCall.id,
          );

          /**
           * Indicates whether the call exists in the existing calls agent list.
           */
          const isCallExisting = existingCallIndex > -1;

          /**
           * Indicates that the user can view the call.
           */
          const isCallUserViewable = CallUtility.isUserViewable({
            call: {
              status: eventCall.status,
              userId: eventCall.userId,
            },
            userId: user?.id!,
          });

          /**
           * This will hold the updated list of active calls.
           */
          let updatedActiveCalls = [...callsActiveUser];

          // Call is active viewable by the user.
          if (
            isCallUserViewable &&
            CALL_STATUSES_ACTIVE.includes(eventCall.status)
          ) {
            /**
             * Call was accepted/created by the user, connect the user to the
             * conference.
             */
            if (
              eventCall.userId === user?.id &&
              // Call is inbound and is already accepted by the user.
              ((eventCall.status === CallStatus.IN_PROGRESS &&
                eventCall.direction === CallDirection.INBOUND) ||
                // Call is outbound and is already answered.
                (eventCall.status === CallStatus.IN_PROGRESS &&
                  eventCall.direction === CallDirection.OUTBOUND))
            ) {
              /**
               * Arguments for getting the customer phone number from a call.
               */
              connectToTwilioConference({
                call: eventCall,
              });
            }

            /**
             * Trigger the on event callback if the call status is active
             * (IN_PROGRESS, QUEUED, WRAPPING_UP).
             */
            args?.onEvent?.({
              data: callsActiveUser,
              eventData: eventCall,
            });

            // Update the existing call since it already exists in the list.
            if (isCallExisting) {
              updatedActiveCalls[existingCallIndex] = eventCall;
            }

            // Add the new call on top of the list since it doesn't exist.
            else {
              updatedActiveCalls.unshift(eventCall);
            }
          }
          // Call is not viewable by the user.
          else if (isCallExisting) {
            // Remove the call from the list.
            updatedActiveCalls.splice(existingCallIndex, 1);

            // // Close the notification if it is removed.
            // notification.close({
            //   tag: notificationTag,
            // });
          }

          // Sort the active calls from newest to oldest date.
          updatedActiveCalls.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
          });

          setCallsActiveUser(updatedActiveCalls);
        },
      },
    );

  trpc.callRouter.onCallSummaryEvent.useSubscription(
    { userId: user?.id },
    {
      onData: (eventCall) => {
        if (!eventCall) return;

        if (!callsActiveUser) return;

        /**
         * Index of the existing call in the active calls of the user matching the
         * call in the published event.
         */
        const existingCallIndex = callsActiveUser.findIndex(
          (call) => call.id === eventCall.id,
        );

        /**
         * Indicates whether the call exists in the existing active calls of the
         * user.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * This will hold the updated list of concluded calls.
         */
        const updatedCallsActiveUser = [...callsActiveUser];

        // Call is viewable by the user.
        if (!CALL_STATUSES_ACTIVE.includes(eventCall.status)) {
          return;
        }

        // Update the existing call since it already exists in the list.
        if (isCallExisting) {
          updatedCallsActiveUser[existingCallIndex] = {
            ...updatedCallsActiveUser[existingCallIndex],
            summary: eventCall.summary,
          };

          // Sort the concluded calls from newest to oldest date.
          updatedCallsActiveUser.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
          });

          setCallsActiveUser(updatedCallsActiveUser);
        }
      },
    },
  );

  /**
   * Subscribe to call recording events.
   * - Update the active call with the recording if the recording event is
   * for the active call.
   */
  trpc.callRecordingRouter.onCallRecordingEvent.useSubscription(
    {
      userId: user?.id,
    },
    {
      onData: (eventCallRecording) => {
        if (!eventCallRecording) return;

        if (!callsActiveUser) return;

        const existingCallIndex = callsActiveUser.findIndex(
          (call) => call.id === eventCallRecording.callId,
        );

        const isCallExisting = existingCallIndex > -1;

        if (!isCallExisting) return;

        const updatedActiveCalls = [...callsActiveUser];

        updatedActiveCalls[existingCallIndex] = {
          ...updatedActiveCalls[existingCallIndex],
          recording: eventCallRecording,
        };

        setCallsActiveUser(updatedActiveCalls);
      },
    },
  );
  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  type ConnectToTwilioConferenceCall = Pick<
    EventEmitterEvent[EventEmitterEventName.CALL][0],
    "direction" | "id" | "from" | "to"
  >;

  /**
   * Connect a call to Twilio.
   */
  type ConnectToTwilioConferenceArgs = {
    /**
     * The call record to connect to Twilio.
     */
    call: ConnectToTwilioConferenceCall;
  };

  /**
   * Connect the user to Twilio conference.
   */
  function connectToTwilioConference({ call }: ConnectToTwilioConferenceArgs) {
    type GetCustomerPhoneNumberArgs = {
      /**
       * The call record.
       */
      call: ConnectToTwilioConferenceCall;
    };

    /**
     * Get the customer phone number from the call.
     *
     * @returns The customer phone number.
     */
    const getCustomerPhoneNumber = ({ call }: GetCustomerPhoneNumberArgs) => {
      switch (call.direction) {
        case CallDirection.INBOUND:
          return call.from;
        case CallDirection.OUTBOUND:
          return call.to;
        default:
          throw new Error("Invalid call direction.");
      }
    };

    /**
     * https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceconnectconnectoptions
     */
    connectToConference({
      callId: call.id,
      conferenceFriendlyName: TwilioConferenceUtility.getFriendlyName({
        customerPhoneNumber: getCustomerPhoneNumber({
          call: call,
        }),
      }),
    });
  }

  async function fetchMoreCallsActive() {
    // Active calls are still being fetched or there are no more active calls to fetch.
    if (
      callsActiveUserFetching ||
      callsActiveUserLoading ||
      !hasMoreCallsActiveUser
    ) {
      return;
    }

    fetchNextPageCallsActiveUser().then((response) => {
      // only add the new last page to the existing data.
      setCallsActiveUser((previousCallsActiveUser) => [
        ...previousCallsActiveUser,
        ...(response.data?.pages[response.data.pages.length - 1] || []),
      ]);
    });
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the active calls when the data changes.
   */
  useEffect(() => {
    // Doing a fetch more, handle that manually on the fetch more logic.
    if (callsActiveUser.length) {
      return;
    }

    /**
     * Only set the calls concluded on first fetch to avoid overwriting
     * subscription event added data.
     */
    setCallsActiveUser(callsActiveUserData?.pages.flat() || []);
  }, [callsActiveUser.length, callsActiveUserData]);

  /**
   * Trigger the on status change callback when the on call event status changes.
   */
  useEffect(() => {
    args?.onEventListenerStatusChange?.({ status: onCallEventStatus });
  }, [args, onCallEventStatus]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: callsActiveUser,
    fetching: callsActiveUserFetching,
    fetchMore: fetchMoreCallsActive,
    hasMore: hasMoreCallsActiveUser,
    loading: callsActiveUserLoading,
  };
};
