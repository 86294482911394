"use client";

/**
 * External dependencies.
 */
import { useEffect, useState } from "react";

/**
 * Internal dependencies.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { trpc } from "@/components/client/trpc";
import { UseTrpcQueryCallbacks } from "@/components/client/trpc/types";
import { CallWithOptionalRelations } from "@/components/common/call/types";
import { CALL_STATUSES_ONGOING_WITH_PENDING } from "@/components/server/call/services/enumerations";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event";

/**
 * The arguments for the use calls concluded hook.
 */
type UseCallsActiveArgs = UseTrpcQueryCallbacks<
  UseCallsActiveReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The return type for the use calls active hook.
 */
type UseCallsActiveReturn = {
  /**
   * The paginated data for active calls.
   */
  data: CallWithOptionalRelations[];
  /**
   * Indicates if the calls are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Indicates if the calls are loading for the first time.
   */
  loading: boolean;
};

/**
 * The use calls active hook type.
 */
type UseCallsActive = (args?: UseCallsActiveArgs) => UseCallsActiveReturn;

/**
 * Hook to fetch, filter, or refetch all active calls
 */
export const useCallsActive: UseCallsActive = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for active calls.
   */
  const [callsActive, setCallsActive] = useState<UseCallsActiveReturn["data"]>(
    [],
  );

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: callsActiveData,
    isLoading: callsActiveLoading,
    isFetching: callsActiveFetching,
  } = trpc.callRouter.getActiveCalls.useQuery(undefined, {
    enabled: !!user?.id,
  });

  /**
   * Subscribe to call events.
   * - Add new active call to the list if it is viewable by the user.
   * - Update existing active call when a call event is published if it is viewable by the user.
   * - Remove active call that is not viewable by the user.
   */
  trpc.callRouter.onCallEvent.useSubscription(
    { userId: user?.id },
    {
      onData: (eventCall) => {
        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!eventCall) return;

        /**
         * Active calls has not been fetched yet.
         */
        if (!callsActive) return;

        /**
         * Update the list of active calls in the system.
         */
        const existingCallIndex = callsActive.findIndex(
          (call) => call.id === eventCall.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;
        /**
         * This will hold the updated list of active calls.
         */
        let updatedActiveCalls = [...callsActive];

        /**
         * Kepp track of all the active calls that are in progress regardless of
         * the user assignment. This can be used to check whether a customer
         * has an active call with one of the system user.
         */
        if (CALL_STATUSES_ONGOING_WITH_PENDING.includes(eventCall.status)) {
          /**
           * Trigger the on event callback if the call status is active (PENDING, IN_PROGRESS,
           * QUEUED, WRAPPING_UP).
           */
          args?.onEvent?.({
            data: updatedActiveCalls,
            eventData: eventCall,
          });

          // Update the existing call in the active calls list.
          if (isCallExisting) {
            updatedActiveCalls[existingCallIndex] = eventCall;
          }
          // Add the call to the active calls list.
          else {
            updatedActiveCalls.unshift(eventCall);
          }
        }
        // The call is not in progress. Remove it from the active calls list and call is in the active calls list.
        else if (isCallExisting) {
          // Remove the call from the list.
          updatedActiveCalls.splice(existingCallIndex, 1);
        }

        // Sort the active calls from newest to oldest date.
        updatedActiveCalls.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        setCallsActive(updatedActiveCalls);
      },
    },
  );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the active calls when the data changes.
   */
  useEffect(() => {
    setCallsActive(callsActiveData || []);
  }, [callsActiveData]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: callsActive,
    fetching: callsActiveFetching,
    loading: callsActiveLoading,
  };
};
