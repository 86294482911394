"use client";

import { useEffect, useState } from "react";

import { APPLICATION_CONFIGURATION } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import { trpc, TrpcRouterInputs } from "@/components/client/trpc";
import { CallWithOptionalRelations } from "@/components/common/call/types";
import { CALL_STATUSES_CONCLUDED } from "@/components/server/call/services/enumerations";
import { CallUtility } from "@/components/server/call/utilities";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";

/**
 * The arguments for the use calls hook.
 */
type UseCallsArgs = {
  /**
   * Indicates if the hook is enabled.
   */
  enabled?: boolean;
  /**
   * The filter for fetching calls.
   */
  filter?: UseCallsFilter;
  /**
   * On event callback. You can use this to listen for any events outside of this
   * hook.
   */
  onEvent?: (args: {
    /**
     * Current data for active calls.
     */
    data: UseCallsReturn["data"];
    /**
     * The data from the call event.
     */
    eventData: EventEmitterEvent[EventEmitterEventName.CALL][0];
  }) => void;
};

/**
 * The filter for fetching calls.
 */
export type UseCallsFilter = Extract<
  TrpcRouterInputs["callRouter"]["getCalls"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use calls hook.
 */
type UseCallsReturn = {
  /**
   * The paginated data for calls.
   */
  data: CallWithOptionalRelations[];
  /**
   * Indicates if the calls are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Fetch more calls if there are more data to fetch.
   */
  fetchMore: () => Promise<void>;
  /**
   * Indicates if there are more calls to fetch.
   */
  hasMore: boolean;
  /**
   * Indicates if the calls are loading for the first time.
   */
  loading: boolean;
  /**
   * Set the filter for fetching calls.
   */
  setFilter: React.Dispatch<React.SetStateAction<UseCallsFilter | undefined>>;
};

/**
 * The use calls hook type.
 */
type UseCalls = (args?: UseCallsArgs) => UseCallsReturn;

/**
 * Hook to fetch, filter, or refetch calls.
 */
export const useCalls: UseCalls = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for calls.
   */
  const [calls, setCalls] = useState<UseCallsReturn["data"]>([]);

  /**
   * The filter for fetching calls.
   */
  const [callsFilter, setCallsFilter] = useState<UseCallsFilter>(args?.filter);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: callsData,
    hasNextPage: hasMoreCalls,
    isLoading: callsLoading,
    isFetching: callsFetching,
    refetch: refetchCalls,
    fetchNextPage: fetchNextPageCalls,
  } = trpc.callRouter.getCalls.useInfiniteQuery(
    {
      filter: callsFilter,
      limit: APPLICATION_CONFIGURATION.pagination.limit,
    },
    {
      enabled: args?.enabled,
      getNextPageParam: (lastPage) =>
        lastPage[lastPage.length - 1]?.id || undefined,
    },
  );

  /**
   * Subscribe to call events.
   */
  trpc.callRouter.onCallEvent.useSubscription(
    { userId: user?.id },
    {
      onData: (eventCall) => {
        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!eventCall) return;

        /**
         * Concluded calls has not been fetched yet.
         */
        if (!calls) return;

        /**
         * TODO: Update the call list.
         */

        // /**
        //  * Call the on event callback if it is provided.
        //  */
        // args?.onEvent({
        //   data: calls,
        //   eventData: eventCall,
        // });

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the published event.
         */
        const existingCallIndex = calls.findIndex(
          (call) => call.id === eventCall.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * Indicates that the user can view the call.
         */
        const isCallUserViewable = CallUtility.isUserViewable({
          call: {
            status: eventCall.status,
            userId: eventCall.userId,
          },
          userId: user?.id!,
        });

        /**
         * This will hold the updated list of calls.
         */
        const updatedCalls = [...calls];

        // Call is viewable by the user.
        if (
          isCallUserViewable &&
          CALL_STATUSES_CONCLUDED.includes(eventCall.status)
        ) {
          // Update the existing call since it already exists in the list.
          if (isCallExisting) {
            updatedCalls[existingCallIndex] = eventCall;
          }
          // Add the new call on top of the list since it doesn't exist.
          else {
            updatedCalls.unshift(eventCall);
          }
        }
        // Call is not viewable by the user.
        else if (isCallExisting) {
          // Remove the call from the list.
          updatedCalls.splice(existingCallIndex, 1);
        }

        // Sort the calls from newest to oldest date.
        updatedCalls.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        setCalls(updatedCalls);
      },
    },
  );

  /**
   * Subscribe to call summary events.
   * - Update the call summary of the existing call.
   */
  trpc.callRouter.onCallSummaryEvent.useSubscription(
    {
      userId: user?.id,
    },
    {
      onData: (eventCall) => {
        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!eventCall) return;

        /**
         * Concluded calls has not been fetched yet.
         */
        if (!calls) return;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the published event.
         */
        const existingCallIndex = calls.findIndex(
          (call) => call.id === eventCall.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * This will hold the updated list of calls.
         */
        const updatedCalls = [...calls];

        // Update the call summary of the existing call since it already exists in the list
        if (isCallExisting) {
          updatedCalls[existingCallIndex] = {
            ...updatedCalls[existingCallIndex],
            summary: eventCall.summary,
          };
        }

        // Sort the calls from newest to oldest date.
        updatedCalls.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        setCalls(updatedCalls);
      },
    },
  );

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  async function fetchMoreCalls() {
    // Concluded calls are still being fetched or there are no more calls to fetch.
    if (callsFetching || callsLoading || !hasMoreCalls) {
      return;
    }

    fetchNextPageCalls().then((response) => {
      // Only add the new last page to the existing data.
      setCalls((previousCalls) => [
        ...previousCalls,
        ...(response.data?.pages[response.data.pages.length - 1] || []),
      ]);
    });
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the calls when the data changes.
   */
  useEffect(() => {
    // Doing a fetch more, handle that manually on the fetch more logic.
    if (calls.length) {
      return;
    }

    /**
     * Only set the calls concluded on first fetch to avoid overwriting
     * subscription event added data.
     */
    setCalls(callsData?.pages.flat() || []);
  }, [calls.length, callsData]);

  /**
   * Refetch the calls if the filter changed.
   */
  useEffect(() => {
    /**
     * Clear the calls since the filter changed.
     * This would help track if we are doing a refetch or a fetch more.
     */
    setCalls([]);
    refetchCalls();
  }, [callsFilter, refetchCalls]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: calls,
    fetching: callsFetching,
    fetchMore: fetchMoreCalls,
    hasMore: hasMoreCalls,
    loading: callsLoading,
    setFilter: setCallsFilter,
  };
};
