"use client";

/**
 * External dependencies.
 */
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createWSClient, httpBatchLink, loggerLink, splitLink, wsLink } from "@trpc/client";
import { PropsWithChildren, useMemo, useState } from "react";
import superjson from "superjson";

/**
 * Internal dependencies.
 */
import { trpc } from "@/components/client/trpc";
import { TrpcConfiguration } from "@/configurations/trpc";

/**
 * @see https://github.com/trpc/examples-next-sse-chat/blob/main/src/app/providers.tsx
 */
let clientQueryClientSingleton: QueryClient | undefined = undefined;
const createQueryClient = () => new QueryClient({
  defaultOptions: {
    queries: {
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: 30 * 1000
    }
  }
});
const getQueryClient = () => {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return createQueryClient();
  } else {
    // Browser: use singleton pattern to keep the same query client
    return clientQueryClientSingleton ??= createQueryClient();
  }
};
const getUrl = () => {
  const base = (() => {
    if (typeof window !== "undefined") return window.location.origin;
    // if (process.env.APP_URL) return process.env.APP_URL;
    return `http://localhost:${TrpcConfiguration.http.port ?? 3000}`;
  })();
  return `${base}/api/trpc`;
};

/**
 * TRPC provider.
 */
export function TrpcProvider({
  children
}: PropsWithChildren) {
  const webSocketClient = useMemo(() => {
    let webSocketUrl = `ws://localhost:${TrpcConfiguration.webSocket.port ?? 3000}`;
    if (process.env.NODE_ENV === "production") {
      const base = (() => {
        if (typeof window !== "undefined") return window.location.origin;
        return `http://localhost:${TrpcConfiguration.webSocket.port ?? 3000}`;
      })();
      const {
        host,
        port,
        protocol
      } = new URL(base);
      const webSocketProtocol = protocol === "https:" ? "wss" : "ws";
      webSocketUrl = `${webSocketProtocol}://${host}:${port}`;
    }
    return createWSClient({
      url: webSocketUrl
    });
  }, []);

  /**
   * Query client.
   */
  const queryClient = getQueryClient();

  /**
   * The TRPC client.
   */
  const [trpcClient] = useState(() => trpc.createClient({
    links: [splitLink({
      condition: operation => operation.type === "subscription",
      // true: unstable_httpSubscriptionLink({
      //   EventSource: EventSourcePolyfill,
      //   /**
      //    * @see https://trpc.io/docs/v11/data-transformers
      //    */
      //   transformer: superjson,
      //   url: getUrl(),
      // }),
      // false: unstable_httpBatchStreamLink({
      //   /**
      //    * @see https://trpc.io/docs/v11/data-transformers
      //    */
      //   transformer: superjson,
      //   url: getUrl(),
      // }),
      true: wsLink({
        /**
         * @see https://trpc.io/docs/v11/data-transformers
         */
        transformer: superjson,
        client: webSocketClient
      }),
      false: httpBatchLink({
        url: getUrl(),
        /**
         * @see https://trpc.io/docs/v11/data-transformers
         */
        transformer: superjson
      })
    }), loggerLink({
      enabled: () => process.env.NODE_ENV === "development"
    })]
  }));
  return <QueryClientProvider client={getQueryClient()} data-sentry-element="QueryClientProvider" data-sentry-component="TrpcProvider" data-sentry-source-file="trpc-provider.tsx">
      <trpc.Provider client={trpcClient} queryClient={queryClient} data-sentry-element="unknown" data-sentry-source-file="trpc-provider.tsx">
        {children}
        <ReactQueryDevtools data-sentry-element="ReactQueryDevtools" data-sentry-source-file="trpc-provider.tsx" />
      </trpc.Provider>
    </QueryClientProvider>;
}