/**
 * Third-party libraries.
 */
import { useEffect, useMemo } from "react";
import { Empty } from "@/components/client/empty/empty";
import { InfiniteScroll } from "@/components/client/scroll";
import { CALL_STATUSES_CONCLUDED } from "@/components/server/call/services/enumerations";
import { PastInteractionItemCard } from "./call-past-interaction-card-item";
import { useCalls } from "./hooks";
const MIN_WIDTH = 340;

/**
 * Call Past Interactions Card Props.
 */
type CallPastInteractionsCardProps = {
  /**
   * Indicates that the card is visible.
   */
  visible?: boolean;
  /** The phone number of the client. Used to fetch the Past interactions of the client. */
  clientPhoneNumber?: string;
};

/** Card that displays the past interactions of a client. */
export function CallPastInteractionsCard(props: CallPastInteractionsCardProps) {
  // ===========================================================================
  // States
  // ===========================================================================

  /**
   * Data for filtering the past calls.
   */
  const pastCallsFilter = useMemo(() => ({
    from: props?.clientPhoneNumber,
    to: props?.clientPhoneNumber,
    statuses: CALL_STATUSES_CONCLUDED
  }), [props?.clientPhoneNumber]);

  // ===========================================================================
  // Queries
  // ===========================================================================

  /**
   * Query that contains the past interactions with `clientPhoneNumber` based
   * on the `to` OR `from` record.
   *
   * This will contain the currently selected call as well because the `endDate` is inclusive of the current call.
   *
   * TODO: Add pagination.
   */
  // const pastInteractionsQuery = usePastInteractionsQuery({
  //   skip: !props?.clientPhoneNumber || !props.visible,
  //   variables: {
  //     clientPhoneNumber: props?.clientPhoneNumber,
  //   },
  // });

  const {
    data: pastCalls,
    fetching: isPastCallsFetching,
    fetchMore: fetchMorePastCalls,
    hasMore: hasMorePastCalls,
    loading: isPastCallsLoading,
    setFilter: setPastCallsFilter
  } = useCalls({
    enabled: false,
    filter: pastCallsFilter
  });

  // ===========================================================================
  // Derived States
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the past calls filter when the provided filter changes.
   */
  useEffect(() => {
    if (pastCallsFilter) {
      setPastCallsFilter(pastCallsFilter);
    }
  }, [pastCallsFilter, setPastCallsFilter]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  // Hide if not visible.
  if (!props.visible) {
    return null;
  }
  return <div className="relative flex !min-h-[100px] w-full animate-slide-left flex-col rounded-md border bg-white" data-sentry-component="CallPastInteractionsCard" data-sentry-source-file="call-past-interactions-card.tsx">
      <div className="flex items-center justify-between border-b border-b-neutral-grey-mid p-4">
        <div className="text-tpl-navy">Past Interactions</div>

        {/* Filter is out of scope for now. */}
        {/* <Button
          disabled
          type="text"
          className="relative w-6 h-6 flex justify-center items-center cursor-not-allowed opacity-60"
         >
          <Icon src="filter" className="absolute text-xl" />
         </Button> */}
      </div>

      {/* PAST INTERACTIONS LIST */}
      {pastCalls && <InfiniteScroll className={`w-full overflow-y-auto min-w-[${MIN_WIDTH}] inline-flex flex-col gap-2 overflow-x-hidden p-4`} hasMore={hasMorePastCalls} hideStatusIndicator={!isPastCallsLoading && !isPastCallsFetching && !hasMorePastCalls}
    // hideStatusIndicator={true}
    loading={isPastCallsLoading} loadingMore={isPastCallsFetching} onLoadMore={fetchMorePastCalls}>
          {pastCalls.map(_call => <PastInteractionItemCard key={_call.id} direction={_call.direction} status={_call.status} summary={_call.summary} dateStarted={_call.date} agentName={_call.user?.fullName ?? ""} />)}
          {!isPastCallsLoading && !isPastCallsFetching && pastCalls.length === 0 && <Empty label="No Interactions" className="py-4" />}
        </InfiniteScroll>}
    </div>;
}