import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticationContextProvider"] */ "/app/components/client/authentication/authentication-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CallRejectModal"] */ "/app/components/client/call/call-reject-modal.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log-card.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log-group.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log-user.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/communication-log/communication-log.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/context/application-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/message/message-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/network/network-connection-guard.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/network/network-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/notification/notification-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/settings/settings-modal.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/system-preference/system-preference-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/trpc/trpc-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/client/twilio/hooks/use-twilio-device.ts");
;
import(/* webpackMode: "eager" */ "/app/components/client/twilio/twilio-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@ant-design/v5-patch-for-react-19/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["useUser"] */ "/app/node_modules/@auth0/nextjs-auth0/dist/client/hooks/use-user.js");
;
import(/* webpackMode: "eager", webpackExports: ["Auth0Provider"] */ "/app/node_modules/@auth0/nextjs-auth0/dist/client/providers/auth0-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/antd/dist/reset.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
