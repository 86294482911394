"use client";

import { useEffect, useState } from "react";

import { APPLICATION_CONFIGURATION } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import {
  trpc,
  TrpcRouterInputs,
  UseTrpcQueryCallbacks,
} from "@/components/client/trpc";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { CallWithOptionalRelations } from "@/components/common/call/types";
import { CALL_STATUSES_CONCLUDED } from "@/components/server/call/services/enumerations";
import { CallUtility } from "@/components/server/call/utilities";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";

/**
 * The arguments for the use calls concluded hook.
 */
type UseCallsConcludedArgs = UseTrpcQueryCallbacks<
  UseCallsConcludedReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The filter for fetching concluded calls.
 */
export type UseCallsConcludedFilter = Extract<
  TrpcRouterInputs["callRouter"]["getConcludedCalls"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use calls concluded hook.
 */
type UseCallsConcludedReturn = {
  /**
   * The paginated data for concluded calls.
   */
  data: CallWithOptionalRelations[];
  /**
   * Indicates if the concluded calls are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Fetch more concluded calls if there are more data to fetch.
   */
  fetchMore: () => Promise<void>;
  /**
   * Indicates if there are more concluded calls to fetch.
   */
  hasMore: boolean;
  /**
   * Indicates if the concluded calls are loading for the first time.
   */
  loading: boolean;
  /**
   * Set the filter for fetching concluded calls.
   */
  setFilter: React.Dispatch<
    React.SetStateAction<UseCallsConcludedFilter | undefined>
  >;
};

/**
 * The use calls concluded hook type.
 */
type UseCallsConcluded = (
  args?: UseCallsConcludedArgs,
) => UseCallsConcludedReturn;

/**
 * Hook to fetch, filter, or refetch concluded calls.
 */
export const useCallsConcluded: UseCallsConcluded = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for concluded calls.
   */
  const [callsConcluded, setCallsConcluded] = useState<
    UseCallsConcludedReturn["data"]
  >([]);

  /**
   * The filter for fetching concluded calls.
   */
  const [callsConcludedFilter, setCallsConcludedFilter] =
    useState<UseCallsConcludedFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: callsConcludedData,
    hasNextPage: hasMoreCallsConcluded,
    isLoading: callsConcludedLoading,
    isFetching: callsConcludedFetching,
    refetch: refetchCallsConcluded,
    fetchNextPage: fetchNextPageCallsConcluded,
  } = trpc.callRouter.getConcludedCalls.useInfiniteQuery(
    {
      filter: callsConcludedFilter,
      limit: APPLICATION_CONFIGURATION.pagination.limit,
    },
    {
      enabled:
        !!user?.id &&
        // Only fetch when the user has either view all or view own call logs permission.
        (user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_ALL) ||
          user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_OWN)),
      getNextPageParam: (lastPage) =>
        lastPage[lastPage.length - 1]?.id || undefined,
    },
  );

  /**
   * Subscribe to call events.
   * - Add new concluded call to the list if it is viewable by the user.
   * - Update existing concluded call when a call event is published if it is viewable by the user.
   * - Remove concluded call that is not viewable by the user.
   */
  const { status: onCallEventStatus } =
    trpc.callRouter.onCallEvent.useSubscription(
      { userId: user?.id },
      {
        onData: (eventCall) => {
          /**
           * Nothing to do if there is no call in the published event.
           */
          if (!eventCall) return;

          /**
           * Concluded calls has not been fetched yet.
           */
          if (!callsConcluded) return;

          /**
           * Index of the existing call in the calls agent list matching the
           * call in the published event.
           */
          const existingCallIndex = callsConcluded.findIndex(
            (call) => call.id === eventCall.id,
          );

          /**
           * Indicates whether the call exists in the existing calls agent list.
           */
          const isCallExisting = existingCallIndex > -1;

          /**
           * Indicates that the user can view the call.
           */
          const isCallUserViewable = CallUtility.isUserViewable({
            call: {
              status: eventCall.status,
              userId: eventCall.userId,
            },
            userId: user?.id!,
          });

          /**
           * This will hold the updated list of concluded calls.
           */
          const updatedConcludedCalls = [...callsConcluded];

          // Call is viewable by the user.
          if (
            isCallUserViewable &&
            CALL_STATUSES_CONCLUDED.includes(eventCall.status)
          ) {
            /**
             * Call the on event callback if it is provided.
             */
            args?.onEvent?.({
              data: callsConcluded,
              eventData: eventCall,
            });

            // Update the existing call since it already exists in the list.
            if (isCallExisting) {
              updatedConcludedCalls[existingCallIndex] = eventCall;
            }
            // Add the new call on top of the list since it doesn't exist.
            else {
              updatedConcludedCalls.unshift(eventCall);
            }
          }
          // Call is not viewable by the user.
          else if (isCallExisting) {
            // Remove the call from the list.
            updatedConcludedCalls.splice(existingCallIndex, 1);
          }

          // Sort the concluded calls from newest to oldest date.
          updatedConcludedCalls.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
          });

          setCallsConcluded(updatedConcludedCalls);
        },
      },
    );

  trpc.callRouter.onCallSummaryEvent.useSubscription(
    { userId: user?.id },
    {
      onData: (eventCall) => {
        if (!eventCall) return;

        if (!callsConcluded) return;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the published event.
         */
        const existingCallIndex = callsConcluded.findIndex(
          (call) => call.id === eventCall.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * This will hold the updated list of concluded calls.
         */
        const updatedConcludedCalls = [...callsConcluded];

        // Call is viewable by the user.
        if (!CALL_STATUSES_CONCLUDED.includes(eventCall.status)) {
          return;
        }

        // Update the existing call since it already exists in the list.
        if (isCallExisting) {
          updatedConcludedCalls[existingCallIndex] = {
            ...updatedConcludedCalls[existingCallIndex],
            summary: eventCall.summary,
          };

          // Sort the concluded calls from newest to oldest date.
          updatedConcludedCalls.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
          });

          setCallsConcluded(updatedConcludedCalls);
        }
      },
    },
  );

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  async function fetchMoreCallsConcluded() {
    // Concluded calls are still being fetched or there are no more concluded calls to fetch.
    if (
      callsConcludedFetching ||
      callsConcludedLoading ||
      !hasMoreCallsConcluded
    ) {
      return;
    }

    fetchNextPageCallsConcluded().then((response) => {
      // Only add the new last page to the existing data.
      setCallsConcluded((previousCallsConcluded) => [
        ...previousCallsConcluded,
        ...(response.data?.pages[response.data.pages.length - 1] || []),
      ]);
    });
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the concluded calls when the data changes.
   */
  useEffect(() => {
    // Doing a fetch more, handle that manually on the fetch more logic.
    if (callsConcluded.length) {
      return;
    }

    /**
     * Only set the calls concluded on first fetch to avoid overwriting
     * subscription event added data.
     */
    setCallsConcluded(callsConcludedData?.pages.flat() || []);
  }, [callsConcluded.length, callsConcludedData]);

  /**
   * Refetch the concluded calls if the filter changed.
   */
  useEffect(() => {
    /**
     * Clear the call routings since the filter changed.
     * This would help track if we are doing a refetch or a fetch more.
     */
    setCallsConcluded([]);
    refetchCallsConcluded();
  }, [callsConcludedFilter, refetchCallsConcluded]);

  /**
   * Trigger the on status change callback when the on call event status changes.
   */
  useEffect(() => {
    args?.onEventListenerStatusChange?.({ status: onCallEventStatus });
  }, [args, onCallEventStatus]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: callsConcluded,
    fetching: callsConcludedFetching,
    fetchMore: fetchMoreCallsConcluded,
    hasMore: hasMoreCallsConcluded,
    loading: callsConcludedLoading,
    setFilter: setCallsConcludedFilter,
  };
};
