"use client";

/**
 * Third-party libraries.
 */
import { withPageAuthRequired } from "@/components/client/hoc/with-page-auth-required";
import { Layout } from "antd";
import { PropsWithChildren, ReactNode } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { Loading } from "@/components/client/loading";
import { NetworkConnectionGuard } from "@/components/client/network";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { PermissionUtility } from "@/components/common/auth0/utilities";
import { ApiRoute } from "@/components/common/route";
type DefaultProtectedPageLayoutProps = PropsWithChildren<{
  header?: ReactNode;
  sidebar?: ReactNode;
  /**
   * Required permissions to access the page
   * - allow access to children if no permissions are required.
   */
  requiredPermissions?: Auth0Permission[];
}>;

/**
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export const DefaultProtectedPageLayout = withPageAuthRequired(function ({
  children,
  header,
  sidebar,
  requiredPermissions = []
}: DefaultProtectedPageLayoutProps) {
  /**
   * Logged on user details.
   */
  const {
    user,
    isAuthenticated,
    error,
    fetching
  } = useAuthenticationContext();
  if (fetching) {
    return <Loading size="large" />;
  }
  if (error) return <div>{error.message}</div>;
  if (!isAuthenticated()) {
    window.location.href = ApiRoute.AUTHENTICATION_LOGIN;
    return <Loading size="large" />;
  }

  // /**
  //  * Check if user has required permissions to access the page.
  //  * - when no permissions are required, return true
  //  * - when user has all required permissions, return true
  //  * - when user does not have all required permissions, return false
  //  */
  // const userHasRequiredPermissions = useMemo(() => {
  //   if (!user) return false;

  //   return PermissionUtility.isAuthorized({
  //     userPermissions: user.permissions,
  //     requiredPermissions,
  //   });
  // }, [user, requiredPermissions]);

  /**
   * Redirects the user to the 401 page when one of the following conditions are met:
   * - user is not authenticated (user is null or access token has expired)
   * - user does not have application access
   * - user does not have the required permissions to access the page
   */
  if (!isAuthenticated() || !user || !user.permissions.includes(Auth0Permission.APPLICATION_ACCESS) || !PermissionUtility.isAuthorized({
    userPermissions: user.permissions,
    requiredPermissions
  })) {
    window.location.href = "/401";
    return <Loading size="large" />;
  }
  return <Layout className="h-full w-full flex-col">
      <NetworkConnectionGuard>
        {header}
        <Layout>
          {sidebar}
          <Layout.Content className="bg-neutral-surface-grey overflow-auto p-0">
            {children}
          </Layout.Content>
        </Layout>
      </NetworkConnectionGuard>
    </Layout>;
});