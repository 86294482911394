"use client";

/**
 * Third-party libraries.
 */
import { UserAvailabilityStatus } from "@prisma/client";
import { NotificationInstance } from "antd/es/notification/interface";
import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback, useState } from "react";

/**
 * Project components
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { useNotificationContext } from "@/components/client/notification";
import { trpc } from "@/components/client/trpc";
import { useNetworkContext } from "../network";
import { UseUsers } from "../user";

/**
 * Arguments of the set user availability status function.
 */
type SetUserAvailabilityStatusArgs = {
  /**
   * Indicates that only the local state would be updated.
   * Database entry will not be affected.
   */
  local?: boolean;
  /**
   * The user availability status.
   */
  status: UserAvailabilityStatus;
};

/**
 * Application context.
 */
export type ApplicationContext = {
  // ===========================================================================
  // Application
  // ===========================================================================
  /**
   * Runs the application in development mode.
   * Certain features not available in production are enabled in development mode.
   *
   * @example
   * development
   */
  mode?: "development";
  /**
   * Sets the mode of the application.
   */
  setMode: Dispatch<SetStateAction<"development" | undefined>>;
  // ===========================================================================
  // Dialer
  // ===========================================================================
  /**
   * Indicates if the dialer is shown or hidden.
   *
   * @default false
   */
  showDialer: boolean;
  /**
   * Shows or hides the dialer.
   */
  setShowDialer: Dispatch<SetStateAction<boolean>>;
  // ===========================================================================
  // Notifications
  // ===========================================================================
  /**
   * Ant Design notification instance.
   *
   * Use this to show notifications.
   */
  notification: NotificationInstance;
  // ===========================================================================
  // Settings
  // ===========================================================================
  /**
   * Indicates if the settings modal is shown or hidden.
   */
  showSettings: boolean;
  /**
   * Shows or hides the settings modal.
   */
  setShowSettings: Dispatch<SetStateAction<boolean>>;
  // ===========================================================================
  // User Availability Status
  // ===========================================================================
  /**
   * User availability status setter.
   */
  setUserAvailabilityStatus: (args: SetUserAvailabilityStatusArgs) => void;
  /**
   * The availability status of the user.
   *
   * @default offline.
   */
  updatingUserAvailabilityStatus: boolean;
  /**
   * Updating user availability status.
   */
  userAvailabilityStatus: UserAvailabilityStatus;
};

/**
 * Application context.
 */
const ApplicationContext = React.createContext<ApplicationContext>({
  // ===========================================================================
  // Application
  // ===========================================================================
  mode: process.env.NEXT_PUBLIC_MODE,
  setMode: () => {},
  // ===========================================================================
  // Dialer
  // ===========================================================================
  setShowDialer: () => {},
  showDialer: false,
  // ===========================================================================
  // Notifications
  // ===========================================================================
  notification: {
    destroy: () => {},
    error: () => {},
    info: () => {},
    open: () => {},
    success: () => {},
    warning: () => {}
  },
  // ===========================================================================
  // Settings
  // ===========================================================================
  setShowSettings: () => {},
  showSettings: false,
  // ===========================================================================
  // User Availability Status
  // ===========================================================================
  setUserAvailabilityStatus: () => {},
  updatingUserAvailabilityStatus: false,
  userAvailabilityStatus: UserAvailabilityStatus.OFFLINE
});

/**
 * Use Application Context hook.
 */
export const useApplicationContext = () => {
  return React.useContext(ApplicationContext);
};

/**
 * Application context provider.
 */
export const ApplicationContextProvider = ({
  children
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    user
  } = useAuthenticationContext();
  const {
    notification
  } = useNotificationContext();
  const {
    setUserEventSubscriptionStatus: setUserEventSubscription
  } = useNetworkContext();
  UseUsers({
    onEventListenerStatusChange: ({
      status
    }) => {
      setUserEventSubscription(status);
    }
  });

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [mode, setMode] = useState<ApplicationContext["mode"]>(process.env.NEXT_PUBLIC_MODE);

  /**
   * Shows or hides the dialer.
   */
  const [showDialer, setShowDialer] = useState<ApplicationContext["showDialer"]>(false);

  /**
   * Shows or hides the settings modal.
   */
  const [showSettings, setShowSettings] = useState<ApplicationContext["showSettings"]>(false);

  // /**
  //  * The list of users in the system.
  //  */
  // const [users, setUsers] = useState<ApplicationContext["users"]>([]);

  /**
   * The user availability status.
   */
  const [userAvailabilityStatus, _setUserAvailabilityStatus] = useState<ApplicationContext["userAvailabilityStatus"]>(UserAvailabilityStatus.OFFLINE);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    mutate: updateUserAvailabilityStatus,
    isPending: updateUserAvailabilityStatusLoading
  } = trpc.userRouter.updateAvailabilityStatus.useMutation();

  // ===========================================================================
  // ===========================================================================
  // Function
  // ===========================================================================
  // ===========================================================================

  /**
   * Updates the user availability status on the server first before updating the
   * state. This is to ensure that the status is synced to the server first
   * before reflecting changes.
   */
  const setUserAvailabilityStatus = useCallback(({
    local,
    status
  }: SetUserAvailabilityStatusArgs) => {
    const errorMessage = `Error updating user availability status to ${status}. Please try again.`;

    /**
     * Do not allow the user to update the status if the user is not logged in.
     */
    if (!user) {
      console.warn("User is not logged in.");
      return;
    }
    if (local) {
      _setUserAvailabilityStatus(status);
      return;
    }

    /**
     * Do not allow the user to update the status if the status is currently
     * being updated. This is to prevent multiple updates at the same time.
     */
    if (updateUserAvailabilityStatusLoading) {
      console.warn("User availability status is currently being updated.");
      return;
    }
    updateUserAvailabilityStatus({
      data: {
        status
      }
    }, {
      onSuccess: data => {
        if (status !== data.availability?.status) {
          console.error(errorMessage);
          notification.error({
            message: "Error updating user availability status.",
            description: errorMessage,
            showProgress: true,
            pauseOnHover: true
          });
          return;
        }
        _setUserAvailabilityStatus(data.availability.status);
      },
      onError: error => {
        const errorMessage = `Error updating user availability status to ${status}. Please try again.`;
        console.error(errorMessage, error);
        notification.error({
          message: "Error updating user availability status.",
          description: errorMessage,
          showProgress: true,
          pauseOnHover: true
        });
      }
    });
  }, [notification, updateUserAvailabilityStatus, updateUserAvailabilityStatusLoading, user]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <ApplicationContext.Provider value={{
    // ===========================================================================
    // Application
    // ===========================================================================
    mode,
    setMode,
    // ===========================================================================
    // Dialer
    // ===========================================================================
    setShowDialer,
    showDialer,
    // ===========================================================================
    // Notifications
    // ===========================================================================
    notification,
    // ===========================================================================
    // Settings
    // ===========================================================================
    setShowSettings,
    showSettings,
    // ===========================================================================
    // User Availability Status
    // ===========================================================================
    setUserAvailabilityStatus,
    updatingUserAvailabilityStatus: updateUserAvailabilityStatusLoading,
    userAvailabilityStatus
  }} data-sentry-element="unknown" data-sentry-component="ApplicationContextProvider" data-sentry-source-file="application-context.tsx">
      {children}
    </ApplicationContext.Provider>;
};