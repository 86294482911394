/**
 * Test IDs for the audio player component.
 */
export const AudioPlayer = {
  /**
   * Test ID for the audio element.
   */
  AUDIO: "audio-player-audio",
  /**
   * Test ID for the audio player.
   */
  AUDIO_PLAYER: "audio-player-audio-player",
};

/**
 * Test IDs for the call complete card.
 */
export const CallCompleteCard = {
  /**
   * Test ID for the complete button.
   * This completes the call in wrap up state.
   */
  COMPLETE_BUTTON: "call-complete-card-complete-button",
};

/**
 * Test ID for the call control live card.
 */
export const CallControlLiveCard = {
  /**
   * Test ID for the mute button.
   * This is the button the user clicks to mute/unmute the call.
   */
  MUTE_BUTTON: "call-control-live-card-mute-button",
  /**
   * Test ID for the end call button.
   * This is the button the user clicks to end the call.
   */
  END_BUTTON: "call-control-live-card-end-call-button",
};

/**
 * Test ID for the call control ringing card.
 */
export const CallControlRingingCard = {
  /**
   * Test ID for the accept button.
   * This is the button the user clicks to accept an incoming call.
   */
  ACCEPT_BUTTON: "call-control-ringing-card-accept-button",
  /**
   * Test ID for the call direction.
   *
   * The available header values are:
   *
   * @example
   * Incoming Call
   *
   * @example
   * Outgoing Call
   */
  HEADER: "call-control-ringing-card-header",
  /**
   * Test ID for the hang up button.
   * This is the button the user clicks to cancel/reject an incoming/outgoing call.
   */
  HANG_UP_BUTTON: "call-control-ringing-card-hang-up-button",
};

/**
 * Test ID for the call recording card.
 */
export const CallRecordingCard = {
  /**
   * Test ID for the call recording card.
   */
  CALL_RECORDING_CARD: "call-recording-card",
};

/**
 * Test IDs for the call log card.
 */
export const CommunicationLog = {
  /**
   * Test ID for the communication log card.
   */
  CARD: "communication-log-card",
  /**
   * Test ID for the communication log card audio.
   *
   * Use this to test the audio player component.
   */
  CARD_AUDIO: "communication-log-card-audio",
  /**
   * Test ID for the communication log card accept call button.
   */
  CARD_CALL_ACCEPT_BUTTON: "communication-log-card-accept-call-button",
  /**
   * Test ID for the communication log card decline/reject call button.
   */
  CARD_CALL_HANG_UP_BUTTON: "communication-log-card-hang-up-call-button",
  /**
   * Test ID for the communication log mute call button.
   */
  CARD_CALL_MUTE_BUTTON: "communication-log-card-mute-call-button",
  /**
   * Test ID for the communication log card status and direction.
   */
  CARD_STATUS_AND_DIRECTION: "communication-log-card-status-and-direction",
  /**
   * Test ID for the communication log card assigned user.
   */
  CARD_USER_ASSIGNED: "communication-log-card-user-assigned",
  /**
   * Test ID for the communication log card contact.
   *
   * This could contain a contact name or a phone number.
   */
  CARD_CONTACT: "communication-log-card-contact",
  /**
   * Test ID for the communication log panel.
   *
   * This panel holds both the active and concluded calls.
   */
  PANEL: "communication-log-panel",
  /**
   * Test ID for the communication log active section.
   *
   * The section that shows the active communication logs.
   */
  SECTION_ACTIVE: "communication-log-active-section",
  /**
   * Test ID for the communication log concluded section.
   *
   * The section that shows the concluded communication logs.
   */
  SECTION_CONCLUDED: "communication-log-concluded-section",
  /**
   * Test ID for the communication log missed section.
   *
   * The section that shows the missed communication logs.
   */
  SECTION_MISSED: "communication-log-missed-section",
};

/**
 * Test ID for the dialer component.
 */
export const Dialer = {
  /**
   * Test ID for the dialer input box.
   * This is where the user enters the phone number.
   */
  INPUT_BOX: "dialer-input-box",
  /**
   * Test ID for the call button.
   * This is the button the user clicks to make a call.
   */
  CALL_BUTTON: "dialer-call-button",
};

/**
 * Test ID for the header component.
 */
export const Header = {
  /**
   * Test ID for the business status.
   *
   * This is the status of the business.
   * It could be:
   * - Open
   * - Closed
   */
  BUSINESS_STATUS: "header-business-status",
  /**
   * Test ID for the dialer button.
   * This shows the dialer component.
   */
  DIALER_BUTTON: "header-dialer-button",
  /**
   * Test ID for the user availability status.
   * This is where the user can change their availability status.
   */
  USER_AVAILABILITY_STATUS: "header-availability-status",
};

/**
 * Test IDs for the rich text editor.
 */
export const RichtTextEditor = {
  /**
   * Test ID for the rich text editor.
   */
  RICH_TEXT_EDITOR: "rich-text-editor",
};

/**
 * Test IDs for the settings modal.
 */
export const SettingsModal = {
  /**
   * Test ID for the call recording switch.
   */
  CALL_RECORDING_SWITCH: "settings-modal-call-recording-switch",
};

/**
 * Test IDs for the sidebar component.
 */
export const Sidebar = {
  /**
   * Test ID for the offline users collapsible section in the sidebar.
   */
  OFFLINE_USERS_SECTION: "sidebar-offline-users-section",
  /**
   * Test ID for the online users collapsible section in the sidebar.
   */
  ONLINE_USERS_SECTION: "sidebar-online-users-section",
  /**
   * Test ID for the user component in the sidebar.
   */
  USER: "sidebar-user",
  /**
   * Test ID for the user name in the sidebar.
   */
  USER_NAME: "sidebar-user-name",
  /**
   * Test ID for the user availability status in the sidebar.
   */
  USER_AVAILABILITY_STATUS: "sidebar-user-availability-status",
  /**
   * Test ID for the user caller names in the sidebar.
   *
   * This is a comma delimited list of the caller names under the user card.
   */
  USER_CALLS_CONTACT_NAMES: "sidebar-user-caller-names",
};
