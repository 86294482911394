/**
 * External dependencies.
 */
import {
  createTRPCReact,
  type inferReactQueryProcedureOptions,
} from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

/**
 * Internal dependencies.
 */
import { TrpcRouter } from "@/components/server/trpc/types";

/**
 * TRPC client instance.
 *
 * Use this to execute queries and mutations from the client.
 */
export const trpc = createTRPCReact<TrpcRouter>({
  abortOnUnmount: true,
});

export type TrpcReactQueryOptions = inferReactQueryProcedureOptions<TrpcRouter>;
export type TrpcRouterInputs = inferRouterInputs<TrpcRouter>;
export type TrpcRouterOutputs = inferRouterOutputs<TrpcRouter>;
