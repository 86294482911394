/**
 * Third-party libraries.
 */
import { SendOutlined, SmileOutlined } from "@ant-design/icons";
import { Editor } from "@tiptap/core";
import { Button, Divider, Tabs, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

/**
 * Project components.
 */
import { Card } from "@/components/client/card";
import { Placeholder } from "@/components/client/placeholder";
import { InfiniteScroll } from "@/components/client/scroll";
import useTabs from "@/components/client/tab/hooks/use-tabs";
import { RichTextEditor } from "@/components/client/text-editor";
import { trpc } from "@/components/client/trpc";
import { ActivityLog } from "@/components/common/activity-log";
import { ActivityLogType } from "@/components/common/activity-log/enumerations";
import { GroupedByDate, ObjectUtility } from "@/components/common/utilities";
import { ActivityLogGroup } from "./activity-log-group";
import { ActivityLogTab } from "./enumerations";
import { useActivityLogs } from "./hooks";

/**
 * Properties of the ActivityLogCard component.
 */
type ActivityLogCardProps = {
  /**
   * ID of the call this activity log card is being displayed at.
   */
  callId?: string;
  /**
   * Phone number of the customer.
   *
   * This is used to query the past interactions of the customer.
   */
  customerPhoneNumber?: string;
  /**
   * Indicates if the card is visible.
   */
  visible?: boolean;
};

/**
 * A card that displays the activity logs.
 */
export function ActivityLogCard(props: ActivityLogCardProps) {
  // ===========================================================================
  // States
  // ===========================================================================

  /**
   * Indicates the type of activity being added to the logs.
   */
  const [addActivityType, setAddActivityType] = useState<ActivityLogType>(ActivityLogType.TASK);
  const activityLogsFilter = useMemo(() => ({
    from: props?.customerPhoneNumber,
    to: props?.customerPhoneNumber
  }), [props?.customerPhoneNumber]);

  /** The tiptap editor. */
  const richTextEditorReference = useRef<Editor | null>(null);

  /**
   * States for managing the tabs.
   */
  const {
    tabs,
    activeTabKey,
    setActiveTabKey
  } = useTabs({
    tabs: Object.values(ActivityLogTab).map(tab => ({
      key: tab,
      label: tab
    }))
  });

  /**
   * Stores the editor content for the activity log.
   *
   * This can be used for services to pass as an argument to an API.
   */
  const [editorContent, setEditorContent] = useState<string | undefined>();

  /**
   * The text editor place holder.
   * This should respect the default add activity log type.
   */
  const [placeholder, setPlaceholder] = useState<string>("Add task...");
  const {
    data: activityLogs,
    fetching: isActivityLogsFetching,
    fetchMore: fetchMoreActivityLogs,
    hasMore: hasMoreActivityLogs,
    loading: isActivityLogsLoading,
    setFilter: setActivityLogsFilter
  } = useActivityLogs();

  /**
   * The logs grouped by date.
   */
  const logsGroupedByDate = useMemo<GroupedByDate<ActivityLog>>(() => {
    return ObjectUtility.groupByDate({
      data: activityLogs,
      key: "date",
      /**
       * Custom mapper that converts the calls to activity logs.
       */
      mapper: activityLog => activityLog satisfies ActivityLog
    });
  }, [activityLogs]);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    mutate: createTask,
    isPending: creatingTask
  } = trpc.taskRouter.createTask.useMutation();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const _createTask = useCallback(() => {
    if (!richTextEditorReference.current || !editorContent || !props.callId) {
      // TODO: Show a warning to the user.
      alert("SDF");
      return;
    }
    createTask({
      data: {
        callId: props.callId,
        description: editorContent
      }
    }, {
      onSettled: () => {
        if (!richTextEditorReference.current) {
          return;
        }
        richTextEditorReference.current.commands.clearContent();
        setEditorContent(undefined);
      }
    });
  }, [createTask, editorContent, props.callId]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the activity logs filter when the provided filter changes.
   */
  useEffect(() => {
    setActivityLogsFilter(activityLogsFilter);
  }, [activityLogsFilter, setActivityLogsFilter]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <Card header={<div className="flex h-full w-full items-center justify-between">
          <Card.Title title="Activity Log" />
          {/* <Button icon={<PlusOutlined />} type="primary">
            Create Task
           </Button> */}
        </div>} bodyAttributes={{
    style: {
      // padding: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0
    }
  }} data-sentry-element="Card" data-sentry-component="ActivityLogCard" data-sentry-source-file="activity-log-card.tsx">
      <div className="w-full px-2">
        <Tabs activeKey={activeTabKey} className="w-full" items={tabs} onChange={key => {
        setActiveTabKey(key);
      }} data-sentry-element="Tabs" data-sentry-source-file="activity-log-card.tsx" />
      </div>
      <div className="flex max-h-72 min-h-[100px] w-full flex-col gap-2 overflow-y-auto px-2">
        <InfiniteScroll className={`inline-flex w-full flex-col gap-2 overflow-y-auto overflow-x-hidden p-4`} hasMore={hasMoreActivityLogs} hideStatusIndicator={!isActivityLogsLoading && !isActivityLogsFetching && !hasMoreActivityLogs}
      // hideStatusIndicator={true}
      loading={isActivityLogsLoading} loadingMore={isActivityLogsFetching} onLoadMore={fetchMoreActivityLogs} data-sentry-element="InfiniteScroll" data-sentry-source-file="activity-log-card.tsx">
          {activeTabKey === ActivityLogTab.ALL && (!activityLogs.length && !isActivityLogsLoading && !isActivityLogsFetching && !hasMoreActivityLogs ? <Placeholder message="No Logs" size="large" /> : logsGroupedByDate.map(group => <ActivityLogGroup data={group} key={group.label} />))}
        </InfiniteScroll>
        {activeTabKey === ActivityLogTab.UNRESOLVED && <Placeholder message="No Logs" size="large" />}
      </div>
      <Divider className="mt-0 w-full" data-sentry-element="Divider" data-sentry-source-file="activity-log-card.tsx" />
      <div className="flex w-full flex-col gap-2">
        <div className="mx-4 flex flex-grow items-end gap-2 rounded bg-neutral-grey-light">
          <RichTextEditor onCreate={({
          editor
        }) => {
          richTextEditorReference.current = editor;
        }} onHotkey_MOD_S={_createTask} onHotkey_ESC={() => {
          if (!richTextEditorReference.current) {
            return;
          }
          richTextEditorReference.current.commands.clearContent();
          setEditorContent(undefined);
          richTextEditorReference.current.commands.blur();
        }} onUpdate={({
          editor
        }) => {
          // The editor is empty.
          if (!editor.getText()?.length) {
            setEditorContent(undefined);
          }
          // The editor has contents.
          else {
            setEditorContent(editor.getHTML());
          }
        }} placeholder={placeholder} showBubbleMenu={false} showFloatingMenu={false} data-sentry-element="RichTextEditor" data-sentry-source-file="activity-log-card.tsx" />
        </div>
        <div className="flex w-full items-center justify-end px-4">
          <div className="flex items-center justify-end gap-2 pb-1">
            <Button type="link" shape="circle" className="!h-6 !min-w-6 !text-tpl-navy" data-sentry-element="Button" data-sentry-source-file="activity-log-card.tsx">
              <SmileOutlined style={{
              fontSize: "24px"
            }} data-sentry-element="SmileOutlined" data-sentry-source-file="activity-log-card.tsx" />
            </Button>
            <Tooltip title="Add Task" data-sentry-element="Tooltip" data-sentry-source-file="activity-log-card.tsx">
              <Button type="primary" onClick={_createTask} data-sentry-element="Button" data-sentry-source-file="activity-log-card.tsx">
                <SendOutlined data-sentry-element="SendOutlined" data-sentry-source-file="activity-log-card.tsx" />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </Card>;
}