"use client";

/**
 * Third-party libraries.
 */
import { Skeleton, Tag, Tooltip } from "antd";
import { useMemo, useState } from "react";

/**
 * Project components.
 */
import { useInterval } from "@/components/client/hooks/use-interval";
import { useSystemPreferenceContext } from "@/components/client/system-preference";
import { BusinessHourUtility } from "@/components/common/business-hour";
import { BusinessStatus } from "@/components/common/system-preference/types";
import { PlayWrightTestId } from "@/tests/constants";
import { useBusinessHours } from "../settings/hooks/use-business-hours";

/**
 * Business hour tag properties.
 */
type BusinessHourTagProps = {};
const TAG_MIN_WIDTH = 59;

/**
 * Business hour tag.
 * Displays whether the system is:
 * Open - Accepting inbound calls.
 * Closed - Not accepting inbound calls.
 * Schedule - Accepting inbound calls based on the schedule.
 */
export function BusinessHourTag({}: BusinessHourTagProps) {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const {
    businessStatus
  } = useSystemPreferenceContext();

  // ===========================================================================
  // ===========================================================================
  // Queries
  // ===========================================================================
  // ===========================================================================

  const {
    data: businessHours,
    loading
  } = useBusinessHours();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  /**
   * Indicates that whether the business status is open, close, or determined
   * based on schedule..
   */
  const currentBusinessStatus = useMemo<BusinessStatus.OPEN | BusinessStatus.CLOSE>(() => {
    if (!businessStatus) {
      return BusinessStatus.CLOSE;
    }
    if (businessStatus !== BusinessStatus.SCHEDULE) {
      return businessStatus;
    }

    // const businessHours = businessHoursQuery.data?.businessHours || [];

    /** Evaluate if the current time is within business hour range. */
    const isBusinessOpen = BusinessHourUtility.isBusinessOpen({
      businessHours: businessHours || []
    });
    return isBusinessOpen ? BusinessStatus.OPEN : BusinessStatus.CLOSE;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessStatus, businessHours,
  /**
   * Intentionally left this here so that the the business hour status will be
   * updated real-time.
   */
  currentDateTime]);
  useInterval(() => setCurrentDateTime(new Date()), 1000);
  const businessStatusTooltipTitle = (() => {
    switch (currentBusinessStatus) {
      case BusinessStatus.OPEN:
        return "Accepting client calls.";
      case BusinessStatus.CLOSE:
        return "Not accepting client calls.";
      default:
        return "Not accepting client calls.";
    }
  })();
  const businessStatusChipColor = (() => {
    switch (currentBusinessStatus) {
      case BusinessStatus.OPEN:
        return "var(--semantic-green)";
      case BusinessStatus.CLOSE:
        return "var(--semantic-red)";
      default:
        return "Not accepting client calls.";
    }
  })();
  if (loading) {
    return <Skeleton.Node active style={{
      height: "22px",
      width: TAG_MIN_WIDTH
    }} />;
  }

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <Tooltip title={businessStatusTooltipTitle} data-sentry-element="Tooltip" data-sentry-component="BusinessHourTag" data-sentry-source-file="business-hour-tag.tsx">
      <Tag className={`!m-0 !flex animate-fade-in cursor-default items-center justify-center`} color={businessStatusChipColor} data-testid={PlayWrightTestId.Header.BUSINESS_STATUS} id="businessStatus" style={{
      width: TAG_MIN_WIDTH
    }} data-sentry-element="Tag" data-sentry-source-file="business-hour-tag.tsx">
        {currentBusinessStatus === BusinessStatus.OPEN ? "Open" : "Closed"}
      </Tag>
    </Tooltip>;
}