"use client";

import { useEffect, useState } from "react";

import { APPLICATION_CONFIGURATION } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import {
  trpc,
  TrpcRouterInputs,
  UseTrpcQueryCallbacks,
} from "@/components/client/trpc";
import { CallRoutingWithUser } from "@/components/common/call/types";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";

/**
 * The arguments for the use calls concluded hook.
 */
type UseCallRoutingArgs = UseTrpcQueryCallbacks<
  UseCallRoutingReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL_ROUTING][0]
>;

/**
 * The filter for fetching call routings.
 */
export type UseCallRoutingFilter = Extract<
  TrpcRouterInputs["callRoutingRouter"]["getCallRoutings"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use calls concluded hook.
 */
type UseCallRoutingReturn = {
  /**
   * The paginated data for call routings.
   */
  data: CallRoutingWithUser[];
  /**
   * Indicates if the call routings are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Fetch more call routings if there are more data to fetch.
   */
  fetchMore: () => Promise<void>;
  /**
   * Indicates if there are more call routings to fetch.
   */
  hasMore: boolean;
  /**
   * Indicates if the call routings are loading for the first time.
   */
  loading: boolean;
  /**
   * Set the filter for fetching call routings.
   */
  setFilter: React.Dispatch<
    React.SetStateAction<UseCallRoutingFilter | undefined>
  >;
};

/**
 * The use calls concluded hook type.
 */
type UseCallRoutings = (args?: UseCallRoutingArgs) => UseCallRoutingReturn;

/**
 * Hook to fetch, filter, or refetch call routings.
 */
export const UseCallRoutings: UseCallRoutings = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for call routings.
   */
  const [callRoutings, setCallRoutings] = useState<
    UseCallRoutingReturn["data"]
  >([]);

  /**
   * The filter for fetching call routings.
   */
  const [callRoutingsFilter, setCallRoutingsFilter] =
    useState<UseCallRoutingFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: callRoutingsData,
    hasNextPage: hasMoreCallRoutings,
    isLoading: callRoutingsLoading,
    isFetching: callRoutingsFetching,
    refetch: refecthCallRoutings,
    fetchNextPage: fecthNextPageCallRoutings,
  } = trpc.callRoutingRouter.getCallRoutings.useInfiniteQuery(
    {
      filter: callRoutingsFilter,
      limit: APPLICATION_CONFIGURATION.pagination.limit,
    },
    {
      enabled: !!user?.id,
      getNextPageParam: (lastPage) =>
        lastPage[lastPage.length - 1]?.id || undefined,
    },
  );

  trpc.callRoutingRouter.onCallRoutingEvent.useSubscription(
    { userId: user?.id },
    {
      onData: (eventCallRouting) => {
        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!eventCallRouting) return;

        /**
         * Concluded calls has not been fetched yet.
         */
        if (!callRoutings) return;

        /**
         * Index of the existing call routing in the calls agent list matching the
         * call routing in the published event.
         */
        const existingCallRoutingIndex = callRoutings.findIndex(
          (callRouting) => callRouting.id === eventCallRouting.id,
        );

        /**
         * Indicates whether the routing exists in the existing calls agent list.
         */
        const isCallRoutingExisting = existingCallRoutingIndex > -1;

        /**
         * This will hold the updated list of call routings.
         */
        const updatedCallRoutings = [...callRoutings];

        // Update the existing call routing since it already exists in the list.
        if (isCallRoutingExisting) {
          updatedCallRoutings[existingCallRoutingIndex] = eventCallRouting;
        }
        // Add the new call routing on top of the list since it doesn't exist.
        else {
          updatedCallRoutings.unshift(eventCallRouting);
        }

        setCallRoutings(updatedCallRoutings);
      },
    },
  );

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  async function fetchMoreCallRoutings() {
    // Call routings are still being fetched or there are no more call routings to fetch.
    if (callRoutingsFetching || callRoutingsLoading || !hasMoreCallRoutings) {
      return;
    }

    fecthNextPageCallRoutings().then((response) => {
      // Only add the new last page to the existing data.
      setCallRoutings((previousCallRoutings) => [
        ...previousCallRoutings,
        ...(response.data?.pages[response.data.pages.length - 1] || []),
      ]);
    });
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the call routings when the data changes.
   */
  useEffect(() => {
    // Doing a fetch more, handle that manually on the fetch more logic.
    if (callRoutings.length) {
      return;
    }

    /**
     * Only set the calls concluded on first fetch to avoid overwriting
     * subscription event added data.
     */
    setCallRoutings(callRoutingsData?.pages.flat() || []);
  }, [callRoutings.length, callRoutingsData]);

  /**
   * Refetch the call routings if the filter changed.
   */
  useEffect(() => {
    /**
     * Clear the call routings since the filter changed.
     * This would help track if we are doing a refetch or a fetch more.
     */
    setCallRoutings([]);
    refecthCallRoutings();
  }, [callRoutingsFilter, refecthCallRoutings]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: callRoutings,
    fetching: callRoutingsFetching,
    fetchMore: fetchMoreCallRoutings,
    hasMore: hasMoreCallRoutings,
    loading: callRoutingsLoading,
    setFilter: setCallRoutingsFilter,
  };
};
