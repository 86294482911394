"use client";

/**
 * Third-party libraries.
 */
import { ExclamationCircleFilled, ImportOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Skeleton, Tooltip } from "antd";
import { FC, useMemo } from "react";

/**
 * Project components.
 */
import { CopyButton } from "@/components/client/button";
import { Card } from "@/components/client/card";
import { ContactWithPhoneNumbersIssues } from "@/components/server/contact/services/types";
import { trpc } from "../trpc";

/**
 * Properties of the ContactLabel component.
 */
type ContactLabelProps = {
  /**
   * Name of the contact.
   */
  name?: string;
  /**
   * Phone number of the contact.
   */
  phoneNumber: string;
};

/**
 * Displays the label for the contact.
 *
 * It could be either the name or the phone number.
 *
 * The name takes precedence over the phone number.
 */
function ContactLabel(props: ContactLabelProps) {
  if (!props.name && !props.phoneNumber) {
    return null;
  }
  if (props.name) {
    return <h2 className="m-0">{props.name}</h2>;
  }
  return <h2 className="m-0" data-sentry-component="ContactLabel" data-sentry-source-file="contact-profile.tsx">
      {props.phoneNumber}
      <CopyButton value={props.phoneNumber} data-sentry-element="CopyButton" data-sentry-source-file="contact-profile.tsx" />
    </h2>;
}

/** The only data required from the Contact model for the ContactProfile component. */
type ContactProfileData = ContactWithPhoneNumbersIssues;

/** Types for the ContactProfile component. */
export type ContactProfileProps = {
  /** The id, displayName, email, phoneNumbers, and photoUrl of the contact. */
  data?: ContactProfileData & {
    /**
     * Phone number to display in case the display name is not provided.
     */
    phoneNumber: string;
  };
  /**
   * The flag to determine if the component is in a loading state.
   */
  loading?: boolean;
  /** The flag to determine if the component should be visible. */
  visible: boolean;
  /** The class name for the container of the component. */
  className?: string;
};

/** Component for displaying the name, email, phoneNumbers, and photoUrl of the contact. */
export const ContactProfile: FC<ContactProfileProps> = ({
  data: contact,
  loading,
  visible
}) => {
  // ===========================================================================
  // States
  // ===========================================================================

  /** Primary phone number. */
  const primaryPhoneNumber = useMemo(() => {
    return contact?.phoneNumbers?.find(phoneNumber => phoneNumber.primary);
  }, [contact]);

  /** List of all secondary phone numbers. */
  const secondaryNumbers = useMemo(() => {
    return contact?.phoneNumbers?.filter(phoneNumber => !phoneNumber.primary) || [];
  }, [contact]);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: contactData
  } = trpc.contactRouter.getContact.useQuery({
    filter: {
      id: contact?.id || ""
    }
  }, {
    enabled: !!contact?.id
  });

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }
  return <Card className="inline-flex" bodyAttributes={{
    className: "!gap-2"
  }} data-sentry-element="Card" data-sentry-component="ContactProfile" data-sentry-source-file="contact-profile.tsx">
      {loading ? <div className="flex w-full flex-col gap-2 rounded-md border bg-white p-4">
          <div className="flex w-full items-center justify-start gap-2">
            <Skeleton.Avatar size="default" active />
            <Skeleton.Input size="small" active block />
          </div>
          <Skeleton.Input size="small" active block />
        </div> : <div className="flex w-full animate-fade-in flex-col gap-2 opacity-0">
          <div className="flex items-center gap-2">
            {contact?.photoUrl ?
        // eslint-disable-next-line @next/next/no-img-element
        <img src={contact.photoUrl} alt="" className="h-8 w-8 rounded-full" /> : <Avatar icon={<UserOutlined />} className="flex-shrink-0" />}{" "}
            {!!contact?.phoneNumber && <ContactLabel name={contact?.displayName} phoneNumber={contact?.phoneNumber} />}
            {contact?.issues?.length! > 0 && <Tooltip placement="bottom" title={<div>
                    <div className="font-bold">Issues:</div>
                    {contact?.issues?.map(issue => <div key={issue.name + issue.message} className="py-1">
                        <div className="text-sm font-black">{issue.name}</div>
                        <div className="text-xs">{issue.message}</div>
                      </div>)}
                  </div>}>
                <ExclamationCircleFilled className="text-lg !text-semantic-red" />
              </Tooltip>}
          </div>
          {contact?.email && <div className="mt-2 flex items-center gap-2">
              <span className="text-sm">Email: </span>
              <span className="text-sm text-tpl-blue">{contact?.email}</span>
              <CopyButton value={contact?.email || ""} />
            </div>}

          {primaryPhoneNumber && <div className="mt-2 flex items-center gap-2">
              <span className="text-sm">Main: </span>
              <span className="text-sm text-tpl-blue">
                {primaryPhoneNumber.canonicalForm || primaryPhoneNumber.rawForm}
              </span>
              {primaryPhoneNumber.label && <span className="text-sm text-tpl-navy-light">
                  ({primaryPhoneNumber.label})
                </span>}
              <CopyButton value={primaryPhoneNumber.canonicalForm || primaryPhoneNumber.rawForm} />
            </div>}

          {secondaryNumbers.length > 0 && <div className="mt-2 flex gap-2">
              <span className="text-sm">Secondary: </span>
              <div className="flex flex-col gap-2">
                {secondaryNumbers?.map(phoneNumber => <div key={phoneNumber.rawForm} className="flex items-center gap-2">
                    <span className="text-sm text-tpl-blue">
                      {phoneNumber.canonicalForm || phoneNumber.rawForm}
                    </span>
                    {phoneNumber?.label && <span className="text-sm text-tpl-navy-light">
                        ({phoneNumber?.label})
                      </span>}
                    <CopyButton value={phoneNumber.canonicalForm || phoneNumber?.rawForm} />
                  </div>)}
              </div>
            </div>}
          {/* Uncomment to add "View Profile on Archus" */}
          {contactData?.profileUrl && <div className="mt-0 flex w-full flex-col items-baseline gap-2 pt-2">
              <Divider />
              <Button className="!h-5 !p-0" icon={<ImportOutlined rotate={90} />} iconPosition="end" onClick={() => {
          window.open(contactData?.profileUrl || "", "_blank");
        }} type="link">
                View Profile on Archus
              </Button>
            </div>}
        </div>}
    </Card>;
};