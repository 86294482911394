/**
 * Third-party libraries
 */
import { MoreHorizontalIcon } from "@/components/client/icons";
import Icon, { PauseOutlined } from "@ant-design/icons";
import { Dropdown, Popover, Slider } from "antd";
import React, { HTMLAttributes, useRef, useState } from "react";

/**
 * Project components
 */
import { PlayArrowIcon, VolumeHighIcon, VolumeLowIcon, VolumeMuteIcon } from "@/components/client/icons";
import { TimeUtility } from "@/components/common/time";
import { PlayWrightTestId } from "@/tests/constants";
import "@/components/client/audio/audio-player.css";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { PermissionRequired } from "../permission";

/** Type for the AudioPlayer component. */
type AudioPlayerProps = {
  /** The audio source. */
  audioSource: string;
  /** Handler when an error is thrown upon loading. */
  onError?: (event: React.SyntheticEvent<HTMLAudioElement, Event>) => void;
} & Omit<HTMLAttributes<HTMLDivElement>, "onError">;
const CLASS_NAMES = {
  AUDIO_PLAYER: "audio-player"
};

/**
 * An audio player component.
 *
 * Used to play audio files.
 */
export function AudioPlayer({
  audioSource,
  className,
  onError,
  ...rest
}: AudioPlayerProps) {
  // ===========================================================================
  // States
  // ===========================================================================

  /** True if the audio player is playing. */
  const [isPlaying, setIsPlaying] = useState(false);

  /** The volume of the audio player. */
  const [audioVolume, setAudioVolume] = useState(0.5);

  /** The current time of the audio player (in seconds). */
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);

  /** The maximum time of the audio player (in seconds). */
  const [audioDuration, setAudioDuration] = useState(0);

  /** Reference to the <audio> element. */
  const audioRef = useRef<HTMLAudioElement>(null);

  // ===========================================================================
  // Variables
  // ===========================================================================

  /**
   * Helper function for formatting the `audioDuration` and `audioCurrentTime`
   * in "numeric" format.
   *
   * Will either be 00:00:00 or 00:00 based on `audioDuration`.
   * - When `audioDuration`'s hours is 0, ALL will be 00:00.
   * - When `audioDuration`'s hours > 0, ALL will be 00:00:00.
   */
  function formatDuration(duration: number) {
    /** True when audioDuration has hours. */
    const hasHours = Math.floor(audioDuration / 3600) > 0;
    return TimeUtility.formatDuration({
      duration: duration * 1000,
      // Convert to milliseconds.
      display: {
        hours: hasHours // When there are hours, display it. When not, hide it.
      }
    });
  }
  return <div {...rest} className={`w-full ${className}`} data-sentry-component="AudioPlayer" data-sentry-source-file="audio-player.tsx">
      {/* REAL AUDIO PLAYER (Hidden) */}
      <audio data-testid={PlayWrightTestId.AudioPlayer.AUDIO} ref={audioRef} src={audioSource}
    /**
     * Comment `hidden` to debug the real audio player.
     */ hidden controls
    /**
     * Set duration when available. (Volume doesn't seem to be present, but still try to set it.)
     */ onLoadedMetadata={() => {
      setAudioDuration(audioRef.current?.duration ?? 0);
      setAudioVolume(audioRef?.current ? audioRef?.current?.volume * 100 : 100);
    }} onError={onError} onVolumeChange={() => {
      if (!audioRef.current) return;
      setAudioVolume(audioRef.current.volume * 100);
    }}
    /**
     * Only used when <audio> is not hidden:
     * When the user clicks audio slider.
     */ onTimeUpdate={() => {
      setAudioCurrentTime(audioRef.current?.currentTime ?? 0);
    }} onEnded={() => {
      /**
       * By default, audio will pause when ended (unless loop is true).
       */
      if (!audioRef.current?.loop) setIsPlaying(false);
    }} />

      {/* CUSTOM AUDIO PLAYER */}
      <div className="bg-neutral-light-gray flex w-full items-center gap-x-2 rounded-md">
        <button onClick={() => {
        if (!audioRef.current) return;
        if (isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        } else {
          audioRef.current.play();
          setIsPlaying(true);
        }
      }}>
          {isPlaying ? <PauseOutlined className="text-lg" /> : <Icon component={PlayArrowIcon} className="text-lg" />}
        </button>
        <div className="text-sm">
          {formatDuration(audioCurrentTime)}
          <span>{" / "}</span>
          {formatDuration(audioDuration)}
        </div>

        <div className="relative flex flex-grow items-center">
          <Slider className={`${CLASS_NAMES.AUDIO_PLAYER} w-full`} classNames={{
          track: "!bg-semantic-red",
          rail: "!bg-neutral-grey-mid"
        }} max={audioDuration} onChange={value => {
          if (!audioRef.current) return;
          audioRef.current.currentTime = value;
        }} value={audioCurrentTime} tooltip={{
          formatter: value => formatDuration(value!)
        }} data-sentry-element="Slider" data-sentry-source-file="audio-player.tsx" />
        </div>

        <Popover
      // Idk how to adjust the width and padding. Tried rootClassName.
      trigger={["click"]} content={<div className="h-24">
              <Slider className={`${CLASS_NAMES.AUDIO_PLAYER}`} vertical value={audioVolume} onChange={value => {
          if (!audioRef.current) return;
          audioRef.current.volume = value / 100;
          setAudioVolume(value);
        }} min={0} max={100} tooltip={{
          formatter: value => `${value}%`
        }} />
            </div>} placement="top" data-sentry-element="Popover" data-sentry-source-file="audio-player.tsx">
          <button>
            <Icon component={audioVolume > 50 ? VolumeHighIcon : audioVolume === 0 ? VolumeMuteIcon : VolumeLowIcon} className="text-lg" data-sentry-element="Icon" data-sentry-source-file="audio-player.tsx" />
          </button>
        </Popover>
        <PermissionRequired requiredPermissions={[Auth0Permission.CALL_RECORDING_EXPORT]} data-sentry-element="PermissionRequired" data-sentry-source-file="audio-player.tsx">
          <Dropdown trigger={["click"]} menu={{
          items: [{
            key: "1",
            label: "Download MP3",
            onClick: () => {
              if (!audioRef.current) return;

              /**
               * NOTE: `link.download = "filename.mp3"` doesn't work for cross-origin requests.
               * So make sure to add a `ResponseContentDisposition` header when
               * creating the signed url instead: https://macarthur.me/posts/trigger-cross-origin-download/
               */
              const link = document.createElement("a");
              link.href = audioRef.current.src;
              link.click();
            }
          }]
        }} data-sentry-element="Dropdown" data-sentry-source-file="audio-player.tsx">
            <button>
              <Icon component={MoreHorizontalIcon} className="text-lg" data-sentry-element="Icon" data-sentry-source-file="audio-player.tsx" />
            </button>
          </Dropdown>
        </PermissionRequired>
      </div>
    </div>;
}