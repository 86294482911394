/**
 * External dependencies.
 */
import { PropsWithChildren } from "react";

/**
 * Displays a single row of item in the activity log.
 */
export function ActivityLogContent({
  children
}: PropsWithChildren<{}>) {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className="inline-flex items-center justify-start gap-4 self-stretch" data-sentry-component="ActivityLogContent" data-sentry-source-file="activity-log-content.tsx">
      <div className="ml-2 h-full border-l"></div>
      {children}
    </div>;
}