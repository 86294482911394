"use client";

import { CallStatus } from "@prisma/client";
import { useEffect, useState } from "react";

import { APPLICATION_CONFIGURATION } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import { trpc, UseTrpcQueryCallbacks } from "@/components/client/trpc";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { CallWithMissedCount } from "@/components/common/call/types";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event";
/**
 * The arguments for the use calls active user hook.
 */
type UseCallsMissedArgs = UseTrpcQueryCallbacks<
  UseCallsMissedReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The filter for fetching missed calls.
 */
export type UseCallsMissedFilter = {
  /**
   * The name of the user who made the call.
   */
  from?: string;
  /**
   * The name of the user who received the call.
   */
  to?: string;
};

/**
 * The return type for the use calls missed hook.
 */
type UseCallsMissedReturn = {
  /**
   * The paginated data for missed calls.
   */
  data: CallWithMissedCount[];
  /**
   * Indicates if the missed calls are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Fetch more missed calls if there are more data to fetch.
   */
  fetchMore: () => Promise<void>;
  /**
   * Indicates if there are more missed calls to fetch.
   */
  hasMore: boolean;
  /**
   * Indicates if the missed calls are loading for the first time.
   */
  loading: boolean;
  /**
   * Set the filter for fetching missed calls.
   */
  setFilter: React.Dispatch<
    React.SetStateAction<UseCallsMissedFilter | undefined>
  >;
};

/**
 * The use calls missed hook type.
 */
type UseCallsMissed = (args?: UseCallsMissedArgs) => UseCallsMissedReturn;

/**
 * Hook to fetch, filter, or refetch missed calls.
 */
export const useCallsMissed: UseCallsMissed = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for missed calls.
   */
  const [callsMissed, setCallsMissed] = useState<UseCallsMissedReturn["data"]>(
    [],
  );

  /**
   * The filter for fetching missed calls.
   */
  const [callsMissedFilter, setCallsMissedFilter] =
    useState<UseCallsMissedFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: callsMissedData,
    hasNextPage: hasMoreCallsMissed,
    isLoading: callsMissedLoading,
    isFetching: callsMissedFetching,
    refetch: refetchCallsMissed,
    fetchNextPage: fetchNextPageCallsMissed,
  } = trpc.callRouter.getMissedCalls.useInfiniteQuery(
    {
      filter: callsMissedFilter,
      limit: APPLICATION_CONFIGURATION.pagination.limit,
    },
    {
      enabled:
        !!user?.id &&
        // Only fetch when the user has either view all or view own call logs permission.
        (user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_ALL) ||
          user?.permissions?.includes(Auth0Permission.CALL_LOGS_VIEW_OWN)),
      getNextPageParam: (lastPage) =>
        lastPage[lastPage.length - 1]?.id || undefined,
    },
  );

  /**
   * Subscribe to call events.
   * - Updates the missed call user assignment.
   */
  const { status: onCallEventStatus } =
    trpc.callRouter.onCallEvent.useSubscription(
      { userId: user?.id },
      {
        onData: (eventCall) => {
          if (!eventCall) return;

          /**
           * Missed calls has not been fetched yet.
           */
          if (!callsMissed) return;

          /**
           * Nothing to do if there is no call in the published event.
           */
          if (!eventCall || eventCall.status !== CallStatus.MISSED) return;
          /**
           * Call the on event callback if it is provided.
           */
          args?.onEvent?.({
            data: callsMissed,
            eventData: eventCall,
          });

          const existingCallIndex = callsMissed.findIndex(
            (call) => call.id === eventCall.id,
          );

          const isCallExisting = existingCallIndex > -1;

          if (!isCallExisting) {
            return;
          }

          const updatedMissedCalls = [...callsMissed];

          /**
           * Update the assigned user of the missed call.
           */
          updatedMissedCalls[existingCallIndex] = {
            ...updatedMissedCalls[existingCallIndex],
            userId: eventCall.userId,
            user: eventCall.user || null,
          };

          // Sort updatedMissedCalls from newest to oldest date.
          updatedMissedCalls.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            return dateB.getTime() - dateA.getTime();
          });

          setCallsMissed(updatedMissedCalls);
        },
      },
    );

  /**
   * Subscribe to call missed group events.
   * - Updates the missed call list.
   */
  trpc.callMissedGroupRouter.onCallMissedGroupAdded.useSubscription(undefined, {
    onData: (eventCall) => {
      /**
       * Nothing to do if there is no call in the published event.
       */
      if (!eventCall) return;

      /**
       * Missed calls has not been fetched yet.
       */
      if (!callsMissed) return;

      setCallsMissed((perviousCallsMissed) => {
        // Remove existing missed call from the list matching the customer phone number.
        const filteredCalls = perviousCallsMissed.filter(
          (call) => call.from !== eventCall.from,
        );

        // Add the new missed call to the list.
        filteredCalls.unshift(eventCall);

        // Sort filteredCalls from newest to oldest date.
        filteredCalls.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);

          return dateB.getTime() - dateA.getTime();
        });

        return filteredCalls;
      });
    },
  });

  /**
   * Remove the missed call from the list when the missed call group is deleted.
   * - Updates the missed call list.
   */
  trpc.callMissedGroupRouter.onCallMissedGroupDeleted.useSubscription(
    undefined,
    {
      onData: (eventCall) => {
        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!eventCall) return;

        /**
         * Missed calls has not been fetched yet.
         */
        if (!callsMissed) return;

        setCallsMissed((previousCallsMissed) => {
          // Remove existing missed call from the list matching the latest missed call ID.
          const filteredCalls = previousCallsMissed.filter(
            (call) => call.id !== eventCall.lastCallId,
          );

          return filteredCalls;
        });
      },
    },
  );

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  async function fetchMoreCallsMissed() {
    // Missed calls are still being fetched or there are no more missed calls to fetch.
    if (callsMissedFetching || callsMissedLoading || !hasMoreCallsMissed) {
      return;
    }

    fetchNextPageCallsMissed().then((response) => {
      // Only add the new last page to the existing data.
      setCallsMissed((previousCallsMissed) => [
        ...previousCallsMissed,
        ...(response.data?.pages[response.data.pages.length - 1] || []),
      ]);
    });
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the missed calls when the data changes.
   */
  useEffect(() => {
    // Doing a fetch more, handle that manually on the fetch more logic.
    if (callsMissed.length) {
      return;
    }

    /**
     * Only set the calls concluded on first fetch to avoid overwriting
     * subscription event added data.
     */
    setCallsMissed(callsMissedData?.pages.flat() || []);
  }, [callsMissed.length, callsMissedData]);

  /**
   * Refetch the missed calls if the filter changed.
   */
  useEffect(() => {
    /**
     * Clear the calls missed since the filter changed.
     * This would help track if we are doing a refetch or a fetch more.
     */
    setCallsMissed([]);
    refetchCallsMissed();
  }, [callsMissedFilter, refetchCallsMissed]);

  /**
   * Trigger the on status change callback when the on call event status changes.
   */
  useEffect(() => {
    args?.onEventListenerStatusChange?.({ status: onCallEventStatus });
  }, [args, onCallEventStatus]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: callsMissed,
    fetching: callsMissedFetching,
    fetchMore: fetchMoreCallsMissed,
    hasMore: hasMoreCallsMissed,
    loading: callsMissedLoading,
    setFilter: setCallsMissedFilter,
  };
};
