import type { SVGProps } from "react";
export const ChibiPanic = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width="64" height="78" viewBox="0 0 64 78" fill="none" {...props} data-sentry-element="svg" data-sentry-component="ChibiPanic" data-sentry-source-file="chibi-panic.tsx">
    <path opacity="0.12" d="M30.651 77.0121C45.8309 77.0121 58.1366 69.7046 58.1366 60.6905C58.1366 51.6763 45.8309 44.3689 30.651 44.3689C15.4712 44.3689 3.16553 51.6763 3.16553 60.6905C3.16553 69.7046 15.4712 77.0121 30.651 77.0121Z" fill="#808080" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M38.0005 52.0527C38.0005 52.0527 44.0412 54.9655 43.3364 64.5074C46.0044 63.5532 46.0044 63.5532 46.0044 63.5532" stroke="#442A25" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M21.2878 52.9062C21.2878 52.9062 18.3178 63.9547 8.40088 59.4851C9.70971 62.0464 9.70972 62.0463 9.70972 62.0463" stroke="#442A25" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M61.3077 21.7195C61.3077 21.7195 61.8111 29.2023 55.8711 33.8728" stroke="#442A25" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M29.2414 57.8781C45.0329 57.8781 57.8344 45.107 57.8344 29.353C57.8344 13.599 45.0329 0.827881 29.2414 0.827881C13.45 0.827881 0.648438 13.599 0.648438 29.353C0.648438 45.107 13.45 57.8781 29.2414 57.8781Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M10.4646 15.9443C4.17214 23.9795 3.41705 33.9231 5.93403 35.8817C8.45102 37.8403 15.5489 32.9187 21.8414 24.8835C28.1339 16.8482 31.2046 8.7628 28.6876 6.80422C26.221 4.84563 16.7571 7.90906 10.4646 15.9443Z" fill="#FDE272" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M53.6814 31.3157C54.4231 29.3716 54.0344 27.4197 52.8131 26.956C51.5919 26.4923 50.0006 27.6925 49.259 29.6366C48.5173 31.5807 48.906 33.5326 50.1273 33.9963C51.3485 34.46 52.9397 33.2598 53.6814 31.3157Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M29.0362 40.9736C30.7677 39.5808 31.2377 37.2965 30.0859 35.8714C28.9341 34.4463 26.5967 34.42 24.8652 35.8128C23.1337 37.2056 22.6637 39.49 23.8155 40.9151C24.9673 42.3402 27.3047 42.3664 29.0362 40.9736Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M35.4838 45.7248C34.8294 44.1178 37.3967 39.2464 41.5749 37.79C45.7531 36.3336 48.3707 39.7988 47.0115 41.9081C45.6524 44.0675 44.8469 43.264 42.2293 44.3186C40.5177 45.0217 36.8429 49.0896 35.4838 45.7248Z" fill="#912B20" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M26.5733 32.9185C26.0699 34.626 23.5025 35.4295 22.0426 36.0321C21.3379 36.3335 20.8848 36.9361 21.0861 37.7396C21.2372 38.3925 22.093 38.9951 22.7977 38.6938C25.2644 37.6392 28.4359 36.4841 29.2413 33.6718C29.6944 31.9643 27.0263 31.211 26.5733 32.9185Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M48.723 27.7962C50.5856 28.3487 52.4985 28.4491 54.4114 28.198C55.1665 28.0976 55.5189 27.1434 55.3679 26.4905C55.1665 25.687 54.4114 25.4359 53.6563 25.5363C52.2468 25.7372 50.7869 25.5865 49.4278 25.1345C47.7162 24.6323 47.0115 27.294 48.723 27.7962Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M36.9436 41.3557C35.685 43.0632 35.1313 44.8209 35.4837 45.7249C36.8429 49.0896 40.5177 45.0218 42.179 44.3187C42.8837 44.0174 43.4375 43.8668 43.9409 43.7663C41.3735 39.0456 37.0442 41.3055 36.9436 41.3557Z" fill="#FC5454" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
    <path d="M1.45403 31.7132C3.06491 36.2833 6.43769 41.8075 11.673 42.611C13.2336 42.8621 13.9384 40.4515 12.3275 40.2004C7.99825 39.5476 5.22953 34.8771 3.87034 31.0603C3.36694 29.5035 0.900289 30.1564 1.45403 31.7132Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-panic.tsx" />
  </svg>;