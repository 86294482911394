"use client";

/** Third-party libraries */
import { Button, Dropdown, Input, List, Spin, theme } from "antd";
import { FC, useMemo, useState } from "react";

/** Project components */
import { StringUtility } from "@/components/common/utilities";
import { PlayWrightTestId } from "@/tests/constants";
import { useAuthenticationContext } from "../authentication";
import { UserAvatar } from "../avatar";
import { useDebouncedValue } from "../hooks/use-debounce";
import { trpc } from "../trpc";
import { CommunicationLog } from "./types";
const {
  useToken
} = theme;

// ===========================================================================
// ===========================================================================
// User with Avatar component
// ===========================================================================
// ===========================================================================

/**
 * Properties of the User with Avatar component.
 */
type UserWithAvatarProps = {
  /**
   * Shows a loading indicator in place of the avatar.
   */
  loading?: boolean;
  /**
   * The name of the user. Defaults to `Unassigned`.
   */
  name?: string;
  /**
   * The size of the user chip.
   */
  size?: "small" | "large";
};

/**
 * Displays the avatar and name of a user.
 */
function UserWithAvatar({
  loading = false,
  name,
  size = "small"
}: UserWithAvatarProps) {
  const textSize = size === "small" ? "text-xs" : "text-sm";
  const renderAvatar = function () {
    if (loading) {
      return <div className="min-w-5">
          <Spin className="text-tpl-red" size="small" />
        </div>;
    }
    return <UserAvatar initials={!!name && name !== "Unassign" ? StringUtility.getInitials({
      input: name
    }) || undefined : undefined} noBadge={name === "Unassign"} size="small" />;
  };
  return <div className={`flex h-6 w-full items-center text-left text-tpl-navy ${size === "small" ? "gap-1" : "gap-2"}`} data-sentry-component="UserWithAvatar" data-sentry-source-file="communication-log-user.tsx">
      <div className="min-w-4 flex-shrink-0">{renderAvatar()}</div>
      <div className={`line-clamp-1 flex-grow font-semibold ${textSize} break-words`} data-testid={PlayWrightTestId.CommunicationLog.CARD_USER_ASSIGNED}>
        {name || "Unassigned"}
        {/* The quick brown fox jumps over the lazy dogs over and over and over
         again. */}
      </div>
    </div>;
}

/** Types for the CommunicationLogAgentAssign component. */
type CommunicationLogUserProps = {
  /**
   * The communication log details.
   */
  communicationLog: Pick<CommunicationLog, "id" | "missedCount" | "user">;
  /** User/agent that the call is currently assigned to. */
  assignedUser?: {
    /** ID of the user/agent that the call is assigned to. */
    id: string;
    /** Name of the user/agent that the call is assigned to. */
    name: string;
  };
  /** Flag for enabling the dropdown menu. Defaults to `true`. */
  enabled?: boolean;
};

/**
 * Component for displaying the agent chip in the communication log card.
 * Also used for assigning/unassigning an agent to a call.
 */
export const CommunicationLogUser: FC<CommunicationLogUserProps> = props => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    token
  } = useToken();

  /**
   * Logged on user details.
   */
  const {
    user
  } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedValue] = useDebouncedValue({
    value: searchText,
    ms: 500
  });

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // const { data: usersData, loading: usersLoading } = useUsersQuery({
  //   /**
  //    * Do not update the GraphQL cache because this is just to display the list
  //    * of users for assigning the call.
  //    *
  //    * This could contain stale user availability status and might cause the
  //    * user availability status on the user list to be incorrect if allowed to
  //    * update the GraphQL cache.
  //    */
  //   fetchPolicy: "no-cache",
  //   variables: { filter: { name: debouncedSearchText } },
  // });

  const {
    data: usersData,
    isFetching: usersLoading
  } = trpc.userRouter.getUsers.useQuery({
    filter: {
      name: debouncedSearchText
    }
  });

  /**
   * List of users.
   */
  type UserAssignmentOption = {
    /**
     * The ID of the user.
     */
    id?: string;
    /**
     * This will default to `Unassigned` if the `id` is `undefined`.
     */
    name: string;
  };

  /**
   * List of users/agents that are not the logged on user.
   */
  const agentList = useMemo<UserAssignmentOption[]>(() => {
    if (!usersData) return [];
    let _users = usersData ? [...usersData] : [];

    /**
     * Filter out the currently logged in user.
     */
    _users = _users.filter(_user => _user.id !== user?.id) || [];
    if (user?.id && usersData?.length) {
      /**
       * Currently logged on user details from the users query.
       */
      const currentUser = usersData?.find(_user => _user.id === user?.id);
      if (currentUser) {
        /**
         * Put the current user to the top of the list.
         */
        _users.unshift(currentUser);
      }
    }

    /**
     * Remove the currently assigned user.
     */
    if (props.communicationLog.user?.id) {
      _users = _users.filter(_user => _user.id !== props.communicationLog.user?.id);
    }
    const _userAssignmentOptions: UserAssignmentOption[] = _users.map(user => ({
      id: user.id,
      name: user.fullName
    }));
    if (props.assignedUser?.id) {
      /**
       * Add an unassign option.
       */
      _userAssignmentOptions.unshift({
        id: undefined,
        name: "Unassign"
      });
    }
    return _userAssignmentOptions;
  }, [props.assignedUser?.id, props.communicationLog.user?.id, user, usersData]);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // const [assignCallMutation, { loading: assignCallMutationLoading }] =
  //   useCallAssignMutation();

  const {
    mutate: assignCallMutation,
    isPending: assignCallMutationLoading
  } = trpc.callRouter.assignCall.useMutation();

  // const [
  //   assignCallMissedMutation,
  //   { loading: assignCallMissedMutationLoading },
  // ] = useCallMissedGroupAssignMutation();

  const {
    mutate: assignCallMissedMutation,
    isPending: assignCallMissedMutationLoading
  } = trpc.callMissedGroupRouter.assignCallMissedGroup.useMutation();

  // const [unassignCallMutation, { loading: unassignCallMutationLoading }] =
  //   useCallUnassignMutation();

  const {
    mutate: unassignCallMutation,
    isPending: unassignCallMutationLoading
  } = trpc.callRouter.unassignCall.useMutation();

  // const [
  //   unassignCallMissedMutation,
  //   { loading: unassignCallMissedMutationLoading },
  // ] = useCallMissedGroupUnassignMutation();

  const {
    mutate: unassignCallMissedMutation,
    isPending: unassignCallMissedMutationLoading
  } = trpc.callMissedGroupRouter.unassignCallMissedGroup.useMutation();

  /**
   * Assigns a call differently depending whether:
   * - There are already more than 1 missed calls.
   * - There is only one missed call.
   */
  const onAssignCall = async (params: {
    callId: string;
    userId: string;
  }) => {
    // There are more than 1 most recent missed calls.
    if (props.communicationLog.missedCount) {
      assignCallMissedMutation({
        data: {
          callId: params.callId,
          userId: params.userId
        }
      });
    }
    // There is only 1 most recent missed call.
    else {
      assignCallMutation({
        data: {
          callId: params.callId,
          userId: params.userId
        }
      });
    }
  };

  /**
   * Unassigns a call differently depending whether:
   * - There are already more than 1 missed calls.
   * - There is only one missed call.
   */
  const onUnassignCall = async (params: {
    /**
     * ID of the call.
     */
    callId: string;
  }) => {
    // There are more than 1 most recent missed calls.
    if (props.communicationLog.missedCount) {
      unassignCallMissedMutation({
        data: {
          callId: params.callId
        }
      });
    }
    // There is only 1 most recent missed call.
    else {
      unassignCallMutation({
        data: {
          callId: params.callId
        }
      });
    }
  };

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  const loading = assignCallMutationLoading || unassignCallMutationLoading || assignCallMissedMutationLoading || unassignCallMissedMutationLoading;

  // const loading = true;

  /**
   * Display a read only component when the dropdown is disabled.
   */
  if (!props.enabled) {
    return <UserWithAvatar name={props.assignedUser?.name} />;
  }
  return <Dropdown disabled={loading} dropdownRender={menu => <div style={{
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: 300
  }} className="py-1">
          {/* Search bar */}
          <div className="px-4 py-2">
            <Input.Search allowClear onChange={e => setSearchText(e.target.value)} onClear={() => setDebouncedValue("")} onClick={e => e.stopPropagation()} onSearch={setSearchText} placeholder="Search" value={searchText} />
          </div>
          <div className="max-h-60 overflow-y-scroll">
            <List loading={usersLoading} dataSource={agentList} renderItem={item => <Button key={item.id} type="text" className={`w-full !rounded-none px-4 py-2 font-semibold text-tpl-navy hover:bg-sky-100 ${props.assignedUser?.id === item.id ? "!bg-sky-100" : ""}`} onClick={e => {
        e.stopPropagation();

        // Unassign.
        if (item.id === undefined) {
          onUnassignCall({
            callId: props.communicationLog.id
          });
        }
        // Assign to user.
        else {
          onAssignCall({
            callId: props.communicationLog.id,
            userId: item.id
          });
        }
      }}>
                  <UserWithAvatar name={item.name} size="large" />
                </Button>} />
          </div>
        </div>} placement="bottom" trigger={["click"]} data-sentry-element="Dropdown" data-sentry-component="CommunicationLogUser" data-sentry-source-file="communication-log-user.tsx">
      <div className={`!p-0 ${loading ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={loading ? undefined : e => {
      e.preventDefault();
      e.stopPropagation();
    }}>
        <UserWithAvatar loading={loading} name={props.assignedUser?.name} data-sentry-element="UserWithAvatar" data-sentry-source-file="communication-log-user.tsx" />
      </div>
    </Dropdown>;
};