import { APPLICATION_CONFIGURATION } from "@/application-configuration";
import { ErrorMessage } from "@/components/common/error/enumerations";
import { prisma, PrismaService } from "@/components/server/prisma";
import { Prisma } from "@prisma/client";
import { PaginationArgs } from "../../../common/call/types";
import { ArchusCoreService } from "../../archus";
import { ContactWithPhoneNumbersIssues } from "./types";

export enum WhereOperation {
  AND = "AND",
  OR = "OR",
}

/** Contact search fields. */
export enum ContactSearchField {
  /**
   * Name of the contact.
   */
  DISPLAY_NAME = "displayName",

  /**
   * Phone number of the contact.
   */
  PHONE_NUMBER = "phoneNumber",

  // UNUSED: For future use.
  // WHATSAPP_CODE = "whatsAppCode",

  // UNUSED: For future use.
  // PARENT_NAME = "parentName",

  // UNUSED: For future use.
  // EMAIL = "email",

  // UNUSED: For future use.
  // STUDENT_NAME = "studentName",
}

const DEFAULT_LIMIT = 25;

/** Args for filtering contacts. */
export type GetContactsFilter = {
  /** Text to be searched. */
  searchText: string;
  /** Fields to be included in the search. */
  includeFields?: ContactSearchField[];
  /** Operation to be used in the search. Acceptable values: "AND" | "OR" */
  operation?: "AND" | "OR";
};

/** Args for getting paginated contacts. */
export type GetContactsArgs = PaginationArgs & {
  /** For filtering the contacts. */
  filter?: GetContactsFilter;
};

/**
 * Gets contacts that matches the search criteria. Results are paginated.
 * - If no `searchText` is provided, all contacts will be returned.
 * - If no `includeFields` are provided, filter by `displayName`.
 * - If no `operation` is provided, filter by `OR`.
 */
export const getContacts: PrismaService<
  GetContactsArgs,
  ContactWithPhoneNumbersIssues[]
> = async ({ prisma, data = {}, user }) => {
  /**
   * Where input for filtering the contacts.
   */
  const whereInput = createContactWhereInput(data.filter);

  const contacts = await prisma.contact.findMany({
    include: {
      issues: true,
      phoneNumbers: true,
    },
    where: whereInput,
    /**
     * Use the cursor if provided.
     */
    cursor: data?.cursor ? { id: data.cursor } : undefined,
    /**
     * Skip 1 if the cursor is provided. This is so we exclude the cursor record
     * from the next result.
     *
     * Use the offset if the cursor is not provided.
     */
    skip: !data?.cursor
      ? data?.offset || APPLICATION_CONFIGURATION.pagination.offset
      : 1,
    take: data?.limit || APPLICATION_CONFIGURATION.pagination.limit,
  });

  const contactsWithProfileUrl = [];

  for (const contact of contacts) {
    if (!contact) {
      continue;
    }

    const CONTACT_PRIMARY_PHONE_NUMBER = contact.phoneNumbers?.find(
      (phoneNumber) => !!phoneNumber.primary,
    );

    if (
      !CONTACT_PRIMARY_PHONE_NUMBER?.countryCode ||
      !CONTACT_PRIMARY_PHONE_NUMBER?.number
    ) {
      contactsWithProfileUrl.push(contact);
      continue;
    }

    const parents = await ArchusCoreService.getParents({
      mobile: {
        countryCode: CONTACT_PRIMARY_PHONE_NUMBER.countryCode.toString(),
        number: CONTACT_PRIMARY_PHONE_NUMBER.number,
      },
    });

    if (!parents.length) {
      contactsWithProfileUrl.push(contact);
      continue;
    }

    const profileUrl = await getContactProfileUrl({
      prisma,
      data: {
        filter: {
          id: contact.id,
        },
      },
      user,
    });

    contactsWithProfileUrl.push({
      ...contact,
      profileUrl,
    });
  }

  return contacts;
};

/**
 * Builds the search conditions for filtering the contacts.
 *
 * `searchText` is the text to be searched.
 * `includeFields` are the fields to be included in the search.
 * `operation` is the how to combine the search conditions.
 *
 * - If no `searchText` is provided, don't filter.
 * - If no `includeFields` are provided, filter by either the `displayName`, `phone number`, or `name`.
 * - If no `operation` is provided, filter by `OR`.
 *
 * @returns An empty Prisma where input when no search text is provided.
 */
function createContactWhereInput(filter?: GetContactsFilter) {
  let prismaContactWhereInput: Prisma.ContactWhereInput = {};

  const searchText = filter?.searchText;

  /**
   * The fields to be included in the search.
   */
  const searchFields = filter?.includeFields;

  /**
   * The operation to be used in the search.
   */
  const searchOperation = filter?.operation;

  // Only create the filtering input when the search text and search fields arguments are provided.
  if (!searchText) {
    return prismaContactWhereInput;
  }

  // When search fields are provided, filter by the search fields.
  if (searchFields?.length) {
    /**
     * Map the search fields to the corresponding database fields.
     */
    const prismaContactCompoundWhereInput = searchFields.map((searchField) => {
      // Create the filter based on the search field.
      switch (searchField) {
        // UNUSED: For future use.
        // case ContactSearchField.EMAIL:
        //   return { email: { contains: searchText, mode: "insensitive" } };

        case ContactSearchField.DISPLAY_NAME:
          return {
            OR: {
              displayName: { contains: searchText, mode: "insensitive" },
            },
          };

        case ContactSearchField.PHONE_NUMBER:
          return {
            OR: [
              {
                phoneNumbers: {
                  some: {
                    rawForm: { contains: searchText, mode: "insensitive" },
                  },
                },
              },
              {
                phoneNumbers: {
                  some: {
                    canonicalForm: {
                      contains: searchText,
                      mode: "insensitive",
                    },
                  },
                },
              },
            ],
          };

        // UNUSED: For future use.
        // case ContactSearchField.STUDENT_NAME:
        //   return {
        //     students: {
        //       some: {
        //         displayName: { contains: searchText, mode: "insensitive" },
        //       },
        //     },
        //   };

        // UNUSED: For future use.
        // case ContactSearchField.WHATSAPP_CODE:
        //   return {
        //     whatsAppCode: { contains: searchText, mode: "insensitive" },
        //   };

        default:
          /** This shouldn't happen. */
          throw new Error("Invalid search field.");
      }
    });

    /** Create the compound filter. */
    prismaContactWhereInput = {
      [searchOperation || "OR"]: prismaContactCompoundWhereInput,
    };
  }
  // When no search fields are provided, filter by `displayName` by default.
  else {
    /**
     * When `searchFields` are not provided, but `searchText` is provided,
     * filter by either the `displayName`, `phone number` or `name`.
     */
    prismaContactWhereInput = {
      OR: [
        { displayName: { contains: searchText, mode: "insensitive" } },
        {
          phoneNumbers: {
            some: { rawForm: { contains: searchText, mode: "insensitive" } },
          },
        },
        {
          phoneNumbers: {
            some: {
              canonicalForm: { contains: searchText, mode: "insensitive" },
            },
          },
        },
      ],
    };
  }

  return prismaContactWhereInput;
}

/**
 * Args for getting a single contact.
 */
type GetContactArgs = {
  filter: {
    id: string;
  };
};

/**
 * Retrieves a single contact.
 */
export const getContact: PrismaService<
  GetContactArgs,
  ContactWithPhoneNumbersIssues | null
> = async ({ data, user }) => {
  if (!data?.filter.id) {
    throw new Error(
      `Cannot get contact. ${ErrorMessage.MISSING_ARGUMENT}: "id".`,
    );
  }

  const contact = await prisma.contact.findFirstOrThrow({
    include: {
      issues: true,
      phoneNumbers: true,
    },
    where: { id: data?.filter.id },
  });

  if (!contact) {
    return null;
  }

  const CONTACT_PRIMARY_PHONE_NUMBER = contact.phoneNumbers?.find(
    (phoneNumber) => !!phoneNumber.primary,
  );

  if (
    !CONTACT_PRIMARY_PHONE_NUMBER?.countryCode ||
    !CONTACT_PRIMARY_PHONE_NUMBER?.number
  ) {
    return null;
  }

  const parents = await ArchusCoreService.getParents({
    mobile: {
      countryCode: CONTACT_PRIMARY_PHONE_NUMBER.countryCode.toString(),
      number: CONTACT_PRIMARY_PHONE_NUMBER.number,
    },
  });

  if (!parents.length) {
    return null;
  }

  const profileUrl = await getContactProfileUrl({
    prisma,
    data: {
      filter: {
        id: data?.filter.id,
      },
    },
    user,
  });

  return {
    ...contact,
    profileUrl,
  };
};

/**
 * Retrieves the Archus CRM profile URL of a contact.
 */
export const getContactProfileUrl: PrismaService<
  {
    filter: {
      id: string;
    };
  },
  string | null
> = async ({ data }) => {
  if (!data?.filter.id) {
    throw new Error(
      `Cannot get contact profile URL. ${ErrorMessage.MISSING_ARGUMENT}: "id".`,
    );
  }

  const contact = await prisma.contact.findFirstOrThrow({
    include: {
      phoneNumbers: true,
    },
    where: { id: data?.filter.id },
  });

  const CONTACT_PRIMARY_PHONE_NUMBER = contact.phoneNumbers?.find(
    (phoneNumber) => !!phoneNumber.primary,
  );

  if (
    !CONTACT_PRIMARY_PHONE_NUMBER?.countryCode ||
    !CONTACT_PRIMARY_PHONE_NUMBER?.number
  ) {
    return null;
  }

  const parents = await ArchusCoreService.getParents({
    mobile: {
      countryCode: CONTACT_PRIMARY_PHONE_NUMBER.countryCode.toString(),
      number: CONTACT_PRIMARY_PHONE_NUMBER.number,
    },
  });

  if (!parents.length) {
    return null;
  }

  return `${process.env.ARCHUS_CUSTOMER_RELATION_MANAGEMENT_URL}/customers/parents/profile/${parents[0]?._id}`;
};
