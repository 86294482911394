/**
 * Third-party libraries.
 */
import { Button, Tooltip } from "antd";

/**
 * Project components.
 */
import { TimeUtility } from "@/components/common/time";
import { PlayWrightTestId } from "@/tests/constants";
import { useEffect, useState } from "react";
import { useInterval } from "../hooks/use-interval";

/**
 * Call complete card properties.
 */
type CallCompleteCardProps = {
  /**
   * Callbacks for the call complete card.
   */
  callback: {
    onCompleteCall: (args: {
      /**
       * Twilio call ID.
       */
      callId: string;
    }) => void;
  };
  /**
   * Data for rendering the call complete card.
   */
  data: {
    /**
     * Twilio call ID.
     */
    callId: string;
    /**
     * Date time when the call was ended.
     */
    dateEnded: Date;
    /**
     * Name of the client.
     */
    clientName?: string;
    /**
     * Phone number of the client.
     */
    clientPhoneNumber: string;
  };
  /**
   * Disables the "complete" button.
   */
  disabled?: boolean;
  /**
   * Displays a loading spinner on the "complete" button.
   */
  loading?: boolean;
  /**
   * Message to be displayed on the tooltip.
   */
  tooltip?: string;
  /**
   * A flag to indicate if the call complete card is visible.
   */
  visible?: boolean;
};

/**
 * A card which allows an agent to complete a call in "wrap up" status.
 * Display an avatar, the client name phone number, and a button to complete the call.
 */
export function CallCompleteCard({
  callback,
  data,
  disabled,
  loading,
  tooltip,
  visible
}: CallCompleteCardProps) {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [duration, setDuration] = useState(0);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useInterval(() => setDuration(Date.now() - data.dateEnded.getTime()), 1000);

  /**
   * Update the duration of the call when the date ended changes.
   */
  useEffect(() => {
    setDuration(Date.now() - data.dateEnded.getTime());
  }, [data.dateEnded]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }
  return <div className="inline-flex w-full animate-slide-left flex-col items-center justify-center gap-4 bg-white p-7" data-sentry-component="CallCompleteCard" data-sentry-source-file="call-complete-card.tsx">
      <div className="flex flex-col items-center justify-center gap-2 self-stretch">
        <div className="text-sm font-semibold text-tpl-navy">
          Wrap Up {TimeUtility.formatDuration({
          duration
        })}
        </div>
        {data.clientName?.length && <div className="flex flex-col items-start justify-start self-stretch">
            <div className="self-stretch text-center text-sm font-semibold leading-[17.50px] text-tpl-navy">
              {data.clientName}
            </div>
          </div>}
        <div className="self-stretch text-center text-sm leading-[17.50px] text-gray-400">
          {data.clientPhoneNumber}
        </div>
      </div>
      <Tooltip title={tooltip || ""} data-sentry-element="Tooltip" data-sentry-source-file="call-complete-card.tsx">
        <Button disabled={disabled} data-testid={PlayWrightTestId.CallCompleteCard.COMPLETE_BUTTON} loading={loading} onClick={() => callback.onCompleteCall({
        callId: data.callId
      })} type="primary" data-sentry-element="Button" data-sentry-source-file="call-complete-card.tsx">
          Complete Call
        </Button>
      </Tooltip>
    </div>;
}