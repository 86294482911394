/**
 * Third-party libraries.
 */
import { HTMLAttributes, useEffect, useState } from "react";

/**
 * Project components.
 */
import { ButtonCall, ButtonCallType } from "@/components/client/button";
import { CommunicationDirection, useCommunicationLogContext } from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { PhoneIcon } from "@/components/client/icons";
import { TimeUtility } from "@/components/common/time";
import { PlayWrightTestId } from "@/tests/constants";
import Icon, { UserOutlined } from "@ant-design/icons";

/**
 * Call control ringing card properties.
 */
export type CallControlRingingCardProps = {
  /**
   * CSS classes for the card.
   */
  className?: HTMLAttributes<HTMLDivElement>["className"];
  /**
   * The selected communication log.
   */
  data: CommunicationLog | null;
  /**
   * Indicates that the card is visible.
   */
  visible: boolean;
};

/**
 * Displays a card with the ringing call details, an accept and reject button.
 */
export function CallControlRingingCard({
  className = "",
  data,
  visible = false
}: CallControlRingingCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    accept,
    hangUp,
    isAccepting,
    isHangingUp
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const accepting = data?.id ? isAccepting({
    callId: data.id
  }) : false;

  /**
   * The date and time the call started ringing.
   */
  const [dateRingingStarted, setDateRingingStarted] = useState<Date | undefined>();

  /**
   * Ringing duration of the call in milliseconds
   */
  const [duration, setDuration] = useState(0);
  const hangingUp = data?.id ? isHangingUp({
    callId: data.id
  }) : false;

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useEffect(() => {
    if (!dateRingingStarted) {
      return;
    }
    const interval = setInterval(() => {
      setDuration(() => new Date().getTime() - dateRingingStarted.getTime());
    }, 1000);
    return () => {
      clearInterval(interval);
      setDuration(0);
    };
  }, [dateRingingStarted]);
  useEffect(() => {
    setDateRingingStarted(visible ? new Date() : undefined);
  }, [visible]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!data || !visible) {
    return null;
  }
  return <div className={`inline-flex w-full animate-slide-left flex-col items-center justify-center gap-4 bg-white p-12 ${className}`} data-sentry-component="CallControlRingingCard" data-sentry-source-file="call-control-ringing-card.tsx">
      <div className="flex h-40 flex-col items-center justify-center gap-2 self-stretch">
        <div className="text-sm font-semibold text-tpl-navy" data-testid={PlayWrightTestId.CallControlRingingCard.HEADER}>
          {data?.direction === CommunicationDirection.INBOUND ? "Incoming" : "Outgoing"}{" "}
          Call {TimeUtility.formatDuration({
          duration
        })}
        </div>
        <div className="relative flex h-16 w-16 items-center justify-center rounded-full bg-slate-100">
          <UserOutlined data-sentry-element="UserOutlined" data-sentry-source-file="call-control-ringing-card.tsx" />
        </div>
        {data.clientName && <div className="flex flex-col items-start self-stretch overflow-ellipsis">
            <div className="leading-17.5 self-stretch text-center text-sm font-semibold text-tpl-navy">
              {data.clientName}
            </div>
          </div>}
        <div className="leading-17.5 self-stretch text-center text-sm text-gray-400">
          {data?.direction === CommunicationDirection.INBOUND ? data?.from : data?.to}
        </div>
      </div>
      <div className="inline-flex items-start gap-2">
        {data?.direction === CommunicationDirection.INBOUND && <ButtonCall data-testid={PlayWrightTestId.CallControlRingingCard.ACCEPT_BUTTON} disabled={accepting || hangingUp} loading={accepting} icon={<Icon className="!text-white" component={PhoneIcon} />} onClick={async () => {
        if (accepting || hangingUp) {
          return;
        }
        await accept({
          callId: data.id
        });
      }} type={ButtonCallType.ACCEPT} />}
        <ButtonCall data-testid={PlayWrightTestId.CallControlRingingCard.HANG_UP_BUTTON} disabled={accepting || hangingUp} loading={hangingUp} icon={<Icon component={PhoneIcon} className="!text-white" rotate={135} />} onClick={async () => {
        if (accepting || hangingUp) {
          return;
        }
        await hangUp({
          callId: data.id,
          direction: data.call.direction,
          status: data.call.status
        });
      }} type={ButtonCallType.HANG_UP} data-sentry-element="ButtonCall" data-sentry-source-file="call-control-ringing-card.tsx" />
      </div>
    </div>;
}