"use client";

/**
 * Third-party libraries.
 */
import Icon, { CloseOutlined as CloseOutlinedIcon, SettingOutlined as SettingOutlinedIcon } from "@ant-design/icons";
import { Button, Drawer, Dropdown, Layout, MenuProps, Select, Tooltip } from "antd";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useCallback, useLayoutEffect, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { UserAvatar } from "@/components/client/avatar";
import { useCommunicationLogContext } from "@/components/client/communication-log";
import { useApplicationContext } from "@/components/client/context";
import { Dialer } from "@/components/client/dialer";
import { DialPadIcon } from "@/components/client/icons";
import { ThePiqueLabLogo } from "@/components/client/images";
import { Loading } from "@/components/client/loading";
import { useMessageContext } from "@/components/client/message";
import { PermissionRequired } from "@/components/client/permission";
import { useTwilioContext } from "@/components/client/twilio";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { ApiRoute } from "@/components/common/route";
import { StringUtility } from "@/components/common/utilities";
import { PlayWrightTestId } from "@/tests/constants";
import { UserAvailabilityStatus } from "@prisma/client";
import { BusinessHourTag } from "../business-hours";
import { trpc } from "../trpc";

/**
 * Default protected page layout header properties.
 */
type DefaultProtectedPageLayoutHeaderProps = {};
const HEADER_ICON_CLASSES = "!text-2xl !text-tpl-navy";

/**
 *
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export function DefaultProtectedPageLayoutHeader({}: DefaultProtectedPageLayoutHeaderProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const router = useRouter();
  /**
   * Logged on user details.
   */
  const {
    user,
    error,
    fetching
  } = useAuthenticationContext();

  /**
   * Application Context.
   */
  const {
    setShowDialer,
    setShowSettings,
    setUserAvailabilityStatus,
    showDialer,
    showSettings,
    updatingUserAvailabilityStatus,
    userAvailabilityStatus
  } = useApplicationContext();
  const {
    hasActiveCommunicationLog
  } = useCommunicationLogContext();
  const message = useMessageContext();

  // ===========================================================================
  // ===========================================================================
  // Mutations
  // ===========================================================================
  // ===========================================================================

  // const [createCall, { loading: creatingCall }] = useCallCreateMutation();

  const {
    mutate: createCall,
    isPending: creatingCall
  } = trpc.callRouter.createCall.useMutation();

  /**
   * Twilio Context.
   */
  const {
    device,
    deviceRegistering
  } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * User initials.
   */
  const [userInitials, setUserAvatarInitials] = useState<string | null>();

  /**
   * User avatar menu items.
   */
  const [userAvatarMenuItems, setUserAvatarMenuItems] = useState<MenuProps["items"]>();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Dials a phone number.
   *
   * Creates an outbound call.
   */
  const onDialerCall = useCallback(({
    phoneNumber
  }: {
    /**
     * Phone number to call.
     */
    phoneNumber: string;
  }) => {
    createCall({
      data: {
        to: phoneNumber
      }
    }, {
      onError: err => message.error(err.message),
      onSuccess: () => {
        // Hide the dialer.
        setShowDialer(false);
      }
    });
  }, [createCall, message, setShowDialer]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Set the user avatar initials and menu items.
   */
  useLayoutEffect(() => {
    // Get the user initials.
    setUserAvatarInitials(StringUtility.getInitials({
      input: user?.name ?? "",
      maxLength: 2
    }));

    /**
     * Create the drop down menu for the user avatar.
     */
    setUserAvatarMenuItems([{
      key: "1",
      disabled: true,
      label: <Link target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
            {user?.name}
          </Link>
    }, {
      key: "2",
      label: <div onClick={() => {
        window.location.assign(ApiRoute.AUTHENTICATION_LOGOUT);
      }}>
            Logout
          </div>
    }]);
  }, [router, user]);
  if (fetching) {
    return <Loading size="large" />;
  }
  if (error) return <div>{error.message}</div>;
  if (!user) {
    router.push(ApiRoute.AUTHENTICATION_LOGIN);
    return <Loading size="large" />;
  }
  if (user && !user.permissions.includes(Auth0Permission.APPLICATION_ACCESS)) {
    router.push("/401");
    return <Loading size="large" />;
  }
  return <Layout.Header className="flex justify-between" style={{
    border: "1px solid rgba(0,0,0,0.1)"
  }} data-sentry-element="unknown" data-sentry-component="DefaultProtectedPageLayoutHeader" data-sentry-source-file="default-protected-page-layout-header.tsx">
      <ThePiqueLabLogo data-sentry-element="ThePiqueLabLogo" data-sentry-source-file="default-protected-page-layout-header.tsx" />
      <div className="flex items-center gap-2 leading-none">
        <BusinessHourTag data-sentry-element="BusinessHourTag" data-sentry-source-file="default-protected-page-layout-header.tsx" />
        <Tooltip title={hasActiveCommunicationLog || !device || deviceRegistering ? "Disabled During Active Logs" : "Dial Pad"} data-sentry-element="Tooltip" data-sentry-source-file="default-protected-page-layout-header.tsx">
          <Button data-testid={PlayWrightTestId.Header.DIALER_BUTTON} disabled={hasActiveCommunicationLog || !device || deviceRegistering} icon={<Icon className={HEADER_ICON_CLASSES} component={DialPadIcon} />} onClick={() => setShowDialer(!showDialer)} type="text" data-sentry-element="Button" data-sentry-source-file="default-protected-page-layout-header.tsx" />
        </Tooltip>
        <PermissionRequired requiredPermissions={[Auth0Permission.SETTINGS_VIEW]} data-sentry-element="PermissionRequired" data-sentry-source-file="default-protected-page-layout-header.tsx">
          <Tooltip title="Settings" data-sentry-element="Tooltip" data-sentry-source-file="default-protected-page-layout-header.tsx">
            <Button type="text" icon={<SettingOutlinedIcon className="!text-2xl !text-tpl-navy" />} onClick={() => setShowSettings(!showSettings)} data-sentry-element="Button" data-sentry-source-file="default-protected-page-layout-header.tsx" />
          </Tooltip>
        </PermissionRequired>
        <Select className="user-availability-status-selector" data-testid={PlayWrightTestId.Header.USER_AVAILABILITY_STATUS} disabled={!device || updatingUserAvailabilityStatus || deviceRegistering || userAvailabilityStatus === UserAvailabilityStatus.ON_A_CALL || userAvailabilityStatus === UserAvailabilityStatus.RINGING || userAvailabilityStatus === UserAvailabilityStatus.WRAPPING_UP} loading={updatingUserAvailabilityStatus || deviceRegistering} options={[{
        label: "Available",
        value: UserAvailabilityStatus.AVAILABLE
      }, {
        label: "Break",
        value: UserAvailabilityStatus.BREAK
      }, {
        label: "Busy",
        value: UserAvailabilityStatus.BUSY
      }, {
        label: "Offline",
        value: UserAvailabilityStatus.OFFLINE
      }, {
        disabled: true,
        label: "Ringing",
        value: UserAvailabilityStatus.RINGING
      }, {
        disabled: true,
        label: "On A Call",
        value: UserAvailabilityStatus.ON_A_CALL
      }, {
        disabled: true,
        label: "Wrapping Up",
        value: UserAvailabilityStatus.WRAPPING_UP
      }]} onChange={value => {
        setUserAvailabilityStatus({
          status: value
        });
      }} style={{
        minWidth: "126px"
      }} value={userAvailabilityStatus} data-sentry-element="Select" data-sentry-source-file="default-protected-page-layout-header.tsx" />
        <Dropdown menu={{
        items: userAvatarMenuItems
      }} placement="bottomLeft" trigger={["click"]} data-sentry-element="Dropdown" data-sentry-source-file="default-protected-page-layout-header.tsx">
          <div>
            <UserAvatar status={userAvailabilityStatus} initials={userInitials ?? undefined} style={{
            cursor: "pointer"
          }} data-sentry-element="UserAvatar" data-sentry-source-file="default-protected-page-layout-header.tsx" />
          </div>
        </Dropdown>
        <Drawer closable={false}
      // mask={false}
      onClose={() => setShowDialer(false)} open={showDialer} styles={{
        body: {
          padding: 0
        }
      }} title={<span className="flex w-full items-center justify-between">
              Dialer
              <Tooltip title="Close">
                <Button icon={<CloseOutlinedIcon className="!text-tpl-navy" />} onClick={async () => setShowDialer(false)} shape="circle" type="text" />
              </Tooltip>
            </span>} data-sentry-element="Drawer" data-sentry-source-file="default-protected-page-layout-header.tsx">
          <Dialer disabled={hasActiveCommunicationLog || !device || deviceRegistering} loading={creatingCall} onDial={onDialerCall} visible={showDialer} data-sentry-element="Dialer" data-sentry-source-file="default-protected-page-layout-header.tsx" />
        </Drawer>
      </div>
    </Layout.Header>;
}