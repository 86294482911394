/**
 * Third-party libraries.
 */
import axios from "axios";
import jwt from "jsonwebtoken";
import jwksRsa, { DecodedToken } from "jwks-rsa";

/**
 * Project components.
 */
import {
  Auth0M2MToken,
  Auth0Token,
  ValidateTokenArgs,
} from "@/components/common/auth0/types";

const auth0Domain = process.env.AUTH0_ISSUER_BASE_URL;
const auth0AudienceApi = process.env.AUTH0_AUDIENCE;

// Configure jwks-rsa
jwksRsa({
  jwksUri: `${auth0Domain}/.well-known/jwks.json`,
});

let m2mToken: Auth0M2MToken;

/**
 * Get a Machine to Machine token for use with Archus Core Service.
 *
 * @returns The access token.
 */
export async function getM2MToken(): Promise<Auth0M2MToken> {
  if (m2mToken && m2mToken.expires_in_date < new Date()) {
    return m2mToken;
  }

  const currentDate = new Date();

  const TOKEN_RESPONSE = await axios.post(
    `${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`,
    {
      client_id: process.env.AUTH0_CORE_SERVICE_API_CLIENT_ID,
      client_secret: process.env.AUTH0_CORE_SERVICE_API_CLIENT_SECRET,
      audience: process.env.AUTH0_CORE_SERVICE_API_AUDIENCE,
      grant_type: "client_credentials",
    },
  );

  m2mToken = {
    ...TOKEN_RESPONSE.data,
    // Add "expires_in_date" property.
    expires_in_date: new Date(
      currentDate.getTime() + TOKEN_RESPONSE.data.expires_in * 1000,
    ),
  };

  return m2mToken;
}

/**
 * Get JWKS from Auth0.
 */
const getJwks = async () => {
  try {
    const response = await axios.get<{
      keys: DecodedToken[];
    }>(`${auth0Domain}/.well-known/jwks.json`);

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * Convert a certificate string to PEM.
 */
const toPEM: (cert: string) => string = (cert) => {
  let formattedCert = cert.match(/.{1,64}/g)?.join("\n");
  formattedCert = "-----BEGIN CERTIFICATE-----\n" + formattedCert;
  formattedCert = formattedCert + "\n-----END CERTIFICATE-----\n";
  return formattedCert;
};

/**
 * Validates the JWT token.
 *
 * @returns The JWT token or null if the catchesError parameter is set to true
 * and the token is invalid or is expired.
 *
 * @throws Error if the "catchesError" parameter set to false and the token
 * is invalid or expired.
 */
export async function validateToken({
  token,
  catchError = false,
}: ValidateTokenArgs) {
  // const jwksKeys: DecodedToken[] = (await getJwks())?.data?.keys || [];
  const jwks = await getJwks();

  // Public Key.
  const publicKey: any = jwks?.keys.find((key: any) => key.alg === "RS256");

  if (!publicKey) {
    throw new Error("Missing RS256 public key.");
  }

  // Secret Key.
  const secretKey = toPEM(publicKey.x5c[0]);

  if (!secretKey) {
    throw new Error("Missing x5c secret key.");
  }

  // secret in env, its '\n' symbol is invalid, so need replace '\n' string to \n symbol.
  try {
    const decoded = jwt.verify(
      token.replace("Bearer ", ""),
      secretKey.replace(/\\n/g, "\n"),
      {
        audience: auth0AudienceApi,
        algorithms: ["RS256"],
      },
    );

    return decoded as Auth0Token;
  } catch (error) {
    if (!catchError) {
      console.error(error);
      throw error;
    }

    return null;
  }
}
