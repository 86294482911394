"use client";

/**
 * External dependencies.
 */
import React, { Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from "react";
import { TrpcSubscriptionStatus } from "@/components/common/trpc/types";

/**
 * Application context.
 */

export type NetworkContext = {
  // ===========================================================================
  // Application
  // ===========================================================================
  /**
   * Indicates that the application is connected to the network.
   */
  connected: boolean;
  /**
   * Sets the flag which indicates that the network is still loading.
   */
  loading: boolean;
  applicationConnected: boolean;
  applicationLoading: boolean;
  // ===========================================================================
  // TRPC Subscriptions
  // ===========================================================================
  callsUserActiveEventSubscriptionStatus: TrpcSubscriptionStatus;
  callsMissedEventSubscriptionStatus: TrpcSubscriptionStatus;
  callsConcludedEventSubscriptionStatus: TrpcSubscriptionStatus;
  userEventSubscriptionStatus: TrpcSubscriptionStatus;
  setCallsUserActiveEventSubscriptionStatus: Dispatch<SetStateAction<TrpcSubscriptionStatus>>;
  setCallsMissedEventSubscriptionStatus: Dispatch<SetStateAction<TrpcSubscriptionStatus>>;
  setCallsConcludedEventSubscriptionStatus: Dispatch<SetStateAction<TrpcSubscriptionStatus>>;
  setUserEventSubscriptionStatus: Dispatch<SetStateAction<TrpcSubscriptionStatus>>;
  // ===========================================================================
  // Twilio
  // ===========================================================================
  twilioConnected: boolean;
  setTwilioConnected: Dispatch<SetStateAction<boolean>>;
  twilioLoading: boolean;
  setTwilioLoading: Dispatch<SetStateAction<boolean>>;
};

/**
 * Application context.
 */
const NetworkContext = React.createContext<NetworkContext>({
  // ===========================================================================
  // Application
  // ===========================================================================
  connected: false,
  loading: true,
  applicationConnected: false,
  applicationLoading: true,
  // ===========================================================================
  // TRPC Subscriptions
  // ===========================================================================
  callsUserActiveEventSubscriptionStatus: "connecting",
  setCallsUserActiveEventSubscriptionStatus: () => {},
  callsMissedEventSubscriptionStatus: "connecting",
  setCallsMissedEventSubscriptionStatus: () => {},
  callsConcludedEventSubscriptionStatus: "connecting",
  setCallsConcludedEventSubscriptionStatus: () => {},
  userEventSubscriptionStatus: "connecting",
  setUserEventSubscriptionStatus: () => {},
  // ===========================================================================
  // Twilio
  // ===========================================================================
  twilioConnected: false,
  setTwilioConnected: () => {},
  twilioLoading: true,
  setTwilioLoading: () => {}
});

/**
 * Use Application Context hook.
 */
export const useNetworkContext = () => {
  return React.useContext(NetworkContext);
};

/**
 * Application context provider.
 */
export const NetworkContextProvider = ({
  children
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // TRPC Subscriptions
  // ===========================================================================

  const [callsUserActiveEventSubscriptionStatus, setCallsUserActiveEventSubscriptionStatus] = useState<NetworkContext["callsUserActiveEventSubscriptionStatus"]>("connecting");
  const [callsMissedEventSubscriptionStatus, setCallsMissedEventSubscriptionStatus] = useState<NetworkContext["callsMissedEventSubscriptionStatus"]>("connecting");
  const [callsConcludedEventSubscriptionStatus, setCallsConcludedEventSubscriptionStatus] = useState<NetworkContext["callsConcludedEventSubscriptionStatus"]>("connecting");
  const [userEventSubscriptionStatus, setUserEventSubscriptionStatus] = useState<NetworkContext["userEventSubscriptionStatus"]>("connecting");

  // ===========================================================================
  // TRPC Subscriptions
  // ===========================================================================

  const [twilioConnected, setTwilioConnected] = useState<boolean>(false);
  const [twilioLoading, setTwilioLoading] = useState<boolean>(true);

  // ===========================================================================
  // Application
  // ===========================================================================

  const connected = useMemo(() => twilioConnected && callsUserActiveEventSubscriptionStatus === "pending" && callsMissedEventSubscriptionStatus === "pending" && callsConcludedEventSubscriptionStatus === "pending" && userEventSubscriptionStatus === "pending", [twilioConnected, callsUserActiveEventSubscriptionStatus, callsMissedEventSubscriptionStatus, callsConcludedEventSubscriptionStatus, userEventSubscriptionStatus]);
  const loading = useMemo(() => twilioLoading || callsUserActiveEventSubscriptionStatus === "connecting" || callsMissedEventSubscriptionStatus === "connecting" || callsConcludedEventSubscriptionStatus === "connecting" && userEventSubscriptionStatus === "connecting", [twilioLoading, callsUserActiveEventSubscriptionStatus, callsMissedEventSubscriptionStatus, callsConcludedEventSubscriptionStatus, userEventSubscriptionStatus]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <NetworkContext.Provider value={{
    // ===========================================================================
    // Application
    // ===========================================================================
    connected,
    loading,
    applicationConnected: callsUserActiveEventSubscriptionStatus === "pending",
    applicationLoading: callsUserActiveEventSubscriptionStatus === "connecting",
    // ===========================================================================
    // TRPC Subscriptions
    // ===========================================================================
    callsUserActiveEventSubscriptionStatus,
    callsMissedEventSubscriptionStatus,
    callsConcludedEventSubscriptionStatus,
    userEventSubscriptionStatus,
    setCallsUserActiveEventSubscriptionStatus,
    setCallsMissedEventSubscriptionStatus,
    setCallsConcludedEventSubscriptionStatus,
    setUserEventSubscriptionStatus,
    // ===========================================================================
    // Twilio
    // ===========================================================================
    twilioConnected,
    setTwilioConnected,
    twilioLoading,
    setTwilioLoading
  }} data-sentry-element="unknown" data-sentry-component="NetworkContextProvider" data-sentry-source-file="network-context.tsx">
      {children}
    </NetworkContext.Provider>;
};