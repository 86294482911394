/**
 * Third-party libraries.
 */
import { Fragment } from "react";

/**
 * Project components.
 */
import { ActivityLogType } from "@/components/common/activity-log/enumerations";
import { type ActivityLog as ActivityLogDataType } from "@/components/common/activity-log/types";
import { ActivityLog } from "./activity-log";
/**
 * Properties of the ActivityLogs component.
 */
type ActivityLogsProps = {
  /**
   * List of activity log records.
   */
  logs: ActivityLogDataType[];
};

/**
 * Displays multiple activity log records.
 * - Icon is displayed on the left side of the activity log.
 *    - The icon is determined by the type of activity log.
 * - If the activity log is a message, it will display the replies.
 *    - The replies will be displayed in a nested list.
 * - If the activity log is a task, it will display the status of the task.
 *    - The status will be displayed as a circle icon.
 *        - Green circle with a check for completed.
 *        - Hollow circle for pending.
 */
export function ActivityLogs(props: ActivityLogsProps) {
  return <div className="flex w-full flex-col gap-2" data-sentry-component="ActivityLogs" data-sentry-source-file="activity-logs.tsx">
      {props.logs.map((log, index) => {
      return <Fragment key={index}>
            <ActivityLog {...log} />
            {log.type === ActivityLogType.NOTE && !!log.replies?.length && <div className="flex w-full flex-col gap-2 pl-7">
                {log.replies.map(reply => {
            return <ActivityLog {...reply} key={reply.id} />;
          })}
              </div>}
          </Fragment>;
    })}
    </div>;
}