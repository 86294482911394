import { BusinessHour, DayOfWeek } from "@prisma/client";

export function sort({ businessHours }: { businessHours: BusinessHour[] }) {
  const dayOfWeekOrder = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY,
  ];

  // Sort the business hours based on the arrange ment of the DayOfWeek enumeration.
  businessHours.sort((a, b) => {
    return dayOfWeekOrder.indexOf(a.day) - dayOfWeekOrder.indexOf(b.day);
  });

  return businessHours;
}
