"use client";

/**
 * Third-party libraries.
 */
import TextArea from "antd/es/input/TextArea";
import Modal from "antd/es/modal/Modal";

/**
 * Project components.
 */
import { useCommunicationLogContext } from "@/components/client/communication-log";
import { useState } from "react";
import { trpc } from "../trpc";

/**
 * Properties for the call reject modal.
 */
type CallRejectModalProps = {};

/**
 * Shows a modal with a text area to input a reason fo a call rejection.
 */
export function CallRejectModal({}: CallRejectModalProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    hideCallRejectedModal,
    rejectedCallRoutingId
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [remark, setRemark] = useState<string>("");

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // const [updateCallRoutingRemark, { loading: updatingCallRoutingRemark }] =
  //   useCallRoutingUpdateRemarkMutation();

  const {
    error: updateCallRoutingRemarkError,
    mutate: updateCallRoutingRemark,
    isPending: updatingCallRoutingRemark
  } = trpc.callRoutingRouter.updateCallRoutingRemark.useMutation();

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <Modal cancelButtonProps={{
    style: {
      display: "none"
    }
  }} okText="Confirm" okButtonProps={{
    disabled: updatingCallRoutingRemark || !remark.length,
    loading: updatingCallRoutingRemark
  }} onOk={() => {
    if (!rejectedCallRoutingId || !remark.length) {
      return;
    }
    updateCallRoutingRemark({
      data: {
        id: rejectedCallRoutingId,
        remark
      }
    }, {
      onSuccess: () => {
        hideCallRejectedModal();
        setRemark("");
      }
    });
  }} open={!!rejectedCallRoutingId} title="Reject Call" data-sentry-element="Modal" data-sentry-component="CallRejectModal" data-sentry-source-file="call-reject-modal.tsx">
      <div className="h-min-[84px] inline-flex w-full flex-col items-start justify-start gap-1">
        <div className="text-center">
          <span className="text-sm font-normal text-tpl-red">*</span>
          <span className="text-sm font-normal text-tpl-navy">
            Reason for rejection:
          </span>
        </div>
        {updateCallRoutingRemarkError && <div className="text-tpl-red">
            {updateCallRoutingRemarkError.message}
          </div>}
        <TextArea autoComplete="off" className="h-max-[20vh] w-full" disabled={updatingCallRoutingRemark} onChange={event => {
        setRemark(event.target.value || "");
      }} value={remark} data-sentry-element="TextArea" data-sentry-source-file="call-reject-modal.tsx" />
      </div>
    </Modal>;
}