/**
 * Auth0 permissions.
 * This will be synced to the Auth0 instance.
 */
export enum Auth0Permission {
  // CCC APP ACCESS
  APPLICATION_ACCESS = "archus-cloud-contact-center:access",
  // CALL
  CALL_RECEIVE = "call:receive",
  CALL_LOGS_VIEW_ALL = "call-log:view:all",
  CALL_LOGS_VIEW_OWN = "call-log:view:own",
  // CALL SUMMARY
  CALL_SUMMARY_UPDATE_ALL = "call-summary:update:all",
  CALL_SUMMARY_UPDATE_OWN = "call-summary:update:own",
  // CALL RECORDING
  CALL_RECORDING_EXPORT = "call-recording:export",
  CALL_RECORDING_VIEW_ALL = "call-recording:view:all",
  CALL_RECORDING_VIEW_OWN = "call-recording:view:own",
  // PHONE BOOK
  PHONE_BOOK_VIEW = "phone-book:view",
  // SETTINGS
  /**
   * This permission value has a prefix for the "Archus Administration" app to
   * workaround their unique permission name constraint.
   */
  SETTINGS_VIEW = "ccc-settings:view",
  /**
   * This permission value has a prefix for the "Archus Administration" app to
   * workaround their unique permission name constraint.
   */
  SETTINGS_UPDATE = "ccc-settings:update",
  // USER
  USER_CREATE = "user:create",
  USER_DELETE = "user:delete",
  USER_READ = "user:read",
  USER_UPDATE = "user:update",
}
