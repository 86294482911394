"use client";

import { useEffect, useState } from "react";

import { useAuthenticationContext } from "@/components/client/authentication";
import {
  trpc,
  TrpcRouterInputs,
  UseTrpcQueryCallbacks,
} from "@/components/client/trpc";
import { ContactWithPhoneNumbersIssues } from "@/components/server/contact/services/types";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";

/**
 * The arguments for the use contact hook.
 */
type UseContactArgs = UseTrpcQueryCallbacks<
  UseContactReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The filter for fetching contact.
 */
export type UseContactFilter = Extract<
  TrpcRouterInputs["contactRouter"]["getContact"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use contact hook.
 */
type UseContactReturn = {
  /**
   * The paginated data for contact.
   */
  data?: ContactWithPhoneNumbersIssues | null;
  /**
   * Indicates if the contact are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Indicates if the contact are loading for the first time.
   */
  loading: boolean;
  /**
   * Refetch the contact.
   */
  refetch: () => Promise<void>;
  /**
   * Set the filter for fetching contact.
   */
  setFilter: React.Dispatch<React.SetStateAction<UseContactFilter | undefined>>;
};

/**
 * The use contact hook type.
 */
type UseContact = (args?: UseContactArgs) => UseContactReturn;

/**
 * Hook to fetch, filter, or refetch contact.
 */
export const useContact: UseContact = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for contact.
   */
  const [contact, setContact] = useState<UseContactReturn["data"]>();

  /**
   * The filter for fetching contact.
   */
  const [contactFilter, setContactFilter] = useState<UseContactFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: contactData,
    isLoading: contactsLoading,
    isFetching: contactsFetching,
    refetch: refetchContacts,
  } = trpc.contactRouter.getContact.useQuery(
    {
      filter: {
        id: "",
      },
    },
    {
      enabled: !!user?.id || !contactFilter?.id,
    },
  );

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  async function _refetchContacts() {
    await refetchContacts();
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the contact when the data changes.
   */
  useEffect(() => {
    setContact(contactData);
  }, [contactData]);

  /**
   * Refetch the contact if the filter changed.
   */
  useEffect(() => {
    refetchContacts();
  }, [contactFilter, refetchContacts]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: contact,
    fetching: contactsFetching,
    loading: contactsLoading,
    refetch: _refetchContacts,
    setFilter: setContactFilter,
  };
};
