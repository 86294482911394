"use client";

import { useEffect, useState } from "react";

import { useAuthenticationContext } from "@/components/client/authentication";
import {
  trpc,
  TrpcRouterInputs,
  UseTrpcQueryCallbacks,
} from "@/components/client/trpc";
import { CallWithOptionalRelations } from "@/components/common/call/types";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";

/**
 * The arguments for the use call recording hook.
 */
type UseCallRecordingArgs = {
  /**
   * Indicates if the hook is enabled.
   */
  enabled?: boolean;
} & UseTrpcQueryCallbacks<
  UseCallRecordingReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The filter for fetching call recording.
 */
export type UseCallRecordingFilter = Extract<
  TrpcRouterInputs["callRecordingRouter"]["getCallRecordingWithUrl"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use call recording hook.
 */
type UseCallRecordingReturn = {
  /**
   * The paginated data for call recording.
   */
  data: CallWithOptionalRelations["recording"];
  /**
   * Indicates if the call recording are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Indicates if the call recording are loading for the first time.
   */
  loading: boolean;
  /**
   * Set the filter for fetching call recording.
   */
  setFilter: React.Dispatch<
    React.SetStateAction<UseCallRecordingFilter | undefined>
  >;
};

/**
 * The use call recording hook type.
 */
type UseCalls = (args?: UseCallRecordingArgs) => UseCallRecordingReturn;

/**
 * Hook to fetch, filter, or refetch call recording.
 */
export const useCallRecording: UseCalls = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The call recording.
   */
  const [callRecording, setCallRecording] =
    useState<UseCallRecordingReturn["data"]>();

  /**
   * The filter for fetching call recording.
   */
  const [callRecordingFilter, setCallRecordingFilter] =
    useState<UseCallRecordingFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: callRecordingData,
    isLoading: callRecordingLoading,
    isFetching: callRecordingFetching,
    refetch: refetchCallRecording,
  } = trpc.callRecordingRouter.getCallRecordingWithUrl.useQuery(
    {
      filter: {
        callId: callRecordingFilter?.callId || "",
      },
    },
    {
      enabled: args?.enabled && !user,
    },
  );

  const { reset: resetOnCallRecordingEventSubscription } =
    trpc.callRecordingRouter.onCallRecordingEvent.useSubscription(
      {
        userId: user?.id,
        callId: callRecordingFilter?.callId,
      },
      {
        onData: (eventCallRecording) => {
          if (!eventCallRecording) return;

          // Update the call recording.
          setCallRecording(eventCallRecording);
        },
      },
    );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the call recording when the data changes.
   */
  useEffect(() => {
    setCallRecording(callRecordingData);
  }, [callRecordingData]);

  /**
   * Refetch the call recording if the filter changed.
   */
  useEffect(() => {
    refetchCallRecording();
    resetOnCallRecordingEventSubscription();
  }, [
    callRecordingFilter?.callId,
    refetchCallRecording,
    resetOnCallRecordingEventSubscription,
  ]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: callRecording,
    fetching: callRecordingFetching,
    loading: callRecordingLoading,
    setFilter: setCallRecordingFilter,
  };
};
