/**
 * Third-party libraries.
 */
import { ClockCircleOutlined as IconClockCircleOutlined, QuestionCircleOutlined as IconQuestionCircleOutlined } from "@ant-design/icons";
import { $Enums } from "@prisma/client";
import { Button, Checkbox, Form, Input, Spin, TimePicker, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useState } from "react";

/**
 * Project components.
 */
import { trpc } from "@/components/client/trpc";
import { BusinessHourUtility } from "@/components/common/business-hour";
import { TimeZone } from "@/components/common/time";
import { formatDuration, formatTime } from "@/components/common/time/utilities/time-utility";
import { useBusinessHours } from "./hooks/use-business-hours";

/** Type for the <Form /> component's values. */
type BusinessHoursFormValue = {
  businessHours: {
    day: $Enums.DayOfWeek;
    /**
     * This is just the format that TimePicker.RangePicker normalizes into.
     *
     * Example
     *  ['9:00', '16:00']
     */
    timeRange: [Date, Date];
    active: boolean;
  }[];
};
type BusinessHoursFormProps = {
  /** Optionally disable the form from outside this component. @defaultValue `true` */
  enabled?: boolean;
};
export function BusinessHoursForm({
  enabled = true
}: BusinessHoursFormProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = useForm<BusinessHoursFormValue>();

  // TODO: This must be updated to be fetched from the server.
  const timeZone = TimeZone.SINGAPORE;
  dayjs.tz.setDefault(timeZone);

  // ===========================================================================
  // Queries
  // ===========================================================================

  const {
    data: businessHours,
    loading: isBusinessHoursLoading
  } = useBusinessHours();

  // ===========================================================================
  // Mutations
  // ===========================================================================

  const {
    mutate: updateBusinessHours,
    status: businessHoursUpdateStatus
  } = trpc.businessHourRouter.updateBusinessHours.useMutation();

  // ===========================================================================
  // Helper Functions
  // ===========================================================================
  function toggleEdit(willEdit: boolean = true) {
    if (willEdit === false) {
      form.resetFields();
      setIsEditing(false);
      return;
    }
    setIsEditing(true);
    const defaultBusinessHours = businessHours?.map(businessHour => {
      return {
        day: businessHour.day,
        timeRange: [businessHour.startTime, businessHour.endTime],
        active: businessHour.active
      };
    });
    form.setFieldsValue({
      businessHours: defaultBusinessHours
    });
  }
  return <div className="flex w-full flex-col" data-sentry-component="BusinessHoursForm" data-sentry-source-file="business-hours-form.tsx">
      <div className="flex items-center justify-between gap-x-2">
        <div className="flex flex-nowrap gap-2 text-nowrap">
          <span>Business Hours</span>
          <Tooltip title="Adjust if the business is open on specific days of the week." data-sentry-element="Tooltip" data-sentry-source-file="business-hours-form.tsx">
            <IconQuestionCircleOutlined className="cursor-pointer" data-sentry-element="IconQuestionCircleOutlined" data-sentry-source-file="business-hours-form.tsx" />
          </Tooltip>
        </div>

        {isEditing ? <div className="flex gap-x-2">
            <Button onClick={() => toggleEdit(false)}>Cancel</Button>
            <Button type="primary" onClick={() => {
          form.submit();
        }} loading={businessHoursUpdateStatus === "pending"}>
              Save
            </Button>
          </div> : <Button onClick={() => toggleEdit(true)} disabled={isBusinessHoursLoading}>
            Edit
          </Button>}
      </div>

      <div className="h-3" />

      {isBusinessHoursLoading && <div className="flex justify-center">
          <Spin />
        </div>}

      <Form form={form} onFinish={async values => {
      const businessHours = values.businessHours.map(businessHour => {
        return {
          day: businessHour.day,
          startTime: new Date(businessHour.timeRange[0]),
          endTime: new Date(businessHour.timeRange[1]),
          active: businessHour.active
        };
      });
      updateBusinessHours({
        data: {
          businessHours
        }
      });
      toggleEdit(false);
    }} data-sentry-element="Form" data-sentry-source-file="business-hours-form.tsx">
        {businessHours?.map((businessHour, index) => <div key={businessHour.id} className="flex justify-between gap-x-2 py-2">
            <div className="flex items-center gap-x-2">
              {isEditing ? <Form.Item name={["businessHours", index, "active"]} className="!mb-0" getValueProps={value => ({
            checked: value
          })} getValueFromEvent={e => e.target.checked}>
                  <Checkbox />
                </Form.Item> : <></>}

              {isEditing ? <Form.Item name={["businessHours", index, "day"]} hidden>
                  <Input value={businessHour.day} />
                </Form.Item> : <></>}

              <span>
                {/* Pascal Case for DayOfWeek (i.e. "MONDAY" to "Monday") */}
                {[businessHour.day[0].toUpperCase(), ...businessHour.day.slice(1).toLowerCase()]}
              </span>
            </div>

            <div className="flex items-center gap-x-2">
              {isEditing ? <Form.Item name={["businessHours", index, "timeRange"]} className="!mb-0"
          /**
           * Deserialization: Form Data (TS) -> Component Values (AntD)
           * - `timeRange` - coming from the form data. e.g. "businessHours[0].timeRange": ["2024-01-01T04:00:00.000+0000", "2024-01-01T11:30:00.000+0000"]
           *
           * Why is this needed? `setFieldsValue()` is used to set the initial values of the form.
           */ getValueProps={(timeRange: [string, string]) => {
            const [startDate, endDate] = timeRange;
            const endDateDayJs = dayjs(endDate);
            const startDateDayJs = dayjs(startDate);
            const {
              normalizedEndDateTime,
              normalizedStartDateTime
            } = BusinessHourUtility.normalizeBusinessHourRangeToCurrentOrNextWeek({
              /**
               * Use the same date as the `startDate` but with the time of the `endDate`.
               * BusinessUtility.normalizeBusinessHourRangeToCurrentOrNextWeek
               * will handle the case where `endDate` is before `startDate`.
               *
               * End date time can only be the same or next day of
               * the start date time.
               */
              endDateTime: dayjs(startDate).set("hour", endDateDayJs.hour()).set("minute", endDateDayJs.minute()).set("second", endDateDayJs.second()).set("millisecond", endDateDayJs.millisecond()).toDate(),
              startDateTime: startDateDayJs.toDate(),
              timeZone
            });
            return {
              // TimeRange.RangePicker needs a `dayjs()` object to work. (It uses dayjs under the hood).
              // Otherwise, it will throw a `dateTime.isValid() is not a function` error because dateTime is not a dayjs object.
              value: [dayjs(normalizedStartDateTime), dayjs(normalizedEndDateTime)]
            };
          }}
          /**
           * Serialization: Component Values (AntD) -> Form Data (TS)
           * @param dateRange - ["2024-01-01T04:00:00.000+0000", "2024-01-01T11:30:00.000+0000"]
           *
           * Why is this needed?
           * Selecting: 11pm (start) and 2am (end) will result in:
           * 🛑 Bad: 11pm (2024-01-01) and 2am (2023-12-31).
           * 👍 Good: 11pm (2024-01-01) and 2am (2024-01-02).
           */ getValueFromEvent={(dateRange: [dayjs.Dayjs, dayjs.Dayjs]) => {
            let [startDate, endDate] = dateRange;
            const endDateDayJs = dayjs(endDate);
            const startDateDayJs = dayjs(startDate);
            const {
              normalizedEndDateTime,
              normalizedStartDateTime
            } = BusinessHourUtility.normalizeBusinessHourRangeToCurrentOrNextWeek({
              /**
               * Use the same date as the `startDate` but with the time of the `endDate`.
               * BusinessUtility.normalizeBusinessHourRangeToCurrentOrNextWeek
               * will handle the case where `endDate` is before `startDate`.
               *
               * End date time can only be the same or next day of
               * the start date time.
               */
              endDateTime: dayjs(startDate).set("hour", endDateDayJs.hour()).set("minute", endDateDayJs.minute()).set("second", endDateDayJs.second()).set("millisecond", endDateDayJs.millisecond()).toDate(),
              startDateTime: startDateDayJs.toDate(),
              timeZone
            });

            // When endDate is before startDate (Add 1 day) i.e. 11pm (2024-01-01) -> 3am (2024-01-02)
            return [dayjs(normalizedStartDateTime), dayjs(normalizedEndDateTime)];
          }}>
                  <TimePicker.RangePicker format="hh:mm a" placeholder={["0:00", "0:00"]} className="w-52" showNow
            /**
             * Disable `order` so we're allowed to enter `endDate` to exceeding 12am of today.
             * BUT, we still need to make sure that `endDate` is a day after `startDate` via `getValueFromEvent`.
             */ order={false} />
                </Form.Item> : <div className="flex items-center justify-center gap-2">
                  <span>{formatTime(businessHour.startTime)}</span>

                  <span>to</span>

                  <span>{formatTime(businessHour.endTime)}</span>

                  <Tooltip title={`${formatDuration({
              from: businessHour.startTime,
              to: businessHour.endTime,
              format: "readable-short"
            })}`}>
                    <IconClockCircleOutlined className="text-xs text-neutral-100" />
                  </Tooltip>
                </div>}
            </div>
          </div>)}
      </Form>
    </div>;
}