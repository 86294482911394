"use client";

import { useEffect, useState } from "react";

import { APPLICATION_CONFIGURATION } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import {
  trpc,
  TrpcRouterInputs,
  UseTrpcQueryCallbacks,
} from "@/components/client/trpc";
import { ContactWithPhoneNumbersIssues } from "@/components/server/contact/services/types";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event/event-emitter";

/**
 * The arguments for the use contacts hook.
 */
type UseContactsArgs = UseTrpcQueryCallbacks<
  UseContactsReturn["data"],
  EventEmitterEvent[EventEmitterEventName.CALL][0]
>;

/**
 * The filter for fetching contacts.
 */
export type UseContactsFilter = Extract<
  TrpcRouterInputs["contactRouter"]["getContacts"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use contacts hook.
 */
type UseContactsReturn = {
  /**
   * The paginated data for contacts.
   */
  data: ContactWithPhoneNumbersIssues[];
  /**
   * Indicates if the contacts are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Fetch more contacts if there are more data to fetch.
   */
  // fetchMore: () => Promise<void>;
  fetchMore: () => Promise<void>;
  /**
   * Indicates if there are more contacts to fetch.
   */
  hasMore: boolean;
  /**
   * Indicates if the contacts are loading for the first time.
   */
  loading: boolean;
  /**
   * Refetch the contacts.
   */
  refetch: () => Promise<void>;
  /**
   * Set the filter for fetching contacts.
   */
  setFilter: React.Dispatch<
    React.SetStateAction<UseContactsFilter | undefined>
  >;
};

/**
 * The use contacts hook type.
 */
type UseContacts = (args?: UseContactsArgs) => UseContactsReturn;

/**
 * Hook to fetch, filter, or refetch contacts.
 */
export const useContacts: UseContacts = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The paginated data for contacts.
   */
  const [contacts, setContacts] = useState<UseContactsReturn["data"]>([]);

  /**
   * The filter for fetching contacts.
   */
  const [contactsFilter, setContactsFilter] = useState<UseContactsFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: contactsData,
    hasNextPage: hasMoreContacts,
    isLoading: contactsLoading,
    isFetching: contactsFetching,
    refetch: refetchContacts,
    fetchNextPage: fetchNextPageContacts,
  } = trpc.contactRouter.getContacts.useInfiniteQuery(
    {
      filter: contactsFilter,
      limit: APPLICATION_CONFIGURATION.pagination.limit,
    },
    {
      enabled: !!user?.id,
      /**
       * Use the contact ID as the cursor for the next page.
       */
      getNextPageParam: (lastPage) =>
        lastPage[lastPage.length - 1]?.id || undefined,
    },
  );

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Fetch more contacts if there are more data to fetch.
   */
  async function fetchMoreContacts() {
    // Concluded calls are still being fetched or there are no more contacts to fetch.
    if (contactsFetching || contactsLoading || !hasMoreContacts) {
      return;
    }

    await fetchNextPageContacts().then((response) => {
      // Only add the new last page to the existing data.
      setContacts((previousContacts) => [
        ...previousContacts,
        ...(response.data?.pages[response.data.pages.length - 1] || []),
      ]);
    });
  }

  async function _refetchContacts() {
    await refetchContacts();
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the contacts when the data changes.
   */
  useEffect(() => {
    // Doing a fetch more, handle that manually on the fetch more logic.
    if (contacts.length) {
      return;
    }

    /**
     * Only set the contacts on first fetch to avoid overwriting
     * subscription event added data.
     */
    setContacts(contactsData?.pages.flat() || []);
  }, [contacts.length, contactsData]);

  /**
   * Refetch the contacts if the filter changed.
   * Reset the contacts pagination offset to 0.
   *
   * This would update the concluded communication logs showing on the screen.
   */
  useEffect(() => {
    /**
     * Clear the contacts since the filter changed.
     * This would help track if we are doing a refetch or a fetch more.
     */
    setContacts([]);
    refetchContacts();
  }, [contactsFilter, refetchContacts]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: contacts,
    fetching: contactsFetching,
    fetchMore: fetchMoreContacts,
    hasMore: hasMoreContacts,
    loading: contactsLoading,
    refetch: _refetchContacts,
    setFilter: setContactsFilter,
  };
};
