import { CallDirection, CallStatus, Prisma } from "@prisma/client";

// =============================================================================
// =============================================================================
// Constants
// =============================================================================
// =============================================================================
/**
 * Statuses that are considered as active calls.
 */
export const CALL_STATUSES_ACTIVE: CallStatus[] = [
  CallStatus.IN_PROGRESS,
  CallStatus.QUEUED,
  CallStatus.WRAPPING_UP,
];

/**
 * Statuses that are considered as ongoing or pending calls. (user is currently
 * dialing or in an ongoing call with a customer)
 */
export const CALL_STATUSES_ONGOING_WITH_PENDING: CallStatus[] = [
  CallStatus.IN_PROGRESS,
  CallStatus.QUEUED,
  CallStatus.PENDING,
];

/**
 * Statuses that are already considered as concluded or final.
 *
 * Statuses that are not equal to the active call statuses and the pending status.
 */
export const CALL_STATUSES_CONCLUDED: CallStatus[] = Object.values(
  CallStatus,
).filter(
  (status) =>
    !CALL_STATUSES_ACTIVE.includes(status) && CallStatus.PENDING !== status,
);

/**
 * Prisma where clause for getting all concluded calls.
 */
export const CONCLUDED_CALLS_PRISMA_WHERE_INPUT: Prisma.CallWhereInput = {
  /**
   * Calls that are not under these statuses regardless of the assigned user:
   * - PENDING
   * - QUEUED
   * - IN_PROGRESS
   */
  status: {
    in: CALL_STATUSES_CONCLUDED,
  },
};

/**
 * Prisma where clause for getting all missed calls.
 */
export const MISSED_CALLS_PRISMA_WHERE_INPUT: Prisma.CallWhereInput = {
  direction: CallDirection.INBOUND,
  status: {
    in: [CallStatus.MISSED],
  },
};
