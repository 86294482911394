/**
 * Third-party libraries.
 */
import Icon from "@ant-design/icons";
import { CallDirection } from "@prisma/client";
import { Button, Collapse, CollapseProps, Tooltip } from "antd";
import { useMemo, useState } from "react";

/**
 * Project components.
 */
import { CommunicationDirection, CommunicationLogStatus, CommunicationLogUser, useCommunicationLogContext } from "@/components/client/communication-log";
import { CommunicationLogUtility } from "@/components/client/communication-log/utilities";
import { FieldDisplayOnly } from "@/components/client/field";
import { ChevronDownIcon, ChevronRightIcon, PhoneIcon } from "@/components/client/icons";
import { useMessageContext } from "@/components/client/message";
import { trpc } from "@/components/client/trpc";
import { TimeUtility } from "@/components/common/time";
import { DateUtility } from "@/components/common/utilities";
import { useCallsActive } from "./hooks";

// =============================================================================
// Call Information Card
// =============================================================================

/**
 * Call Information Card Props.
 */
export type CallInformationCardProps = {
  /**
   * Indicates that the call information card is visible.
   */
  visible?: boolean;
};

/**
 * A small card that displays information about a call.
 */
export function CallInformationCard({
  visible
}: CallInformationCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Contexts
  // ===========================================================================
  // ===========================================================================

  const {
    data: activeCalls
  } = useCallsActive();
  const {
    selectedCommunicationLog
  } = useCommunicationLogContext();
  const message = useMessageContext();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // const [createCall, { loading: creatingCall }] = useCallCreateMutation();
  const {
    mutate: createCall,
    isPending: creatingCall
  } = trpc.callRouter.createCall.useMutation();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Indicates if the card is expanded or not.
   */
  const [expanded, setExpanded] = useState<boolean>(false);

  /**
   * The icon to display based on the status of the communication.
   */
  const icon = useMemo(() => {
    if (!selectedCommunicationLog?.status) {
      return null;
    }
    return CommunicationLogUtility.getIcon({
      status: selectedCommunicationLog?.status
    });
  }, [selectedCommunicationLog?.status]);

  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const color = useMemo(() => {
    if (!selectedCommunicationLog?.status) {
      return null;
    }
    return CommunicationLogUtility.getCardColor({
      status: selectedCommunicationLog.status
    });
  }, [selectedCommunicationLog?.status]);

  /**
   * The phone number used for call back.
   *
   * A null phone number would hide the callback button.
   */
  const phoneNumber = useMemo(() => {
    if (!selectedCommunicationLog) {
      return null;
    }

    /**
     * Only show the callback button when the call is missed, declined,
     * no response, failed, or canceled.
     */
    switch (selectedCommunicationLog.status) {
      case CommunicationLogStatus.MISSED:
      case CommunicationLogStatus.DECLINED:
      case CommunicationLogStatus.CANCELED:
      case CommunicationLogStatus.NO_RESPONSE:
      case CommunicationLogStatus.FAILED:
        break;
      default:
        return null;
    }

    /**
     * Pick the correct phone number to use depending on the communication log
     * direction.
     * - Inbound calls would use the `from` phone number.
     * - Outbound calls would use the `to` phone number.
     */
    switch (selectedCommunicationLog.direction) {
      case CommunicationDirection.INBOUND:
        return selectedCommunicationLog.from;
      case CommunicationDirection.OUTBOUND:
        return selectedCommunicationLog.to;
      default:
        throw new Error(`Unknown direction: ${selectedCommunicationLog.direction}`);
    }
  }, [selectedCommunicationLog]);

  /**
   * An active call with the same phone number as the selected communication log.
   *
   * Use this to check if there is an existing call with the same phone number
   * and prevent the user from creating a new call.
   */
  const activeCall = useMemo(() => {
    const _activeCall = activeCalls.find(call => {
      const callPhoneNumber = call.direction === CallDirection.INBOUND ? call.from : call.to;
      return callPhoneNumber === selectedCommunicationLog?.clientPhoneNumber;
    });
    return _activeCall;
  }, [activeCalls, selectedCommunicationLog]);

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================

  const items: CollapseProps["items"] = [{
    key: "1",
    label: <div className="flex h-[74px] w-full items-center justify-between border-b border-neutral-grey-mid p-4" onClick={() => setExpanded(!expanded)}>
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2">
            <div className="inline-flex items-center justify-start gap-1 self-stretch">
              {!!icon?.length && !!color && <Icon className={color.icon} component={PhoneIcon} rotate={icon === "phone-down" ? 135 : 0} />}
              <div className="text-sm font-semibold text-tpl-navy">
                {selectedCommunicationLog?.status + " " + selectedCommunicationLog?.direction}
              </div>
            </div>
            {selectedCommunicationLog && <div className="inline-flex w-full items-center justify-start gap-1">
                <CommunicationLogUser communicationLog={{
            id: selectedCommunicationLog.id,
            missedCount: selectedCommunicationLog.missedCount,
            user: selectedCommunicationLog.user
          }} assignedUser={selectedCommunicationLog.user}
          /** Only enabled when the call is inbound and the status is `Missed`, `Declined`, or `Canceled`. */ enabled={selectedCommunicationLog.direction === CommunicationDirection.INBOUND && (selectedCommunicationLog.status === CommunicationLogStatus.MISSED || selectedCommunicationLog.status === CommunicationLogStatus.DECLINED || selectedCommunicationLog.status === CommunicationLogStatus.CANCELED)} />
              </div>}
          </div>
          <div className="flex items-center gap-2">
            {!!phoneNumber && <Tooltip title={activeCall ? `There is an existing ${activeCall.direction} call with the customer.` : "Callback"}>
                <Button disabled={creatingCall || !!activeCall} icon={<Icon component={PhoneIcon} className={"!text-sm !text-white"} />} loading={creatingCall} onClick={async event => {
            event.stopPropagation();
            createCall({
              data: {
                to: phoneNumber
              }
            }, {
              onError: error => {
                message.error(error.message);
              }
            });
          }} type="primary">
                  Callback
                </Button>
              </Tooltip>}
            <Icon component={expanded ? ChevronDownIcon : ChevronRightIcon} className="h-6 w-6 text-tpl-navy-light" />
          </div>
        </div>,
    children: <div className="flex flex-col items-start justify-start gap-2 self-stretch bg-white p-4 py-0">
          <FieldDisplayOnly label="From" value={selectedCommunicationLog?.from} />
          <FieldDisplayOnly label="To" value={selectedCommunicationLog?.to} />
          <FieldDisplayOnly label="Agent" value={selectedCommunicationLog?.user ? selectedCommunicationLog.user.name : "-"} />
          <FieldDisplayOnly label="Date" value={selectedCommunicationLog?.date ? DateUtility.getDateTime({
        date: selectedCommunicationLog?.date
      }) : "-"} />
          <FieldDisplayOnly label="Start" value={selectedCommunicationLog?.dateStarted ? DateUtility.getTime({
        date: selectedCommunicationLog?.dateStarted
      }) : "-"} />
          <FieldDisplayOnly label="End" value={selectedCommunicationLog?.dateEnded ? DateUtility.getTime({
        date: selectedCommunicationLog?.dateEnded
      }) : "-"} />
          <FieldDisplayOnly label="Direction" value={selectedCommunicationLog?.direction} />
          <FieldDisplayOnly label="Wait Time" value={!selectedCommunicationLog?.date || !selectedCommunicationLog.dateStarted && !selectedCommunicationLog.dateEnded ? "-" : TimeUtility.formatDuration({
        from: selectedCommunicationLog?.date,
        to: selectedCommunicationLog?.dateStarted! || selectedCommunicationLog?.dateEnded!
      })} />
          <FieldDisplayOnly label="Duration" value={!selectedCommunicationLog?.duration ? "-" : TimeUtility.formatDuration({
        duration: selectedCommunicationLog?.duration * 1000
      })} />
          <FieldDisplayOnly enableCopy label="Call ID" trim={-6} value={selectedCommunicationLog?.id} />
        </div>,
    showArrow: false,
    style: {
      padding: 0
    }
  }];

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }
  return <div className={`flex w-full animate-slide-left flex-col items-start justify-start self-stretch bg-white ${!!selectedCommunicationLog ? "" : "hidden"} ${expanded ? "border-b border-neutral-grey" : ""}`} data-sentry-component="CallInformationCard" data-sentry-source-file="call-information-card.tsx">
      <Collapse className="w-full" ghost items={items} data-sentry-element="Collapse" data-sentry-source-file="call-information-card.tsx" />
    </div>;
}