/**
 * Third-party libraries.
 */
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Divider } from "antd";

/**
 * Project components.
 */
import { ActivityLog } from "@/components/common/activity-log";
import { GroupedByDate } from "@/components/common/utilities";
import { useState } from "react";
import { ActivityLogs } from "./activity-logs";

/**
 * Properties of the ActivityLogGroup component.
 */
type ActivityLogGroupProps = {
  /**
   * The data to display in the activity log group.
   */
  data: GroupedByDate<ActivityLog>[0];
};

/**
 * Displays a collapsible group of activity logs.
 * The header of the group displays the date of the activity logs.
 */
export function ActivityLogGroup({
  data
}: ActivityLogGroupProps) {
  const [expanded, setExpanded] = useState<boolean>(true);
  return <div className="activity-log-collapse static block w-full" key={data.label} data-sentry-component="ActivityLogGroup" data-sentry-source-file="activity-log-group.tsx">
      <Collapse ghost activeKey={expanded ? [data.label] : []} items={[{
      key: data.label,
      showArrow: false,
      label: <div className="sticky top-0 z-10 inline-flex h-[18px] w-full items-center justify-between gap-2 bg-white p-4" onClick={() => {
        setExpanded(!expanded);
      }}>
                <div className="inline-flex h-[0px] flex-1 flex-col items-center justify-center gap-2.5">
                  <Divider />
                </div>
                <div className="flex items-center justify-center text-sm font-normal text-tpl-navy">
                  {data.label}
                </div>
                <div className="relative h-4 w-4">
                  {expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
                </div>
                <div className="inline-flex h-[0px] flex-1 flex-col items-center justify-center gap-2.5">
                  <Divider />
                </div>
              </div>,
      children: <div className="static flex h-full w-full">
                <ActivityLogs logs={data.items} />
              </div>
    }]} data-sentry-element="Collapse" data-sentry-source-file="activity-log-group.tsx" />
    </div>;
}