"use client";

import { useEffect, useState } from "react";

import { useAuthenticationContext } from "@/components/client/authentication";
import {
  trpc,
  TrpcRouterInputs,
  UseTrpcQueryCallbacks,
} from "@/components/client/trpc";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import {
  EventEmitterEvent,
  EventEmitterEventName,
} from "@/components/server/event";
import { UserWithAvailability } from "@/components/server/user/types";

/**
 * The arguments for the use users hook.
 */
type UseUsersArgs = UseTrpcQueryCallbacks<
  UserUsersReturn["data"],
  EventEmitterEvent[EventEmitterEventName.USER][0]
>;

/**
 * The filter for fetching users.
 */
export type UserUsersFilter = Extract<
  TrpcRouterInputs["userRouter"]["getUsers"],
  { filter?: any }
>["filter"];

/**
 * The return type for the use users hook.
 */
type UserUsersReturn = {
  /**
   * The data for users.
   */
  data: UserWithAvailability[];
  /**
   * Indicates if the users are fetching either initially or subsequently.
   */
  fetching: boolean;
  /**
   * Indicates if the users are loading for the first time.
   */
  loading: boolean;
  /**
   * Set the filter for fetching users.
   */
  setFilter: React.Dispatch<React.SetStateAction<UserUsersFilter | undefined>>;
};

/**
 * The use users hook type.
 */
type UseUsers = (args?: UseUsersArgs) => UserUsersReturn;

/**
 * Hook to fetch, filter, or refetch users.
 */
export const UseUsers: UseUsers = (args) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { user } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The data for users.
   */
  const [users, setUsers] = useState<UserUsersReturn["data"]>([]);

  /**
   * The filter for fetching users.
   */
  const [usersFilter, setUserFilter] = useState<UserUsersFilter>();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const {
    data: usersData,
    isLoading: usersLoading,
    isFetching: usersFetching,
    refetch: refetchUsers,
  } = trpc.userRouter.getUsers.useQuery(undefined, {
    enabled:
      // Check if the user is authenticated.
      !!user?.id &&
      // Check if the user has the permission to read users.
      user?.permissions?.includes(Auth0Permission.USER_READ),
  });

  /**
   * Subscribe to user events.
   * - Add new users to the list if it is viewable by the user.
   * - Update existing users when a user event is published if it is viewable by the user.
   * - Remove users that is not viewable by the user.
   */
  const { status: onCallEventStatus, error } =
    trpc.userRouter.onUserUpdate.useSubscription(
      { userId: user?.id },
      {
        onData: (eventUser) => {
          /**
           * Nothing to do if there is no user in the published event.
           */
          if (!eventUser) return;

          /**
           * Concluded calls has not been fetched yet.
           */
          if (!users) return;

          /**
           * Index of the existing user in the calls agent list matching the
           * user in the published event.
           */
          const existingUserIndex = users.findIndex(
            (user) => user.id === eventUser.id,
          );

          /**
           * Indicates whether the user exists in the existing calls agent list.
           */
          const isUserExisting = existingUserIndex > -1;

          /**
           * This will hold the updated list of users.
           */
          const updatedUsers = [...users];

          /**
           * Call the on event callback if it is provided.
           */
          args?.onEvent?.({
            data: users,
            eventData: eventUser,
          });

          // Update the existing user since it already exists in the list.
          if (isUserExisting) {
            updatedUsers[existingUserIndex] = eventUser;
          }
          // Add the new user on top of the list since it doesn't exist.
          else {
            updatedUsers.unshift(eventUser);
          }

          // Sort the users from newest to oldest date.
          updatedUsers.sort((a, b) => {
            const dateA = new Date(a.fullName);
            const dateB = new Date(b.fullName);
            return dateB.getTime() - dateA.getTime();
          });

          setUsers(updatedUsers);
        },
      },
    );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the users when the data changes.
   */
  useEffect(() => {
    setUsers(usersData || []);
  }, [usersData]);

  /**
   * Refetch the users if the filter changed.
   * Reset the users pagination offset to 0.
   *
   * This would update the concluded communication logs showing on the screen.
   */
  useEffect(() => {
    refetchUsers();
  }, [usersFilter, refetchUsers]);

  /**
   * Trigger the on status change callback when the on user event status changes.
   */
  useEffect(() => {
    args?.onEventListenerStatusChange?.({ status: onCallEventStatus });
  }, [args, onCallEventStatus]);

  // ===========================================================================
  // ===========================================================================
  // Return
  // ===========================================================================
  // ===========================================================================

  return {
    data: users,
    fetching: usersFetching,
    loading: usersLoading,
    setFilter: setUserFilter,
  };
};
