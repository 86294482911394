import { ErrorMessage } from "@/components/common/error/enumerations";
import { Call, CallStatus, User } from "@prisma/client";

/**
 * Arguments for getting the customer phone number from a call record.
 */
type GetCustomerPhoneNumberArgs = {
  /**
   * The call record.
   */
  call: Call;
};

/**
 * Get the customer phone number from a call record.
 *
 * @returns The customer phone number.
 * @throws If the call record is missing or the call direction is invalid.
 */
export function getCustomerPhoneNumber({ call }: GetCustomerPhoneNumberArgs) {
  if (!call) {
    throw new Error("Missing call.");
  }

  if (call.direction === "INBOUND") {
    return call.from;
  } else if (call.direction === "OUTBOUND") {
    return call.to;
  }

  throw new Error("Invalid call direction.");
}

/**
 * Arguments for determining if a user can view the call.
 */
type IsUserViewableArgs = {
  /**
   * The call to evaluate.
   */
  call: Pick<Call, "status" | "userId">;
  /**
   * The user to check if they can view the call.
   */
  userId: User["id"];
};

/**
 * Identifies if the user can view the call.
 * - Call is assigned to a user
 * - Call is unassigned and:
 *    - Call is canceled
 *    - Call is completed
 *    - Call is missed
 *    - Call is rejected
 *
 * @returns True if the user can view the call.
 */
export const isUserViewable = ({ call, userId }: IsUserViewableArgs) => {
  if (!call.status) {
    throw new Error(`${ErrorMessage.MISSING_ARGUMENT}: call.status`);
  }

  if (!userId) {
    throw new Error(`${ErrorMessage.MISSING_ARGUMENT}: userId`);
  }

  // A call is viewable by the user if it is assigned to him.
  if (!!call.userId && call.userId === userId) {
    return true;
  }

  switch (call.status) {
    /**
     * A queued call is not viewable by the user at this point because it is not
     * assigned to him based on the if clause above.
     */
    case CallStatus.QUEUED:
      return false;
    // A call is viewable by the user if it is under these statuses.
    case CallStatus.FAILED:
    case CallStatus.CANCELED:
    case CallStatus.COMPLETED:
    case CallStatus.MISSED:
    case CallStatus.REJECTED:
      return true;
    // A call is not viewable otherwise.
    default:
      return false;
  }
};
