type GetInitialsArgs = {
  /**
   * The input string to get the initials from.
   */
  input?: string;
  /**
   * The maximum length of the initials. Defaults to `2`.
   */
  maxLength?: number;
};

/**
 * Get the initials of the given word.
 */
export function getInitials({ input, maxLength = 2 }: GetInitialsArgs) {
  if (!input) return null;

  /**
   * First characters of each word in the input.
   */
  const firstCharactersOfEachWord = input.match(/\b(\w)/g);

  if (!firstCharactersOfEachWord) {
    return null;
  }

  /**
   * Upper case initials.
   */
  let initials = firstCharactersOfEachWord.map((word) => word.toUpperCase());

  initials = initials.slice(0, maxLength);

  return initials.join("");
}

/**
 * Arguments for the `toTitleCase` function.
 */
type ToTitleCaseArgs = {
  /**
   * The text to convert to title case.
   */
  text: string;
};

/**
 * Capitalizes the first letter of every word of the given text.
 */
export function toTitleCase({ text }: ToTitleCaseArgs) {
  // If the text is empty, return it as is.
  if (!text.length) {
    return text;
  }

  /**
   * Split the text by spaces.
   */
  const split = text.split(" ");

  const titleCased = split.map((word) => {
    // If the word is more than one character, capitalize the first letter and lowercase the rest.
    if (word.length > 1) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    // If the word is only one character, capitalize it.
    else {
      return word.charAt(0).toUpperCase();
    }
  });

  return titleCased.join(" ");
}
