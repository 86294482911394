import { PropsWithChildren, ReactNode } from "react";
import { UserAvatar } from "@/components/client/avatar";
import { UserAvatarProps } from "@/components/client/avatar/user-avatar";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { RichTextEditor } from "../../text-editor";
export type ActivityLogHeaderProps = PropsWithChildren<{
  /**
   * Indicates if the activity log title is editable.
   */
  editable?: boolean;
  /**
   * Icon component to be displayed on the left side of the activity log item.
   */
  icon: ReactNode;
  /**
   * Actions that can be added to the activity log item.
   *
   * This will be added as links above the time.
   */
  menuItems?: MenuProps["items"];
  /**
   * Callback when the content is changed.
   */
  onChange?: (args: {
    content?: string;
  }) => void;
  /**
   * Callback when the icon is clicked.
   */
  onIconClick?: () => void | (() => Promise<void>);
  /**
   * Title of the activity log.
   */
  title: string;
  /**
   * Initials of the user who is associated with the activity.
   */
  userInitials?: UserAvatarProps["initials"];
}>;
export function ActivityLogHeader({
  menuItems: actions,
  editable,
  icon,
  onChange,
  onIconClick,
  title,
  userInitials
}: ActivityLogHeaderProps) {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className="flex w-full items-center justify-between gap-2" data-sentry-component="ActivityLogHeader" data-sentry-source-file="activity-log-header.tsx">
      <div className="flex w-full items-center gap-2 self-stretch">
        <div className={`flex h-4 w-4 items-center justify-center ${typeof onIconClick === "function" ? "hover:cursor-pointer" : ""}`} onClick={() => {
        onIconClick?.();
      }}>
          {icon}
        </div>
        {!editable && <UserAvatar initials={userInitials} noBadge size="small" />}
        {!editable ? <div className="text-sm font-semibold text-tpl-navy">{title}</div> : <RichTextEditor className="w-full bg-neutral-grey-light" content={title} editable={true} onCreate={({
        editor
      }) => {
        editor.commands.focus();
      }} onUpdate={({
        editor
      }) => {
        // The editor is empty.
        if (!editor.getText()?.length) {
          onChange?.({
            content: undefined
          });
        }
        // The editor has contents.
        else {
          onChange?.({
            content: editor.getHTML()
          });
        }
      }} />}
      </div>
      {!!actions?.length && !editable && <div className="flex gap-2">
          <Dropdown menu={!actions?.length ? undefined : {
        items: actions
      }} trigger={["click"]}>
            <Button type="text" shape="circle" icon={<EllipsisOutlined />} />
          </Dropdown>
        </div>}
    </div>;
}