"use client";

/**
 * Third-party libraries.
 */
import { Call, Device } from "@twilio/voice-sdk";
import axios from "axios";
import packageJson from "package.json";
import { useCallback, useState } from "react";

/**
 * Project components.
 */
import { TwilioDeviceEvent } from "@/components/client/twilio/enumerations";
import { ApiRoute } from "@/components/common/route";

type useTwilioDeviceProps = {
  /**
   * Callbacks for Twilio device events.
   */
  callback?: {
    /**
     * Twilio device registered.
     */
    registered: () => void;
    /**
     * Twilio device registering.
     */
    registering: () => void;
    /**
     * Twilio device error.
     */
    error: (error: unknown) => void;
    /**
     * Handles incoming calls.
     */
    incoming: (args: {
      /**
       * The incoming call.
       *
       * Add call event listeners here.
       *
       * {@link https://www.twilio.com/docs/voice/sdks/javascript/twiliocall | Voice JavaScript SDK: Twilio.Call}
       */
      call: Call;
    }) => void;
    /**
     * Twilio device destroyed.
     */
    destroyed: () => void;
    /**
     * Twilio device token will expire.
     */
    token_will_expire: () => void;
    /**
     * Twilio device unregistered.
     */
    unregistered: () => void;
  };
};

/**
 * Hook to manage the Twilio Device instance.
 *
 * Things to note:
 * - Twilio device must be instantiated before use.
 *    - This is not required to make incoming/outgoing calls.
 * - Twilio device registration is only required to make calls.
 *    - This is not required to make outgoing calls.
 * - Twilio device must be unregistered to stop receiving incoming calls.
 *    - This is not required to make outgoing calls.
 * - Twilio device must be destroyed when no longer needed.
 */
export const useTwilioDevice = (props?: useTwilioDeviceProps) => {
  const [device, setDevice] = useState<Device | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [initializing, setInitializing] = useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const [registering, setRegistering] = useState<boolean>(false);

  /**
   * This method will disconnect all calls, unbind audio devices, destroy the
   * stream (which causes the unregistered event to be emitted), and then the
   * Device instance will emit the destroyed event. Then, all event listeners
   * attached to the Device instance are cleaned up.
   */
  function destroyDevice() {
    if (!device) {
      console.warn("Twilio device not initialized.");

      return;
    }

    device.destroy();

    setRegistered(false);
  }

  /**
   * Creates an instance of the Twilio device and initializes it.
   */
  const initialize = useCallback(async () => {
    // Only run initialize if Twilio device is not yet initialized.
    if (!!device || !!initializing || errorCount > 2) {
      return;
    }

    console.log("Initializing Twilio device.");

    setInitializing(true);
    setError(null);

    try {
      /**
       * Token response from the server.ascript/device#constructor
       */
      const response = await axios.post(ApiRoute.TWILIO_TOKEN);

      const token = response.data.token;

      if (!token) {
        throw new Error("No Twilio token received from the server.");
      }

      /**
       * Initialize the Twilio device with the token.
       *
       * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice
       */
      const _device = new Device(token, {
        allowIncomingWhileBusy: true,
        appName: "Archus Cloud Contact Center",
        appVersion: packageJson.version,
        // sounds: {
        //   /**
        //    * Set a custom incoming call rington.
        //    */
        //   incoming: ASSET_ROUTE.AUDIO.CALL_RINGTONE,
        //   /**
        //    * Set a custom outgoing call rington.
        //    */
        //   outgoing: ASSET_ROUTE.AUDIO.CALL_RINGTONE,
        // },
      });

      if (!_device) {
        throw new Error("Twilio device failed to initialize.");
      }

      function addTwilioDeviceListeners(_device: Device) {
        // Only initialize if Twilio device is not yet initialized.
        if (!_device) {
          throw new Error("Twilio device not initialized.");
        }

        _device.on(TwilioDeviceEvent.DESTROYED, () => {
          setDevice(null);
          props?.callback?.destroyed();
        });

        _device.on(TwilioDeviceEvent.ERROR, (error) => {
          setDevice(null);
          setRegistered(false);
          setRegistering(false);
          setError(error);
          props?.callback?.error(error);
        });

        _device.on(TwilioDeviceEvent.INCOMING, (call) => {
          props?.callback?.incoming({ call });
        });

        _device.on(TwilioDeviceEvent.REGISTERED, () => {
          setRegistered(true);
          setRegistering(false);
          props?.callback?.registered();
        });

        _device.on(TwilioDeviceEvent.REGISTERING, () => {
          setRegistering(true);
          props?.callback?.registering();
        });

        _device.on(TwilioDeviceEvent.TOKEN_WILL_EXPIRE, () => {
          props?.callback?.token_will_expire();
        });

        _device.on(TwilioDeviceEvent.UNREGISTERED, () => {
          setRegistered(false);
          setRegistering(false);
          props?.callback?.unregistered();
        });
      }

      addTwilioDeviceListeners(_device);

      setDevice(_device);

      console.log("✅ Initialized Twilio device.");

      setErrorCount(0);

      return _device;
    } catch (error) {
      setErrorCount((prevErrorCount) => prevErrorCount + 1);
      setError(error);
      console.error("❌ Error initializing Twilio device:", error);
    } finally {
      setInitializing(false);
    }
  }, [device, initializing, props?.callback]);

  /**
   * Register the Device instance with Twilio, allowing it to receive incoming calls.
   *
   * This will open a signaling WebSocket, so the browser tab may show the 'recording' icon.
   *
   * It's not necessary to call device.register() in order to make outgoing calls.
   */
  async function registerDevice() {
    if (!device) {
      throw new Error("Twilio device not initialized.");
    }

    if (error) {
      throw new Error("Twilio device has error. Cannot register.");
    }

    try {
      await device.register();
    } catch (error) {
      console.error("Error registering Twilio device:", error);
      setError("Error registering Twilio device.");
    }
  }

  /**
   * Unregister the Device instance with Twilio. This will prevent the Device
   * instance from receiving incoming calls.
   */
  async function unregisterDevice() {
    if (!device) {
      throw new Error("Twilio device not initialized.");
    }

    if (error) {
      throw new Error("Twilio device has error. Cannot unregister.");
    }

    try {
      await device.unregister();
    } catch (error) {
      console.error("Error unregistering Twilio device:", error);
      setError("Error unregistering Twilio device.");
    }
  }

  return {
    /**
     * This method will disconnect all calls, unbind audio devices, destroy the
     * stream (which causes the unregistered event to be emitted), and then the
     * Device instance will emit the destroyed event. Then, all event listeners
     * attached to the Device instance are cleaned up.
     */
    destroyDevice,
    /**
     * Twilio device instance.
     *
     * This is null when not yet initialized.
     */
    device,
    /**
     * Twilio device error.
     */
    error,
    /**
     * Creates an instance of the Twilio device and initializes it.
     */
    initialize,
    /**
     * Twilio device is initializing.
     */
    initializing,
    /**
     * Twilio device is registered.
     */
    registered,
    /**
     * Twilio device is registering.
     */
    registering,
    /**
     * Register the Device instance with Twilio, allowing it to receive incoming calls.
     *
     * This will open a signaling WebSocket, so the browser tab may show the 'recording' icon.
     *
     * It's not necessary to call device.register() in order to make outgoing calls.
     */
    registerDevice,
    /**
     * Set the registered state.
     */
    setRegistered,
    /**
     * Unregister the Device instance with Twilio. This will prevent the Device
     * instance from receiving incoming calls.
     */
    unregisterDevice,
  };
};
